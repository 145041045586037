import React, { useState, useEffect, Fragment } from 'react';
import { toast } from 'react-toastify';
import {
  Col,
  Card,
  CardHeader,
  CardBody,
  Row,
  Spinner,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input
} from 'reactstrap';
import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css';
import './AdminDashboard.css';
import { UsersPersonalDetail } from 'Services/usersPersonalDetail.service';
import { VacationDays, addVacationDays } from 'Services/vacationdays.service';
import { UsersTimeOff, createUsersTimeOff, updateUsersTimeOff } from 'Services/usersTimeoff.service';
import { fetchAttendanceRecords, fetchCurrentMonthAttendance, fetchTodaysAttendance } from "Services/attendance.service";
import { fetchDocuments } from 'Services/managedocs.service';
import { fetchshifts } from 'Services/shifts.service';
import { ArrowLeft, Edit, Trash, PlusCircle, Eye, Upload } from "react-feather";

const HrmsAdminDashboard = () => {
  // **Loading States**
  const [loadingYetToPunchIn, setLoadingYetToPunchIn] = useState(true);
  const [loadingAbsent, setLoadingAbsent] = useState(true);
  const [loadingDocuments, setLoadingDocuments] = useState(true);
  const [loadingBirthdays, setLoadingBirthdays] = useState(true);
  const [loadingLatePunch, setLoadingLatePunch] = useState(true);
  const [loadingProbation, setLoadingProbation] = useState(true);
  const [probationEnding, setProbationEnding] = useState([]);
  const [noOfDays, setNoOfDays] = useState(1);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [timeOffType, setTimeOffType] = useState('');
  const [attendanceRecords, setAttendanceRecords] = useState([]); // Store fetched attendance data
  const [latePunches, setLatePunches] = useState([]);
  const [yetToPunchIn, setYetToPunchIn] = useState([]); // Store employees yet to punch in
  const [punchedInToday, setPunchedInToday] = useState(new Set()); // Track punched-in employees
  const [todaysAttendance, setTodaysAttendance] = useState([]); // ✅ Store today's attendance
  const [vacationModal, setVacationModal] = useState(false);
  const [vacationFormValues, setVacationFormValues] = useState([{
    empName: '',
    empNo: '',
    transactionType: '',
    fromDate: new Date().toISOString().split('T')[0], // Today
    toDate: new Date().toISOString().split('T')[0], // Today
    resumptionDate: '',
    noOfDays: 1,
    status: 'Pending',
  }]);


  const [vacations, setVacations] = useState([]);
  const [isVacationEditMode, setIsVacationEditMode] = useState(false);

  const [selectedDate, setSelectedDate] = useState(new Date());
  const [events, setEvents] = useState([]);
  const [modal, setModal] = useState(false);
  const [formValues, setFormValues] = useState({
    eventTitle: '',
    eventPriority: 'normal',
    eventDate: new Date().toISOString().substr(0, 10),
  });

  const [today] = useState(new Date());
  const lastWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7);



  const [upcomingBirthdays, setUpcomingBirthdays] = useState([]);
  console.log("upcom.....................", upcomingBirthdays);

  const [absent, setAbsent] = useState([]);
  console.log("Absentee", absent);

  // **Fetch Absentees**
  useEffect(() => {
    const fetchAbsentees = async () => {
      try {
        console.log("🔄 Fetching absentee data...");

        setLoadingAbsent(true);
        const absentees = await VacationDays();

        console.log("📡 API Response - Raw Data:", absentees);

        const today = new Date();
        today.setHours(0, 0, 0, 0); // ✅ Reset time to 00:00:00 to avoid mismatch

        console.log(`📅 Today's Date: ${today.toDateString()}`);

        const currentlyAbsent = absentees.filter(absentee => {
          const fromDate = new Date(absentee.fromDate);
          const toDate = new Date(absentee.toDate);

          // ✅ Reset time on fromDate and toDate to 00:00:00 for accurate comparison
          fromDate.setHours(0, 0, 0, 0);
          toDate.setHours(0, 0, 0, 0);

          console.log(`🔍 Checking: ${absentee.empName}`);
          console.log(`➡️ From: ${fromDate.toDateString()} | To: ${toDate.toDateString()} | Status: ${absentee.status}`);

          // ✅ Ensure correct comparison including single-day leaves
          const isAbsentToday = (today.getTime() >= fromDate.getTime()) && (today.getTime() <= toDate.getTime());
          const isActiveLeave = absentee.status === "Completed" || absentee.status === "In_Progress";

          console.log(`✅ Is Absent Today? ${isAbsentToday}`);
          console.log(`✅ Is Leave Status Valid? ${isActiveLeave}`);

          return isAbsentToday && isActiveLeave;
        });

        console.log("✅ Final Absentees List:", currentlyAbsent);
        setAbsent(currentlyAbsent);
      } catch (error) {
        console.error("❌ Error fetching absentees:", error);
      }
      setLoadingAbsent(false);
      console.log("⏳ Finished fetching absentees.");
    };

    fetchAbsentees();
  }, []);



  useEffect(() => {
    const fetchProbationEnding = async () => {
      try {
        setLoadingProbation(true);
        const usersData = await UsersPersonalDetail();
        const today = new Date();
        const thirtyDaysFromNow = new Date();
        thirtyDaysFromNow.setDate(today.getDate() + 30);

        const endingProbations = usersData.filter(user => {
          const probationEndDate = new Date(user.end_of_probation);
          return probationEndDate >= today && probationEndDate <= thirtyDaysFromNow;
        });

        setProbationEnding(endingProbations);
      } catch (error) {
        console.error("Error fetching probation data:", error);
      }
      setLoadingProbation(false);
    };

    fetchProbationEnding(); // ✅ Call function inside useEffect

  }, []); // ✅ Empty dependency array to run only once


  // **Fetch Upcoming Birthdays**
  useEffect(() => {
    const fetchBirthdays = async () => {
      try {
        setLoadingBirthdays(true);
        const allUsers = await UsersPersonalDetail();
        const today = new Date();
        const thirtyDaysFromNow = new Date();
        thirtyDaysFromNow.setDate(today.getDate() + 30);

        const upcoming = allUsers
          .map(user => {
            const birthDate = new Date(user.birth_date);
            let birthDateThisYear = new Date(today.getFullYear(), birthDate.getMonth(), birthDate.getDate());
            if (birthDateThisYear < today) {
              birthDateThisYear.setFullYear(today.getFullYear() + 1);
            }

            const timeDiff = Math.ceil((birthDateThisYear - today) / (1000 * 60 * 60 * 24));

            return {
              ...user,
              timeDiff,
              birthDateThisYear
            };
          })
          .filter(user => user.birthDateThisYear >= today && user.birthDateThisYear <= thirtyDaysFromNow)
          .sort((a, b) => a.timeDiff - b.timeDiff);

        setUpcomingBirthdays(upcoming);
      } catch (error) {
        console.error("Error fetching upcoming birthdays:", error);
      }
      setLoadingBirthdays(false);
    };

    fetchBirthdays();
  }, []);

  const formatTimeLeft = (daysLeft) => {
    const weeks = Math.floor(daysLeft / 7);
    const days = daysLeft % 7;
    if (weeks > 0 && days > 0) {
      return `${weeks} week${weeks > 1 ? 's' : ''} and ${days} day${days > 1 ? 's' : ''} left`;
    } else if (weeks > 0) {
      return `${weeks} week${weeks > 1 ? 's' : ''} left`;
    } else {
      return `${days} day${days > 1 ? 's' : ''} left`;
    }
  };

  const toggle = () => setModal(!modal);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = () => {
    if (formValues.eventTitle) {
      setEvents([
        ...events,
        {
          date: new Date(formValues.eventDate),
          title: formValues.eventTitle,
          priority: formValues.eventPriority,
        },
      ]);
      toggle();
    }
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const getEventColor = (priority) => {
    switch (priority) {
      case 'low':
        return 'green';
      case 'high':
        return 'red';
      default:
        return 'blue';
    }
  };
 const toggleVacationModal = (employee = null) => {
  console.log("🚀 toggleVacationModal Triggered!", employee);

  if (employee) {
    console.log("🟢 Setting Employee Data:", employee);
    const today = new Date().toISOString().split('T')[0];

    const vacationData = {
      empName: employee.name,
      empNo: employee.userid,
      transactionType: '', // Leave empty for now
      fromDate: today,
      toDate: today,
      resumptionDate: '',
      noOfDays: 1,
      status: 'Pending',
    };

    setVacationFormValues([vacationData]);
  }

  // ✅ Simply open or toggle the modal
  setVacationModal(prev => !prev);
};

  
  
const handleSaveVacation = async (e) => {
  e.preventDefault();

  const data = vacationFormValues[0];

  if (!data.transactionType) {
    toast.error("❌ Please select a transaction type.");
    return;
  }

  try {
    // 🔄 Fetch existing time-off records
    const userTimeOffRecords = await UsersTimeOff();

    let timeoffType = "";
    let dayAdjustment = -1;

    switch (data.transactionType.trim().toUpperCase()) {
      case "ANNUAL VACATIONS":
      case "LATE DEDUCTION":
      case "SICK LEAVE":
      case "PERSONAL LEAVE":
        timeoffType = "ANNUAL VACATIONS";
        break;
      case "SICK DAY OFF":
        timeoffType = "SICK DAY OFF";
        break;
      case "ABSENCE WITHOUT PERMISSIONS":
      case "UNPAID VACATION":
        timeoffType = "UNPAID VACATION";
        dayAdjustment = 1;
        break;
      case "MATERNITY LEAVE":
      case "PATERNITY LEAVE":
      case "PILGRIMAGE LEAVE":
        timeoffType = data.transactionType.toUpperCase();
        break;
      case "EARLY OUT":
      case "HALF DAY":
        timeoffType = "ANNUAL VACATIONS";
        dayAdjustment = -0.5;
        break;
      case "BUSINESS VACATION":
        toast.info("ℹ️ Business vacation does not affect time-off records.");
        break;
      default:
        console.warn("⚠️ Unknown transaction type:", data.transactionType);
        return;
    }

    if (timeoffType !== "") {
      const existing = userTimeOffRecords.find(
        record => String(record.userid).trim() === String(data.empNo).trim() &&
                 record.timeoffType.trim().toUpperCase() === timeoffType.trim().toUpperCase()
      );

      if (existing) {
        const updatedDays = parseFloat(existing.days) + dayAdjustment;
        await updateUsersTimeOff(existing.id, { days: updatedDays.toString() });
        console.log(`🛠️ Updated ${timeoffType} to ${updatedDays} days`);
      } else {
        await createUsersTimeOff({
          userid: data.empNo,
          timeoffType,
          days: dayAdjustment.toString(),
        });
        console.log(`🆕 Created time-off record for ${timeoffType}`);
      }
    }

    // ✅ Finally, add vacation record
    await addVacationDays(data);
    toast.success("✅ Vacation added successfully!");
    setVacationModal(false);
  } catch (error) {
    console.error("❌ Error saving vacation:", error);
    toast.error("Something went wrong! Please try again.");
  }
};

  const handleVacationFormChange = (e, id) => {
    const { name, value } = e.target;
    const updatedVacation = [...vacationFormValues];

    updatedVacation[id] = { ...updatedVacation[id], [name]: value };

    setVacationFormValues(updatedVacation);
  };


  const handleDateSelection = (date) => {
    setSelectedDate(date); // Update state with the selected date
  };

  const [expiry, setExpiry] = useState([]);
  console.log("Expiring..", expiry);

  // **Fetch Expiring Documents**
  useEffect(() => {
    const fetchExpiringDocuments = async () => {
      try {
        setLoadingDocuments(true);
        const expiringDocs = await fetchDocuments();
        const today = new Date();

        const filteredExpiry = expiringDocs.filter(document => {
          const expiryDate = new Date(document.expiryDate);
          let reminderPeriod = document.docType === "PASSPORT" ? 6 : document.docType === "VISA" ? 1 : 0;
          const reminderDate = new Date(today);
          reminderDate.setMonth(today.getMonth() + reminderPeriod);

          return expiryDate >= today && expiryDate <= reminderDate;
        });

        setExpiry(filteredExpiry);
      } catch (error) {
        console.error("Error fetching documents:", error);
      }
      setLoadingDocuments(false);
    };

    fetchExpiringDocuments();
  }, []);

  const loadAttendanceRecords = async () => {
    try {
      console.log("🔄 Fetching attendance records...");

      const attendanceData = await fetchAttendanceRecords();
      console.log("✅ Attendance Data Received:", attendanceData);

      if (!attendanceData || !Array.isArray(attendanceData.rows) || attendanceData.rows.length === 0) {
        console.warn("⚠️ No attendance data found.");
        return;
      }

      setAttendanceRecords(attendanceData.rows); // ✅ Save to state
      console.log("✅ Updated attendanceRecords state:", attendanceData.rows);
    } catch (error) {
      console.error("❌ Error fetching attendance data:", error);
    }
  };

  // ✅ Call this function on component mount
  useEffect(() => {
    loadAttendanceRecords();
  }, []);

  const fetchLatePunchIns = async () => {
    try {
      console.log("🔄 [Step 1] Fetching Late Punch-Ins...");

      setLoadingLatePunch(true);

      console.log("📡 Fetching shifts, employees, and attendance data...");
      const [shiftData, usersData, attendanceData] = await Promise.all([
        fetchshifts(),
        UsersPersonalDetail(),
        fetchAttendanceRecords(),
      ]);

      console.log("✅ [Step 2] Data Fetched Successfully");

      if (!shiftData || !Array.isArray(shiftData.rows)) {
        console.error("❌ Shift data is invalid:", shiftData);
        throw new Error("Invalid shift data format.");
      }
      if (!usersData || !Array.isArray(usersData)) {
        console.error("❌ Users data is invalid:", usersData);
        throw new Error("Invalid users data format.");
      }
      if (!attendanceData || !Array.isArray(attendanceData.rows)) {
        console.error("❌ Attendance data is invalid:", attendanceData);
        throw new Error("Invalid attendance data format.");
      }

      console.log("📊 Shift Data:", shiftData.rows);
      console.log("📊 Users Data:", usersData);
      console.log("📊 Attendance Data:", attendanceData.rows);
    } catch (error) {
      console.error("❌ Error fetching Late Punch-Ins:", error);
    }
    setLoadingLatePunch(false);
  };


  // **Fetch Employees Yet to Punch In**
  useEffect(() => {
    if (todaysAttendance.length === 0) {
      console.log("⚠️ Skipping Yet to Punch In calculation - No attendance data yet.");
      return;
    }

    const loadYetToPunchIn = async () => {
      try {
        setLoadingYetToPunchIn(true);

        console.log("🔄 Fetching Users...");
        const usersData = await UsersPersonalDetail();
        console.log("📌 Users Data:", usersData);

        console.log("🔄 Using `todaysAttendance` for Punch-in Data");
        console.log("📌 Today's Attendance Data:", todaysAttendance);

        console.log("🔄 Fetching Absentees...");
        const absentees = await VacationDays();
        console.log("📌 Absentees List:", absentees);

        // Step 1: Create a Set of employees who have punched in today
        const punchedInTodaySet = new Set(
          todaysAttendance.map(record => record.emp_code?.toString().trim())
        );

        console.log("✅ Employees Who Have Punched In Today:", punchedInTodaySet);

        // Step 2: Identify employees who are on leave today
        const todayDate = new Date().toISOString().split("T")[0];

        // Map VacationDays empNo -> UserPersonalDetail userid -> section
        const absenteesMapped = absentees
          .filter(record => {
            if (!record.fromDate || !record.toDate) {
              console.warn("⚠️ Skipping record due to missing date fields:", record);
              return false;
            }

            const fromDateObj = new Date(record.fromDate);
            const toDateObj = new Date(record.toDate);

            if (isNaN(fromDateObj) || isNaN(toDateObj)) {
              console.warn("⚠️ Skipping record due to invalid date format:", record);
              return false;
            }

            const fromDate = fromDateObj.toISOString().split("T")[0];
            const toDate = toDateObj.toISOString().split("T")[0];

            return todayDate >= fromDate && todayDate <= toDate;
          })
          .map(record => record.empNo?.toString().trim()); // Get empNo for mapping

        console.log("🚫 Employees on Leave Today (Mapped from VacationDays.empNo):", absenteesMapped);

        // Convert empNo to emp_code using UserPersonalDetail
        const absenteesTodaySet = new Set(
          usersData
            .filter(user => absenteesMapped.includes(user.userid?.toString().trim()))
            .map(user => user.section?.toString().trim())
        );

        console.log("🚫 Employees on Leave Today (Excluded - Set Format):", absenteesTodaySet);

        // Step 3: Filter employees who haven't punched in and are not absent
        const employeesYetToPunchIn = usersData.filter(user => {
          const empCode = user.section?.toString().trim(); // Corrected mapping

          return (
            empCode &&
            !punchedInTodaySet.has(empCode) &&
            !absenteesTodaySet.has(empCode)
          );
        });

        console.log("✅ Final Yet to Punch In List:", employeesYetToPunchIn);
        setYetToPunchIn(employeesYetToPunchIn);
      } catch (error) {
        console.error("❌ Error fetching Yet to Punch In employees:", error);
      }
      setLoadingYetToPunchIn(false);
    };

    loadYetToPunchIn();
  }, [todaysAttendance]);



  // const toggleModal = (employee) => {
  //   console.log("Selected Employee:", employee);
  //   setSelectedEmployee(employee);
  //   setModal(!modal);
  // };

  // const handleTimeOffSubmit = async () => {
  //   if (!selectedEmployee || !timeOffType || !noOfDays) {
  //     alert("❌ Please fill in all fields!");
  //     return;
  //   }

  //   try {
  //     console.log("🔍 Fetching existing time-off records from UsersTimeOff...");

  //     // Step 1: Fetch existing time-off records
  //     const existingTimeOffRecords = await UsersTimeOff(); // API returns an array

  //     console.log("✅ Fetched UsersTimeOff Records:", existingTimeOffRecords);

  //     // Step 2: Apply the time-off mapping logic
  //     let adjustedTimeOffType = "";
  //     let dayAdjustment = 0;
  //     let requestedDays = parseFloat(noOfDays);

  //     switch (timeOffType.trim().toUpperCase()) {
  //       case "ANNUAL VACATIONS":
  //       case "LATE DEDUCTION":
  //       case "SICK LEAVE":
  //       case "PERSONAL LEAVE":
  //         adjustedTimeOffType = "ANNUAL VACATIONS";
  //         dayAdjustment = -requestedDays;
  //         break;
  //       case "SICK DAY OFF":
  //         adjustedTimeOffType = "SICK DAY OFF";
  //         dayAdjustment = -requestedDays;
  //         break;
  //       case "ABSENCE WITHOUT PERMISSIONS":
  //       case "UNPAID VACATION":
  //         adjustedTimeOffType = "UNPAID VACATION";
  //         dayAdjustment = requestedDays; // No deduction from annual leave
  //         break;
  //       case "MATERNITY LEAVE":
  //         adjustedTimeOffType = "MATERNITY LEAVE";
  //         dayAdjustment = requestedDays; // Special leave, not deducted
  //         break;
  //       case "PATERNITY LEAVE":
  //         adjustedTimeOffType = "PATERNITY LEAVE";
  //         dayAdjustment = requestedDays; // Special leave, not deducted
  //         break;
  //       case "PILGRIMAGE LEAVE":
  //         adjustedTimeOffType = "PILGRIMAGE LEAVE";
  //         dayAdjustment = requestedDays; // Special leave, not deducted
  //         break;
  //       case "EARLY OUT":
  //       case "HALF DAY":
  //         adjustedTimeOffType = "ANNUAL VACATIONS";
  //         dayAdjustment = -0.5;
  //         break;
  //       case "BUSINESS VACATION":
  //         console.log("🔹 No changes for BUSINESS VACATION.");
  //         alert("✅ Business vacation does not require time-off deduction.");
  //         return;
  //       default:
  //         console.warn("⚠️ Unknown transaction type:", timeOffType);
  //         alert("❌ Invalid time-off type selected!");
  //         return;
  //     }

  //     console.log("📌 Adjusted Time-Off Type:", adjustedTimeOffType);
  //     console.log("📉 Day Adjustment:", dayAdjustment);

  //     // Step 3: Find if an existing record exists for this user
  //     let employeeTimeOffRecord = existingTimeOffRecords.find(
  //       record => record.userid === selectedEmployee.userid && record.timeoffType === adjustedTimeOffType
  //     );

  //     console.log("🔍 Existing Time-Off Record:", employeeTimeOffRecord || "No Record Found");

  //     if (employeeTimeOffRecord) {
  //       // Step 4: If record exists, adjust the days
  //       let newDaysValue = parseFloat(employeeTimeOffRecord.days) + dayAdjustment;

  //       if (newDaysValue < 0) {
  //         alert("❌ Insufficient leave balance!");
  //         console.warn("⚠️ Cannot deduct more days than available.");
  //         return;
  //       }

  //       console.log(`🔄 Updating record for user ${selectedEmployee.userid}. New days left: ${newDaysValue}`);

  //       // Step 5: Update existing record
  //       await updateUsersTimeOff(employeeTimeOffRecord.id, {
  //         userid: selectedEmployee.userid,
  //         timeoffType: adjustedTimeOffType,
  //         days: newDaysValue.toString(), // Convert to string for consistency
  //       });

  //       console.log("✅ Time-Off Record Updated!");
  //     } else {
  //       // Step 6: If no record exists, create a new one
  //       console.log("🆕 No existing record. Creating new entry...");

  //       await createUsersTimeOff({
  //         userid: selectedEmployee.userid,
  //         timeoffType: adjustedTimeOffType,
  //         days: dayAdjustment.toString(), // Convert to string for consistency
  //       });

  //       console.log("✅ New Time-Off Record Created!");
  //     }

  //     alert("✅ Time off updated successfully!");
  //     toggleModal(null);
  //   } catch (error) {
  //     console.error("❌ Error updating time off:", error);
  //     alert("Something went wrong! Please try again.");
  //   }
  // };



  // ✅ Fetch Late Punch-Ins (Runs After Attendance is Fetched)
  useEffect(() => {
    const fetchLatePunchIns = async () => {
      try {
        console.log("🔄 Fetching Late Punch-Ins...");

        setLoadingLatePunch(true);

        const [shiftData, usersData, attendanceResponse] = await Promise.all([
          fetchshifts(),
          UsersPersonalDetail(),
          fetchCurrentMonthAttendance(1, 1000),
        ]);

        console.log("✅ Data Fetched: Shifts, Users, Attendance (This Month)");

        // ✅ Ensure the response contains `rows`
        if (!attendanceResponse || !attendanceResponse.rows) {
          console.warn("⚠️ No attendance data for late punches.");
          setLatePunches([]);
          setLoadingLatePunch(false);
          return;
        }

        const attendanceData = attendanceResponse.rows;
        console.log("✅ Attendance Records (This Month):", attendanceData);

        const todayDate = new Date().toISOString().split("T")[0];

        const shiftMap = new Map(
          shiftData.rows.map(shift => [shift.emp_code, shift.hours])
        );

        console.log("✅ Shift Map Created:", shiftMap);

        const todaysAttendance = attendanceData.filter(record => {
          const punchTimeString = record.punch_time.split(".")[0];
          const punchTime = new Date(punchTimeString.replace(" ", "T"));
          return punchTime.toISOString().split("T")[0] === todayDate;
        });

        console.log("✅ Today's Attendance Records:", todaysAttendance);
        setTodaysAttendance(todaysAttendance);

        const firstPunchInMap = new Map();

        todaysAttendance.forEach(record => {
          const empCode = record.emp_code;
          const punchTimeString = record.punch_time.split(".")[0];
          const punchTime = new Date(punchTimeString.replace(" ", "T"));

          if (!firstPunchInMap.has(empCode) || punchTime < firstPunchInMap.get(empCode)) {
            firstPunchInMap.set(empCode, punchTime);
          }
        });

        console.log("✅ First Punch-In Records:", Array.from(firstPunchInMap.entries()));

        const latePunchList = [];

        firstPunchInMap.forEach((punchTime, empCode) => {
          let shiftHours = shiftMap.get(empCode);
          let shiftStart;

          if (!shiftHours) {
            console.warn(`⚠️ No shift assigned to ${empCode}, defaulting to 10:00 AM`);
            shiftStart = new Date(`${todayDate}T10:00:00`);
          } else {
            const match = shiftHours.match(/(\d+):(\d+)(AM|PM)/);
            if (!match) return;

            let [_, startHour, startMinute, period] = match;
            startHour = parseInt(startHour, 10);
            if (period === "PM" && startHour !== 12) startHour += 12;
            if (period === "AM" && startHour === 12) startHour = 0;

            shiftStart = new Date(`${todayDate}T${startHour}:${startMinute}:00`);
          }

          const lateThreshold = new Date(shiftStart);
          lateThreshold.setMinutes(lateThreshold.getMinutes() + 15);

          if (punchTime.getTime() > lateThreshold.getTime()) {
            const lateMinutes = Math.round((punchTime - shiftStart) / (1000 * 60));
            const employee = usersData.find(user => user.section === empCode);
            const empName = employee ? employee.name : `Unknown (${empCode})`;

            console.log(`🚨 LATE: ${empName} punched in at ${punchTime.toLocaleTimeString()} (Late by ${lateMinutes} min)`);

            latePunchList.push({
              empCode,
              name: empName,
              punchTime: punchTime.toLocaleTimeString(),
              expectedTime: shiftStart.toLocaleTimeString(),
              lateBy: `${lateMinutes} minutes`,
            });
          }
        });

        setLatePunches(latePunchList);
        console.log("✅ Late Punch List:", latePunchList);
      } catch (error) {
        console.error("❌ Error fetching Late Punch-Ins:", error);
      }
      setLoadingLatePunch(false);
    };

    fetchLatePunchIns();
  }, [attendanceRecords]);

  // Runs only when attendance records update
  return (
    <Fragment>
      <style>

      </style>
      <div style={{ height: '30px' }} className="container-fluid">
        <div className="page-title" style={{ display: 'none' }}>
          <div className="row">
            <div className="col-6">
              <h3></h3>
            </div>
            <div className="col-6">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="/dashboard">Dashboard</a>
                </li>
                <li className="breadcrumb-item active">Clients</li>
              </ol>
            </div>
          </div>
        </div>
      </div>

      <Row className="card-container">
        {/* Who’s Off / Working Remotely */}
        <Col sm="4" className="card-column">
          <Card className="card-common who-is-off">
            <CardHeader><h5>Who’s Off / Working Remotely 🏡</h5></CardHeader>
            <CardBody>
              {loadingAbsent ? (
                <Spinner color="primary" />
              ) : absent.length > 0 ? (
                <ul>
                  {absent.map((absentee, index) => (
                    <li key={index} className="who-is-off-item">
                      <span className="initial">{absentee.empName.charAt(0)}</span>
                      <div className="absence-info">
                        <p>{absentee.empName}</p>
                        <p>{new Date(absentee.fromDate).toLocaleDateString()} → {new Date(absentee.toDate).toLocaleDateString()}</p>
                      </div>
                    </li>
                  ))}
                </ul>
              ) : (
                <p>No one’s off today! 🎉</p>
              )}
            </CardBody>
          </Card>
        </Col>
        {/* Employees Yet to Punch In */}
        <Col sm="4" className="card-column">
          <Card className="card-common yet-to-punch-in">
            <CardHeader><h5>Yet to Punch In ⏳</h5></CardHeader>
            <CardBody>
              {loadingYetToPunchIn ? (
                <div className="text-center">
                  <Spinner color="primary" />
                </div>
              ) : yetToPunchIn.length > 0 ? (
                <div className="yet-to-punch-in-container">
                  <ul className="yet-to-punch-in-list">
                    {yetToPunchIn.map((employee, index) => (
                      <li key={index} className="yet-to-punch-in-item">
                        <span className="initial">{employee.name.charAt(0)}</span>
                        <div className="employee-info">
                          <p className="name">{employee.name}</p>
                          <p className="job-title">{employee.job_title || "No Job Title"}</p>
                        </div>
                        <Edit
                          size={18}
                          className="edit-icon"
                          style={{ cursor: 'pointer', marginLeft: 'auto' }}
                          onClick={() => toggleVacationModal(employee)}
                        />
                      </li>
                    ))}
                  </ul>
                </div>
              ) : (
                <p className="all-punched-in">Everyone has punched in today! 🎉</p>
              )}
            </CardBody>

          </Card>
        </Col>
        {/* Documents Expiring */}
        <Col sm="4" className="card-column">
          <Card className="card-common documents-expiring-soon">
            <CardHeader><h5>Documents Expiring Soon 📄</h5></CardHeader>
            <CardBody>
              {loadingDocuments ? (
                <Spinner color="primary" />
              ) : expiry.length > 0 ? (
                <ul>
                  {expiry.map((doc, index) => (
                    <li key={index} className="expiring-document-item">
                      <span className="initial">{doc.owner.charAt(0)}</span>
                      <div>
                        <p>{doc.owner} - {doc.docType}</p>
                        <p>{new Date(doc.expiryDate).toLocaleDateString()}</p>
                      </div>
                    </li>
                  ))}
                </ul>
              ) : (
                <p>No documents expiring soon!</p>
              )}
            </CardBody>
          </Card>
        </Col>

        <Row className="card-container">
          {/* Late Punch-Ins */}
          <Col sm="4" className="card-column">
            <Card className="card-common late-punch-ins">
              <CardHeader><h5>Late Punch-Ins ⏳</h5></CardHeader>
              <CardBody style={{ maxHeight: "250px", overflowY: "auto" }}>
                {loadingLatePunch ? (
                  <div className="text-center">
                    <Spinner color="primary" />
                  </div>
                ) : latePunches.length > 0 ? (
                  <ul style={{ padding: 0, margin: 0, listStyle: "none" }}>
                    {latePunches.map((employee, index) => (
                      <li key={index} className="expiring-document-item">
                        <span
                          className="initial"
                          style={{ backgroundColor: "purple", color: "white" }}
                        >
                          {employee.name.charAt(0)}
                        </span>

                        <div>
                          <p className="name">{employee.name}</p>
                          <p>Late by {employee.lateBy}</p>
                        </div>
                      </li>
                    ))}
                  </ul>
                ) : (
                  <p>No late punch-ins today! 🎉</p>
                )}
              </CardBody>
            </Card>
          </Col>


          <Col sm="4" className="card-column">
            <Card className="card-common documents-expiring-soon">
              <CardHeader><h5>Probation Ending in 30 Days 📅</h5></CardHeader>
              <CardBody>
                {loadingProbation ? (
                  <Spinner color="primary" />
                ) : probationEnding.length > 0 ? (
                  <ul>
                    {probationEnding.map((employee, index) => (
                      <li key={index} className="expiring-document-item">
                        <span className="initial">{employee.name.charAt(0)}</span>
                        <div>
                          <p>{employee.name} - Ends on {new Date(employee.end_of_probation).toLocaleDateString()}</p>
                        </div>
                      </li>
                    ))}
                  </ul>
                ) : (
                  <p>No probation periods ending soon.</p>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>






      </Row>

      {/* Upcoming Birthdays and Calendar */}
      <Row className="birthdays-and-calendar mt-4">
        {/* Upcoming Birthdays */}
        <Col sm="6" className="card-column">
          <Card className="card-common upcoming-birthdays">
            <CardHeader><h5>Upcoming Birthdays 🎂</h5></CardHeader>
            <CardBody style={{ maxHeight: "300px", overflowY: "auto" }}>
              {loadingBirthdays ? (
                <div className="text-center">
                  <Spinner color="primary" />
                </div>
              ) : upcomingBirthdays.length > 0 ? (
                <ul className="upcoming-birthdays-list">
                  {upcomingBirthdays.map((user, index) => (
                    <li key={index} className="upcoming-birthday-item">
                      <span className="initial">{user.name.charAt(0)}</span>
                      <div className="birthday-info">
                        <p className="name">{user.name}</p>
                        <p className="date">{new Date(user.birthDateThisYear).toLocaleDateString()}</p>
                        <p className="time-left">
                          {user.timeDiff} days left 🎉
                        </p>
                      </div>
                    </li>
                  ))}
                </ul>
              ) : (
                <p>No upcoming birthdays in the next 3 weeks</p>
              )}
            </CardBody>
          </Card>
        </Col>
        {/* Calendar */}
        <Col sm="6">
          <Card>
            <CardHeader><h5>Calendar 📅</h5></CardHeader>
            <CardBody>
              <Calendar value={selectedDate} />
            </CardBody>
          </Card>
        </Col>
      </Row>
      
      {console.log("🔍 vacationModal State Inside JSX:", vacationModal)}
      
      {/* Modal Component */}
      
        <Modal isOpen={vacationModal} toggle={toggleVacationModal}>
    <ModalHeader toggle={toggleVacationModal}>
      {isVacationEditMode ? 'Edit Vacation Info' : 'Add Vacation Info'}
    </ModalHeader>
    <ModalBody>
      <Form onSubmit={handleSaveVacation}>
        {/* Employee Name - Auto-selected */}
        <FormGroup>
          <Label for="empName">Employee Name</Label>
          <Input
            type="text"
            name="empName"
            id="empName"
            value={vacationFormValues[0].empName || ''}
            disabled // Prevent user from changing
          />
        </FormGroup>

        {/* Employee Number - Auto-selected */}
        <FormGroup>
          <Label for="empNo">Employee Number</Label>
          <Input
            type="text"
            name="empNo"
            id="empNo"
            value={vacationFormValues[0].empNo || ''}
            disabled // Prevent user from changing
          />
        </FormGroup>

        {/* From Date - Auto-selected */}
        <FormGroup>
          <Label for="fromDate">From Date</Label>
          <Input
            type="date"
            name="fromDate"
            id="fromDate"
            value={vacationFormValues[0].fromDate || ''}
            disabled // Set to today automatically
          />
        </FormGroup>

        {/* To Date - Auto-selected */}
        <FormGroup>
          <Label for="toDate">To Date</Label>
          <Input
            type="date"
            name="toDate"
            id="toDate"
            value={vacationFormValues[0].toDate || ''}
            disabled // Set to today automatically
          />
        </FormGroup>

        {/* Number of Days - Auto-selected */}
        <FormGroup>
          <Label for="noOfDays">Number Of Days</Label>
          <Input
            type="number"
            name="noOfDays"
            id="noOfDays"
            value={vacationFormValues[0].noOfDays || 1}
            disabled // Always 1 by default
          />
        </FormGroup>

        {/* Transaction Type Dropdown */}
        <FormGroup>
          <Label for="transactionType">Transaction Type</Label>
          <Input
            type="select"
            name="transactionType"
            id="transactionType"
            value={vacationFormValues[0].transactionType}
            onChange={(e) => handleVacationFormChange(e, 0)}
            required
          >
            <option value="" disabled>Select Type</option>
            <option value="ANNUAL VACATIONS">ANNUAL VACATIONS</option>
            <option value="SICK DAY OFF">SICK DAY OFF</option>
            <option value="ABSENCE WITHOUT PERMISSIONS">ABSENCE WITHOUT PERMISSIONS</option>
            <option value="BUSINESS VACATION">BUSINESS VACATION</option>
            <option value="LATE DEDUCTION">LATE DEDUCTION</option>
            <option value="MANUAL ATTENDANCE">MANUAL ATTENDANCE</option>
            <option value="MATERNITY LEAVE">MATERNITY LEAVE</option>
            <option value="PATERNITY LEAVE">PATERNITY LEAVE</option>
            <option value="PERSONAL LEAVE">PERSONAL LEAVE</option>
            <option value="PILGRIMAGE LEAVE">PILGRIMAGE LEAVE</option>
            <option value="SICK LEAVE">SICK LEAVE</option>
            <option value="UNPAID VACATION">UNPAID VACATION</option>
            <option value="EARLY OUT">EARLY OUT</option>
            <option value="HALF DAY">HALF DAY</option>
          </Input>
        </FormGroup>

        {/* Status Dropdown */}
        <FormGroup>
          <Label for="status">Status</Label>
          <Input
            type="select"
            name="status"
            id="status"
            value={vacationFormValues[0].status}
            onChange={(e) => handleVacationFormChange(e, 0)}
            required
          >
            <option value="Pending">Pending</option>
            <option value="In_Progress">In Progress</option>
            <option value="Completed">Completed</option>
            <option value="Canceled">Canceled</option>
          </Input>
        </FormGroup>

        <ModalFooter>
          <Button color="primary" type="submit">
            {isVacationEditMode ? 'Update' : 'Save'}
          </Button>
          <Button color="secondary" onClick={toggleVacationModal}>Cancel</Button>
        </ModalFooter>
      </Form>
    </ModalBody>
    </Modal>




      {/* Modal for updating time off
      <Modal isOpen={modal} toggle={() => toggleModal(null)}>
        <ModalHeader toggle={() => toggleModal(null)}>Update Time Off</ModalHeader>
        <ModalBody>
          {selectedEmployee && (
            <Form>
              <FormGroup>
                <Label>Employee</Label>
                <Input type="text" value={selectedEmployee.name} disabled />
              </FormGroup>
              <FormGroup>
                <Label>Time Off Type</Label>
                <Input type="select" value={timeOffType} onChange={e => setTimeOffType(e.target.value)}>
                  <option value="">Select Type</option>
                  <option value="ANNUAL VACATIONS">Annual Vacation</option>
                  <option value="SICK LEAVE">Sick Leave</option>
                  <option value="UNPAID VACATION">Unpaid Vacation</option>
                  <option value="MATERNITY LEAVE">Maternity Leave</option>
                  <option value="PATERNITY LEAVE">Paternity Leave</option>
                  <option value="PILGRIMAGE LEAVE">Pilgrimage Leave</option>
                  <option value="HALF DAY">Half Day</option>
                </Input>
              </FormGroup>
              <FormGroup>
                <Label>Number of Days</Label>
                <Input type="number" value={noOfDays} min="0.5" step="0.5" onChange={e => setNoOfDays(e.target.value)} />
              </FormGroup>
            </Form>
          )}
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleTimeOffSubmit}>Submit</Button>
          <Button color="secondary" onClick={() => toggleModal(null)}>Cancel</Button>
        </ModalFooter>
      </Modal> */}
    </Fragment>
  );
};

export default HrmsAdminDashboard;