import React, { useEffect, useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  TableSortLabel,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  TextField,
  Grid,
  Button
} from '@mui/material';
import { Bar, Line, Pie, Doughnut } from 'react-chartjs-2';
import Chart from 'chart.js/auto'; // Automatically handles element registration
import { MarketReport } from 'Services';

function MarketReportTabs() {
  const [reportData, setReportData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedCampaign, setSelectedCampaign] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = useState(true);
  const [sortConfig, setSortConfig] = useState({ key: '', direction: 'asc' });
  const [formFilters, setFormFilters] = useState([]);
  const [showCharts, setShowCharts] = useState(false);
  const [selectedSource, setSelectedSource] = useState('');
const [uniqueSources, setUniqueSources] = useState([]);

  const CustomTablePagination = (props) => (
      <TablePagination
        {...props}
        sx={{
          '& .MuiTablePagination-selectLabel': {
            marginBottom: 0,
          },
          '& .MuiTablePagination-displayedRows': {
            marginBottom: 0,
          },
        }}
      />
    );

     // Fetching data and other logic remains unchanged

  const handleToggleCharts = () => {
    setShowCharts((prev) => !prev);
  };

  useEffect(() => {
    const fetchMarketReport = async () => {
      try {
        const response = await MarketReport.fetchMarketReport();
        if (Array.isArray(response)) {
          setReportData(response);
          setFilteredData(response);
  
          // Extract unique campaigns
          const uniqueCampaigns = [...new Set(response.map((item) => item.Campaign || 'Unknown Campaign'))];
          setFormFilters(uniqueCampaigns);
  
          // Extract unique sources and remove "Unknown Source"
          const uniqueSourceList = [...new Set(response.map((item) => item.Source))]
            .filter((source) => source && source !== 'Unknown Source'); 
          setUniqueSources(uniqueSourceList);
        } else {
          console.warn('Unexpected API response:', response);
        }
      } catch (error) {
        console.error('Error fetching market report:', error);
      } finally {
        setLoading(false);
      }
    };
  
    fetchMarketReport();
  }, []);
  
  

  useEffect(() => {
    let filtered = reportData;

    if (selectedCampaign) {
      filtered = filtered.filter((item) => item.Campaign === selectedCampaign);
    }

    if (selectedSource) {
      filtered = filtered.filter((item) => item.Source === selectedSource);
    }

    if (startDate) {
      const startDateTime = new Date(startDate);
      startDateTime.setHours(0, 0, 0, 0); // Set to 00:00:00
      filtered = filtered.filter((item) => new Date(item.Created) >= startDateTime);
    }
    
    if (endDate) {
      const endDateTime = new Date(endDate);
      endDateTime.setHours(23, 59, 59, 999); // Set to 23:59:59
      filtered = filtered.filter((item) => new Date(item.Created) <= endDateTime);
    }
    

    setFilteredData(filtered);
    setPage(0);
  }, [selectedCampaign, selectedSource, reportData, startDate, endDate]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSort = (key) => {
    const direction =
      sortConfig.key === key && sortConfig.direction === 'asc' ? 'desc' : 'asc';
    setSortConfig({ key, direction });

    const sortedData = [...filteredData].sort((a, b) => {
      const aValue = a[key] || '';
      const bValue = b[key] || '';
      if (aValue < bValue) return direction === 'asc' ? -1 : 1;
      if (aValue > bValue) return direction === 'asc' ? 1 : -1;
      return 0;
    });

    setFilteredData(sortedData);
  };

  const calculateBarData = () => {
    const groupedData = filteredData.reduce((acc, item) => {
      const salesManager = item.SalesManager || 'Unknown Manager';
      if (!acc[salesManager]) {
        acc[salesManager] = { leads: 0, clients: 0 };
      }
      acc[salesManager].leads += 1;
      if (item.Converted === 'True') {
        acc[salesManager].clients += 1;
      }
      return acc;
    }, {});
  
    const labels = Object.keys(groupedData);
    const leadsData = labels.map((manager) => groupedData[manager].leads);
    const clientsData = labels.map((manager) => groupedData[manager].clients);
  
    // Create gradient for Leads (Red) and Clients (Green)
    const createGradient = (ctx, chartArea, startColor, endColor) => {
      const gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);
      gradient.addColorStop(0, startColor); // Start color
      gradient.addColorStop(1, endColor);   // End color
      return gradient;
    };
  
    return {
      labels,
      datasets: [
        {
          label: 'Leads',
          data: leadsData,
          backgroundColor: (context) => {
            const { chart } = context;
            const { ctx, chartArea } = chart;
            if (!chartArea) {
              return '#FF6F61'; // Fallback color if chartArea is not defined
            }
            return createGradient(ctx, chartArea, '#FF6F61', '#FF0000'); // Red gradient
          },
        },
        {
          label: 'Clients',
          data: clientsData,
          backgroundColor: (context) => {
            const { chart } = context;
            const { ctx, chartArea } = chart;
            if (!chartArea) {
              return '#5a5'; // Fallback color if chartArea is not defined
            }
            return createGradient(ctx, chartArea, '#36a2eb', '#00FF00'); // Green gradient
          },
        },
      ],
    };
  };
  
  

  const calculateLineData = () => {
    const monthlyData = filteredData.reduce((acc, item) => {
      const date = new Date(item.Created);
      const year = date.getFullYear();
      const month = date.getMonth(); // Get month as a number (0-11)
      const key = `${year}-${month}`; // Create a unique key for sorting
  
      if (!acc[key]) {
        acc[key] = { leads: 0, clients: 0, year, month };
      }
      if (item.Converted === 'True') {
        acc[key].clients += 1;
      } else {
        acc[key].leads += 1;
      }
      return acc;
    }, {});
  
    const sortedKeys = Object.keys(monthlyData).sort((a, b) => {
      const [yearA, monthA] = a.split('-').map(Number);
      const [yearB, monthB] = b.split('-').map(Number);
      return new Date(yearA, monthA) - new Date(yearB, monthB);
    });
  
    const labels = sortedKeys.map((key) => {
      const { year, month } = monthlyData[key];
      return `${new Date(year, month).toLocaleString('default', { month: 'long' })} ${year}`;
    });
  
    const leadsData = sortedKeys.map((key) => monthlyData[key].leads);
    const clientsData = sortedKeys.map((key) => monthlyData[key].clients);
  
    const createGradient = (ctx, chartArea) => {
      const gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);
      gradient.addColorStop(0, 'rgba(54, 162, 235, 0.2)'); // Light blue
    gradient.addColorStop(1, 'rgba(54, 162, 235, 0.8)'); // Dark blue
      return gradient;
    };
  
    return {
      labels,
      datasets: [
        {
          label: 'Leads',
          data: leadsData,
          borderColor: '#36a2eb',
          backgroundColor: (context) => {
            const { chart } = context;
            const { ctx, chartArea } = chart;
            return chartArea ? createGradient(ctx, chartArea) : '#36a2eb';
          },
          fill: true,
        },
        {
          label: 'Clients',
          data: clientsData,
          borderColor: '#5a5',
          backgroundColor: (context) => {
            const { chart } = context;
            const { ctx, chartArea } = chart;
            return chartArea
              ? createGradient(ctx, chartArea, 'rgba(90, 165, 90, 0.2)', 'rgba(90, 165, 90, 0.8)')
              : '#5a5';
          },
          fill: true,
        },
      ],
    };
  };
  

  const calculatePieData = () => {
    const groupedData = filteredData.reduce(
      (acc, item) => {
        if (item.Converted === 'True') {
          acc.converted += 1;
        } else {
          acc.notConverted += 1;
        }
        return acc;
      },
      { converted: 0, notConverted: 0 }
    );

    return {
      labels: ['Converted', 'Not Converted'],
      datasets: [
        {
          data: [groupedData.converted, groupedData.notConverted],
          backgroundColor: ['#5a5', '#e66767'],
        },
      ],
    };
  };

  const calculateDoughnutData = () => {
    const groupedData = filteredData.reduce((acc, item) => {
      const status = item.Status || 'Unknown';
      acc[status] = (acc[status] || 0) + 1;
      return acc;
    }, {});

    return {
      labels: Object.keys(groupedData),
      datasets: [
        {
          data: Object.values(groupedData),
          backgroundColor: [
            '#88c0d0', // Dusty Blue
            '#f08c65', // Warm Peach
            '#a3be8c', // Sage Green
            '#d08770', // Muted Coral
            '#b48ead', // Mauve Purple
            '#ebcb8b', // Golden Beige
            '#bf616a', // Soft Cranberry
            '#e5e9f0', // Soft Gray
            '#81a1c1', // Denim Blue
            '#d8a3a1', // Rosewood
            '#a29bfe', // Periwinkle
            '#fab1a0', // Muted Salmon
            '#74b49b', // Forest Mint
            '#ffcb77'  // Sunset Gold
        ],
        
        
        },
      ],
    };
  };

  const lineData = {
    labels: ['January', 'February', 'March', 'April', 'May'],
    datasets: [
      {
        label: 'Performance',
        data: [65, 59, 80, 81, 56],
        borderColor: '#36a2eb',
        backgroundColor: 'rgba(54, 162, 235, 0.5)',
      },
    ],
  };



  const columns = [
    { id: 'Created', label: 'Created' },
    { id: 'SalesManager', label: 'Sales Manager' },
    { id: 'Name', label: 'Name' },
    { id: 'Email', label: 'Email' },
    { id: 'Source', label: 'Source' },
    { id: 'Campaign', label: 'Campaign' },
    { id: 'Channel', label: 'Channel' },
    { id: 'Status', label: 'Status' },
    { id: 'Converted', label: 'Converted' },
    { id: 'Deposit', label: 'Deposit' },
  ];

  return (
    <Box sx={{ padding: 2 }}>
       <style>
        {`
          .form-group.w-75 {
            display: none;
        
          }
            .form-inline.search-fulls.col-7 {
              border: none;
            }

              .MuiBox-root.css-19midj6 {
                  padding: 3px;
                }
        `}
      </style>

      <Card>
        <CardContent>
        <Box sx={{ display: 'flex', gap: 2, marginBottom: 3 }}>
  {/* Campaign Dropdown */}
  <FormControl sx={{ width: '255px', height: '38px' }}>

  <Select
    labelId="campaign-select-label"
    value={selectedCampaign}
    onChange={(e) => setSelectedCampaign(e.target.value)}
    displayEmpty
    sx={{
      height: '38px', 
      '& .MuiSelect-select': {
        height: '38px',
        display: 'flex',
        alignItems: 'center',
        padding: '0 14px',
      },
    }}
  >
    <MenuItem value="">
      <em>All Campaigns</em>
    </MenuItem>
    {formFilters.map((campaign) => (
      <MenuItem key={campaign} value={campaign}>
        {campaign}
      </MenuItem>
    ))}
  </Select>
</FormControl>

 {/* Source Dropdown */}
 <FormControl sx={{ width: '255px', height: '38px' }}>
    <Select
      labelId="source-select-label"
      value={selectedSource}
      onChange={(e) => setSelectedSource(e.target.value)}
      displayEmpty
      sx={{
        height: '38px',
        '& .MuiSelect-select': {
          height: '38px',
          display: 'flex',
          alignItems: 'center',
          padding: '0 14px',
        },
      }}
    >
      <MenuItem value="">
        <em>All Sources</em>
      </MenuItem>
      {uniqueSources.map((source) => (
        <MenuItem key={source} value={source}>
          {source}
        </MenuItem>
      ))}
    </Select>
  </FormControl>



  {/* Start Date */}
  <TextField
    type="date"
    value={startDate}
    onChange={(e) => setStartDate(e.target.value)}
    InputLabelProps={{ shrink: true }}
    sx={{
      width: '255px',
      '& input': { height: '38px', padding: '0 14px' },
    }}
  />

  {/* End Date */}
  <TextField
    type="date"
    value={endDate}
    onChange={(e) => setEndDate(e.target.value)}
    InputLabelProps={{ shrink: true }}
    sx={{
      width: '255px',
      '& input': { height: '38px', padding: '0 14px' },
    }}
  />

  {/* Button with Same Width & Height */}
  <Button
    variant="contained"
    color="primary"
    onClick={handleToggleCharts}
    sx={{
      width: '255px',
      height: '38px', // Same as inputs & dropdown
      padding: '0 14px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}
  >
    {showCharts ? 'Hide Infographic' : 'Show Infographic'}
  </Button>
</Box>




          {showCharts && (
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Bar
                  data={calculateBarData()}
                  options={{
                    responsive: true,
                    plugins: {
                      legend: { position: 'bottom' },
                      title: { display: true, text: 'Sales Manager Leads and Clients ' },
                    },
                    layout: {
                      padding: { bottom: 20 },
                    },
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <Line
                  data={calculateLineData()}
                  options={{
                    responsive: true,
                    plugins: {
                      legend: { position: 'bottom' },
                      title: { display: true, text: 'Leads and Clients Over Time' },
                    },
                    layout: {
                      padding: { bottom: 20 },
                    },
                  }}
                />
              </Grid>
              <Grid item xs={1}>   
              </Grid>
              <Grid item xs={4}>
                <Pie
                  data={calculatePieData()}
                  options={{
                    responsive: true,
                    plugins: {
                      legend: { position: 'right' },
                      title: { display: true, text: 'Conversion Rates' },
                    },
                    layout: { padding: { bottom: 20 } },
                  }}
                />
              </Grid>
              <Grid item xs={2}>   
              </Grid>
              <Grid item xs={4}>
                <Doughnut
                  data={calculateDoughnutData()}
                  options={{
                    responsive: true,
                    plugins: {
                      legend: { position: 'right' },
                      title: { display: true, text: 'Status Distribution' },
                    },
                    layout: { padding: { bottom: 20 } },
                  }}
                />
              </Grid>
            </Grid>
          )}

          {loading ? (
            <Typography variant="body1">Loading...</Typography>
          ) : (
            <TableContainer component={Paper} sx={{ marginTop: 3 }}>
              <Table aria-label="Market report table">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ backgroundColor: '#f0f0f0', fontWeight: 'bold' }}>#</TableCell>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      sx={{
                        backgroundColor: '#f0f0f0', // Light grey background
                        fontWeight: 'bold', // Bold text
                        color: '#333', // Dark text
                      }}
                    >
                      <TableSortLabel
                        active={sortConfig.key === column.id}
                        direction={sortConfig.direction}
                        onClick={() => handleSort(column.id)}
                      >
                        {column.label}
                      </TableSortLabel>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>

                <TableBody>
                  {filteredData
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((item, index) => (
                      <TableRow key={index}>
                        <TableCell>{page * rowsPerPage + index + 1}</TableCell>
                        <TableCell>{new Date(item.Created).toLocaleString()}</TableCell>
                        <TableCell>{item.SalesManager || 'N/A'}</TableCell>
                        <TableCell>{item.Name || 'N/A'}</TableCell>
                        <TableCell>{item.Email || 'N/A'}</TableCell>
                        <TableCell>{item.Source || 'N/A'}</TableCell>
                        <TableCell>{item.Campaign || 'N/A'}</TableCell>
                        <TableCell>{item.Channel || 'N/A'}</TableCell>
                        <TableCell>{item.Status || 'N/A'}</TableCell>
                        <TableCell
                          style={{
                            color: item.Converted === 'True' ? '#5a5' : '#e66767',
                            fontWeight: 'bold',
                          }}
                        >
                          {item.Converted === 'True' ? 'Yes' : 'No'}
                        </TableCell>
                        <TableCell>{item.Deposit || 0}</TableCell>
                      </TableRow>
                    ))}
                  {filteredData.length === 0 && (
                    <TableRow>
                      <TableCell colSpan={11} align="center">
                        No data available
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>

              <CustomTablePagination
                rowsPerPageOptions={[10, 25, 50, 100, 500]}
                component="div"
                count={filteredData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableContainer>
          )}
        </CardContent>
      </Card>
    </Box>
  );
}

export default MarketReportTabs;
