import React, { useState, Fragment } from "react";
import { useSelector } from "react-redux";
import NotContactedLeadsReport from "./NotContactedLeadsReport";
import OrganicLeadsReport from "./OrganicLeadsReport";
import MyDepositsReport from "./MyDepositsReport";
import MyWithdrawalsReport from "./MyWithdrawalsReport";
import ActiveIBReport from "./ActiveIBReport";
import InActiveIBReport from "./InActiveIBReport";
import DormantIBReport from "./DormantIBReport";
import MyIBReport from "./MyIBReport";
import RebateIB from "./RebateIB";
import CPAIB from "./CPAIB";
import NTPIB from "./NTPIB";
import MasterIB from "./MasterIB";
import ActiveClient from "./ActiveClient";
import InActiveClient from "./InActiveClient";
import DormantClient from "./DormantClient";
import { Col, Row, Card, CardHeader, Input, FormGroup } from "reactstrap";
import { TextField, Button } from "@mui/material";
import infoIcon from "../../../assets/images/information.png";

const SalesReports = () => {
  const [selectedReport, setSelectedReport] = useState("getNotContactedLeads");
  const [searchTerm, setSearchTerm] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [salesFilter, setSalesFilter] = useState("");
  const [tooltipVisible, setTooltipVisible] = useState(false); // Ensure tooltipVisible is defined here
  const [showCharts, setShowCharts] = useState(false);


  // Fetching data and other logic remains unchanged

  const handleToggleCharts = () => {
    setShowCharts((prev) => !prev);
  };


  // Access roleId from Redux store
  const profile = useSelector((state) => state.login && state.login.profile);
  const roleId = profile?.roleId || null;

  // Adjust end date if start and end dates are selected with a range, making sure to include full range of dates
  const adjustedEndDate = () => {
    if (startDate && endDate) {
      const start = new Date(startDate);
      const end = new Date(endDate);

      // If start date and end date are the same, adjust the end date to 23:59:59.999 (end of the same day)
      if (start.getTime() === end.getTime()) {
        end.setHours(23, 59, 59, 999); // Adjust the end date to 23:59:59.999
      }
      // If start date and end date are different, adjust the end date to 23:59:59 of the end date
      else {
        end.setHours(23, 59, 59, 999); // Set the time to 23:59:59.999 for end date
      }
      return end.toISOString().slice(0, 19); // Return in ISO format YYYY-MM-DDTHH:mm:ss
    }
    return endDate;
  };

  // Dynamic message based on selected report
  const getDynamicMessage = () => {
    switch (selectedReport) {
      case "getNotContactedLeads":
        return (
          <>
            This report displays a list of 'Not Contacted Leads,' which includes both new and reopened leads.
            <br />
            The table contains the following data:
            <br />
            Created Date, Partner, Sales, Name, Country, Status, and Days (number of days not contacted).
          </>
        );
      case "getOrganicLeads":
        return (
          <>
            This report displays the list of "Organic Leads"
            <br />
            The table contains the following data:
            <br />
            Created Date, Partner , Sales, Name, Country, Status, and Days (number of days since the lead was created).
          </>
        );
      case "getMyDeposits":
        return (
          <>
            This report shows the deposits made, with details like Created Date, Partner, Sales, Name, Customer ID,
            <br />
            Deposit Status, Amount, and Account Number.

          </>
        );
      case "getMyWithdrawals":
        return (
          <>
            This report shows the withdrawals made, with details like Created Date, Partner, Sales, Name, Customer ID,
            <br />
            Account Number, Withdrawal Status, Amount.

          </>
        );
      case "getActiveIB":
        return (
          <>
            This report shows active Partne's  (Introducing Brokers) who are active along with new clients referred in the
            <br />
            last 30 days. It includes details like Created Date, Partner ID, Partner Name, Sales, Payout, Rebate, and the
            <br />
            number of clients referred.
          </>
        );
      case "getInActiveIB":
        return (
          <>
            This report shows inactive Partner's (Introducing Brokers) who are active but have not referred new clients in
            <br />
            the past 30 days, although rebates have been generated. It includes details like Created Date, Partner ID, Partner 
            <br />
            Name, Sales, Payout, Rebate, and the number of clients referred in the last 30 days before.
          </>
        );
      case "DormantIBReport":
        return (
          <>

            This report displays <strong>Dormant Introducing Brokers (Partner's)</strong> who have an active status but have
            <br />
            not referred any new clients in the past 30 days.
            <br />
            It includes key details such as <strong>Created Date, Partner ID, Partner Name, Sales, Payout, Rebate (outstanding rebate),</strong>
            <br />
            and the number of clients referred in the past 30 days.
          </>
        );
      case "MyIBReport":
        return (
          <>
            This report displays details of <strong>My Introducing Brokers (Partner's)</strong>, focusing on their rebate and payout information.
            <br />
            It includes key details such as <strong>Created Date, Partner ID, Partner Name, Sales, Total Rebate (including outstanding rebates),</strong>
            <br />
            and <strong>Total Rebate Payout.</strong>
          </>
        );
      case "RebateIB":
        return (
          <>
            This report displays <strong>My Introducing Brokers (Partner's)</strong> who have <strong>Rebate profiles</strong>,
            <br />
            showing only Partner's under the rebate model.
            <br />
            It includes key details such as <strong>Created Date, Partner ID, Sales, Partner Name,</strong> and <strong>Partner Type.</strong>

          </>
        );
      case "CPAIB":
        return (
          <>
            This report displays <strong>My Introducing Brokers (Partner)</strong> who have <strong>CPA profiles</strong>,
            <br />
            showing only Partner under the CPA model.
            <br />
            It includes key details such as <strong>Created Date, Partner ID, Sales, Partner Name,</strong> and <strong>Partner Type.</strong>
          </>
        );
      case "NTPIB":
        return (
          <>
            This report displays <strong>My Introducing Brokers (Partner)</strong> who have <strong>NTP profiles</strong>,
            <br />
            showing only IBs under the NTP model.
            <br />
            It includes key details such as <strong>Created Date, Partner ID, Sales, Partner Name,</strong> and <strong>Partner Type.</strong>
          </>
        );
      case "MasterIB":
        return (
          <>
            This report displays <strong>My Master Introducing Brokers (Partner)</strong>, showing only Partner designated as Master Partner.
            <br />
            It includes key details such as <strong>Created Date, Partner ID, Sales, Partner Name,</strong> and <strong>Partner Type.</strong>
          </>
        );
      case "ActiveClient":
        return (
          <>
            My active client table displays all clients who are <strong>currently trading,</strong>
            <br />
            along with their account balance and equity. and account creation date.
            <br />
            The data filter does not work for this report because the data is shown in real-time.
          </>
        );

        case "InActiveClient":
          return (
            <>
              My Inactive Clients table displays all clients who have had <strong>no deposits or trades</strong> 
              in the past <strong>10 days</strong>.
              <br />
              This table includes details such as Created Date, Partner, Sales, Name, Account Number, Amount, and Equity.
              <br />
              The data is shown in real-time, and filtering does not apply to this report.
            </>
          );
        
          case "DormantClient":
            return (
              <>
                My Dormant Clients table displays all clients who have had <strong>no deposits or trades</strong> 
                in the past <strong>30 days</strong>.
                <br />
                This table includes details such as Created Date, Partner, Sales, Name, Account Number, Withdrawal Status, 
                Amount, and Equity.
                <br />
                The data is shown in real-time, and filtering does not apply to this report.
              </>
            );
          
      default:
        return "";
    }
  };
  const renderReport = () => {
    switch (selectedReport) {
      case "getNotContactedLeads":
        return (
          <NotContactedLeadsReport
            searchTerm={searchTerm}
            startDate={startDate}
            endDate={adjustedEndDate()}
            showCharts={showCharts} // Pass showCharts as a prop
          />
        );
      case "getOrganicLeads":
        return (
          <OrganicLeadsReport
            searchTerm={searchTerm}
            startDate={startDate}
            endDate={adjustedEndDate()}
            salesFilter={salesFilter}
            showCharts={showCharts}
          />
        );
      case "getMyDeposits":
        return (
          <MyDepositsReport
            searchTerm={searchTerm}
            startDate={startDate}
            endDate={adjustedEndDate()}
            showCharts={showCharts}
          />
        );
      case "getMyWithdrawals":
        return (
          <MyWithdrawalsReport
            searchTerm={searchTerm}
            startDate={startDate}
            endDate={adjustedEndDate()}
            showCharts={showCharts}
          />
        );
      case "getActiveIB":
        return (
          <ActiveIBReport
            searchTerm={searchTerm}
            startDate={startDate}
            endDate={adjustedEndDate()}
            showCharts={showCharts}
          />
        );
      case "getInActiveIB":
        return (
          <InActiveIBReport
            searchTerm={searchTerm}
            startDate={startDate}
            endDate={adjustedEndDate()}
            showCharts={showCharts}
          />
        );
      case "DormantIBReport":
        return (
          <DormantIBReport
            searchTerm={searchTerm}
            startDate={startDate}
            endDate={adjustedEndDate()}
          // showCharts={showCharts} 
          />
        );
      case "MyIBReport":
        return (
          <MyIBReport
            searchTerm={searchTerm}
            startDate={startDate}
            endDate={adjustedEndDate()}
          // showCharts={showCharts} 
          />
        );
      case "RebateIB":
        return (
          <RebateIB
            searchTerm={searchTerm}
            startDate={startDate}
            endDate={adjustedEndDate()}
          // showCharts={showCharts} 
          />
        );
      case "CPAIB":
        return (
          <CPAIB
            searchTerm={searchTerm}
            startDate={startDate}
            endDate={adjustedEndDate()}
          // showCharts={showCharts} 
          />
        );
      case "NTPIB":
        return (
          <NTPIB
            searchTerm={searchTerm}
            startDate={startDate}
            endDate={adjustedEndDate()}
          // showCharts={showCharts} 
          />
        );
      case "MasterIB":
        return (
          <MasterIB
            searchTerm={searchTerm}
            startDate={startDate}
            endDate={adjustedEndDate()}
          // showCharts={showCharts} 
          />
        );
      case "ActiveClient":
        return (
          <ActiveClient
            searchTerm={searchTerm}
          />
        );
        case "InActiveClient":
        return (
          <InActiveClient
            searchTerm={searchTerm}
          />
        );
        case "DormantClient":
        return (
          <DormantClient
            searchTerm={searchTerm}
          />
        );
      default:
        return <p>Select a report to view data.</p>;
    }
  };

  return (
    <Fragment>
      <style>
        {`
          .form-group.w-75 {
            display: none;
        
          }
            .form-inline.search-fulls.col-7 {
              border: none;
            }

          .MuiBox-root.css-19midj6 {
                  padding: 3px;
            }
        `}
      </style>
      {/* Adding the new div before the SalesReports content */}
      <div style={{ height: "20px" }} className="container-fluid">
        <div className="page-title" style={{ display: "none" }}>
          <div className="row">
            <div className="col-6"></div>
            <div className="col-6">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="/dashboard">Dashboard</a>
                </li>
                <li className="breadcrumb-item active">Clients</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      {/* SalesReports Component */}
      <Col sm="12">
        <Card>
          <CardHeader>
            <Row className="align-items-center">
              <Col md={1} style={{ marginRight: '-56px' }}>
                <div
                  style={{ position: "relative", display: "inline-block" }}
                  onMouseEnter={() => setTooltipVisible(true)}
                  onMouseLeave={() => setTooltipVisible(false)}
                >
                  <img src={infoIcon} alt="Info Icon" style={{ width: '30px', height: '30px', cursor: "pointer" }} />
                  {tooltipVisible && (
                    <div
                      style={{
                        position: "absolute",
                        top: "50%", // Aligns it vertically centered
                        left: "100%", // Positions it to the right of the icon
                        transform: "translateY(-50%)", // Centers it vertically
                        backgroundColor: "#e2e2e2",
                        color: "black",
                        padding: "5px 10px",
                        borderRadius: "4px",
                        fontSize: "12px",
                        whiteSpace: "nowrap",
                        zIndex: 10000,
                        border: "1px solid white",
                        marginLeft: "8px", // Adds some spacing between the icon and tooltip
                      }}
                    >
                      {getDynamicMessage()}
                    </div>
                  )}
                </div>
              </Col>
              <Col md={2}>
                <FormGroup>
                  <Input
                    type="select"
                    id="reportSelector"
                    value={selectedReport}
                    onChange={(e) => setSelectedReport(e.target.value)}
                    style={{ height: '38px', marginBottom: '-17px' }} // Adjust height and margin
                  >
                    <option value="getNotContactedLeads">Not Contacted Leads</option>
                    <option value="getOrganicLeads">Organic Leads</option>
                    <option value="getMyDeposits">My Deposits</option>
                    <option value="getMyWithdrawals">My Withdrawals</option>
                    <option value="ActiveClient">My Active Client</option>
                    <option value="InActiveClient">My InActive Client</option>
                    <option value="DormantClient">My Dormant Client</option>
                    <option value="getActiveIB">Active Partner</option>
                    <option value="getInActiveIB">Non Active Partner</option>
                    <option value="DormantIBReport">Dormant Partner</option>
                    <option value="MyIBReport">My Partner Rebate and Payout </option>
                    <option value="RebateIB">My Partner with Rebate profiles </option>
                    <option value="CPAIB"> My Partner with CPA profiles </option>
                    <option value="NTPIB">My Partner with NTP profiles</option>
                    <option value="MasterIB">My Master Partner</option>
                  </Input>
                </FormGroup>
              </Col>
              <Col md={2}>
                <TextField
                  placeholder="Search" // Use placeholder instead of label
                  variant="outlined"
                  fullWidth
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  InputProps={{
                    style: {
                      height: '38px',
                      padding: '0 14px',
                      display: 'flex',
                      alignItems: 'center', // Align text vertically
                    },
                  }}
                />
              </Col>
              <Col md={2}>
                <TextField
                  placeholder="Start Date" // Use placeholder instead of label
                  type="date"
                  fullWidth
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                  InputProps={{
                    style: {
                      height: '38px',
                      padding: '0 14px',
                      display: 'flex',
                      alignItems: 'center', // Align text vertically
                    },
                  }}
                />
              </Col>
              <Col md={2}>
                <TextField
                  placeholder="End Date" // Use placeholder instead of label
                  type="date"
                  fullWidth
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                  InputProps={{
                    style: {
                      height: '38px',
                      padding: '0 14px',
                      display: 'flex',
                      alignItems: 'center', // Align text vertically
                    },
                  }}
                />
              </Col>
              <Col>
                {/* Button to toggle chart visibility */}
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleToggleCharts}
                  sx={{ height: 'fit-content' }}
                  className="chartbutton"
                >
                  {showCharts ? 'Hide Infographic' : 'Show Infographic'}
                </Button>
              </Col>


            </Row>
          </CardHeader>
          {renderReport(showCharts)}
        </Card>
      </Col>
    </Fragment>
  );
};

export default SalesReports;
