import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import { SalesReportService } from "Services";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { Grid } from '@mui/material';

const ActiveClient = ({ searchTerm, showCharts }) => {
    const [filteredData, setFilteredData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [groupMembers, setGroupMembers] = useState([]);

    const profile = useSelector((state) => state.login?.profile);
    const roleId = profile?.roleId || null;
    const userId = profile?.id || null;

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleDateString("en-GB"); // Format: DD/MM/YYYY
    };

    useEffect(() => {
        if (userId) {
            const fetchGroupMembers = async () => {
                try {
                    const response = await SalesReportService.getGroupMembersById(userId);
                    setGroupMembers(response);
                } catch (err) {
                    console.error("Failed to fetch group members:", err);
                    toast.error("Failed to fetch group members.");
                    setGroupMembers([]);
                }
            };
            fetchGroupMembers();
        }
    }, [userId]);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            setError("");
            try {
                const response = await SalesReportService.getActiveClient();
                let filteredResponse = response;
                if (roleId !== 33 && roleId !== 18 && roleId !== 41&&roleId !== 35&&roleId !== 37&&roleId !== 39) {
                    if (groupMembers.length > 0) {
                        filteredResponse = response.filter((item) => groupMembers.includes(item.agentId));
                    } else {
                        filteredResponse = response.filter((item) => item.agentId === userId);
                    }
                }
                setFilteredData(filteredResponse);
            } catch (err) {
                setError("Failed to fetch data.");
                toast.error("Failed to fetch data.");
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, [roleId, userId, groupMembers]);

    useEffect(() => {
        let filtered = filteredData;
        if (searchTerm) {
            const lowercasedTerm = searchTerm.toLowerCase();
            filtered = filtered.filter((item) =>
                Object.values(item).some(
                    (value) => value && value.toString().toLowerCase().includes(lowercasedTerm)
                )
            );
        }
        setFilteredData(filtered);
    }, [searchTerm]);

    return (
        <div style={{ position: "relative" }}>
            <style>
                {`
          .chartbutton {
           display: none;
          }
        `}
            </style>
            {error && <p className="text-danger">{error}</p>}
            {loading ? (
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100px", color: "#007bff" }}>
                    <div className="spinner-border text-primary" role="status"><span className="sr-only">Loading...</span></div>
                </div>
            ) : filteredData.length > 0 ? (
                <Grid container spacing={1}>
                    <Grid item xs={12} md={12}>
                        <DataTable columns={[
                            {
                                name: "Created Date",
                                selector: (row) => formatDate(row.createdAt),
                                sortable: true,
                            },
                            { name: "Partner Name", selector: (row) => row.IB, sortable: true },
                            { name: "Sales", selector: (row) => row.Sales, sortable: true },
                            { name: "Name", selector: (row) => row.ClientName, sortable: true },
                            { name: "Ac Number", selector: (row) => row.login, sortable: true },
                            { name: "Balance", selector: (row) => (row.Balance ? row.Balance.toFixed(2) : "0.00"), sortable: true },
                            { name: "Equity", selector: (row) => (row.Equity ? row.Equity.toFixed(2) : "0.00"), sortable: true },
                        ]} data={filteredData} pagination striped persistTableHead />
                    </Grid>
                </Grid>
            ) : (
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100px", color: "#007bff" }}>
                    No data available.
                </div>
            )}
        </div>
    );
};

export default ActiveClient;
