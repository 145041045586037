import React, { Fragment, useState, useEffect } from "react";
import {
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import DataTable from "react-data-table-component";
import Select from "react-select";
import { Cashback } from "Services";
import { toast } from "react-toastify";
import { TextField, TablePagination } from "@mui/material";
import { Edit, Trash } from "react-feather";

const platformOptions = [
  { value: "MT4", label: "MT4" },
  { value: "MT5", label: "MT5" },
];

const CashbackComponent = () => {
  const [modal, setModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [data, setData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedRow, setSelectedRow] = useState(null);
  const [formValues, setFormValues] = useState({
    groupName: "",
    platform: "",
    perlot: "",
  });
  const [editValues, setEditValues] = useState({
    id: null,
    groupName: "",
    platform: "",
    perlot: "",
  });

  const toggle = () => setModal(!modal);
  const toggleEditModal = () => {
    console.log("Toggling Edit Modal. Current State:", editModal);
    setEditModal(!editModal);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setEditValues({ ...editValues, [name]: value });
  };

  const handleSelectChange = (selectedOption) => {
    setFormValues({ ...formValues, platform: selectedOption.value });
  };

  const handleEditSelectChange = (selectedOption) => {
    setEditValues({ ...editValues, platform: selectedOption.value });
  };

  useEffect(() => {
    Cashback.Cashback()
      .then((res) => {
        console.log("Fetched cashback data:", res);
        setData(res);
      })
      .catch((error) => {
        console.error("Error fetching cashback data:", error);
        toast.error("Error fetching cashback data");
      });
  }, []);

  const handleSubmit = () => {
    Cashback.createCashback(formValues)
      .then((res) => {
        console.log("API Response:", res);
        if (res && res.slno) {
          setData([...data, res]);
          setFormValues({ groupName: "", platform: "", perlot: "" });
          toggle();
          toast.success("Cashback group added successfully!");
        } else {
          toast.error("Failed to add cashback group");
        }
      })
      .catch((error) => {
        console.error("Error adding cashback group:", error);
        toast.error(error.message || "Error adding cashback group");
      });
  };

  const handleEditSubmit = () => {
    Cashback.updateCashback(editValues.id, editValues)
      .then((res) => {
        console.log("API Response from updateCashback:", res); // Debugging response

        // Check if response is an array and contains "1" (indicating success)
        if (Array.isArray(res) && res[0] === 1) {
          setData(
            data.map((item) =>
              item.slno === editValues.id ? { ...item, ...editValues } : item
            )
          );
          toggleEditModal();
          toast.success("Cashback group updated successfully!");
        } else {
          console.warn("Unexpected API Response:", res);
          toast.error("Failed to update cashback group");
        }
      })
      .catch((error) => {
        console.error("Error updating cashback group:", error);
        toast.error(error.message || "Error updating cashback group");
      });
  };

  const handleEditClick = (row) => {
    console.log("Edit button clicked for:", row);
    setEditValues({
      id: row.slno,
      groupName: row.groupName,
      platform: row.platform,
      perlot: row.perlot,
    });
    toggleEditModal();
  };

  const toggleDeleteModal = () => setDeleteModal(!deleteModal);

  const handleDeleteClick = (row) => {
    setSelectedRow(row);
    toggleDeleteModal();
  };

  const handleDeleteConfirm = () => {
    Cashback.deleteCashback(selectedRow.slno)
      .then(() => {
        setData(data.filter((item) => item.slno !== selectedRow.slno));
        toast.success("Cashback group deleted successfully!");
        toggleDeleteModal();
      })
      .catch((error) => toast.error("Error deleting cashback group"));
  };

  const tableColumns = [
    { name: "Sl. No", selector: (row) => row.slno, sortable: true },
    { name: "Group Name", selector: (row) => row.groupName, sortable: true },
    { name: "Platform", selector: (row) => row.platform, sortable: true },
    { name: "$ Per Lot", selector: (row) => row.perlot, sortable: true },
    {
      name: "Action",
      cell: (row) => (
        <div className="d-flex gap-2">
          <Button
            color="warning"
            size="sm"
            onClick={() => handleEditClick(row)}
          >
            <Edit size={16} />
          </Button>
          <Button
            color="danger"
            size="sm"
            onClick={() => handleDeleteClick(row)}
          >
            <Trash size={16} />
          </Button>
        </div>
      ),
    },
  ];

  return (
    <Fragment>
      <style>
        {`
          .form-group.w-75 {
            display: none;
        
          }
            .form-inline.search-fulls.col-7 {
              border: none;
            }

          .MuiBox-root.css-19midj6 {
                  padding: 3px;
            }
        `}
      </style>
      {/* Adding the new div before the SalesReports content */}
      <div style={{ height: "20px" }} className="container-fluid">
        <div className="page-title" style={{ display: "none" }}>
          <div className="row">
            <div className="col-6"></div>
            <div className="col-6">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="/dashboard">Dashboard</a>
                </li>
                <li className="breadcrumb-item active">Clients</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <Col sm="12">
        <Card>
          <CardHeader className="d-flex justify-content-between align-items-center">
            <h5>Cashback Group</h5>
            <Button color="primary" onClick={toggle}>
              Add
            </Button>
          </CardHeader>
          <CardBody>
            <TextField
              label="Search"
              variant="outlined"
              fullWidth
              sx={{ marginBottom: 2 }}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <DataTable
              data={data
                .filter((row) =>
                  Object.values(row).some((value) =>
                    value
                      .toString()
                      .toLowerCase()
                      .includes(searchTerm.toLowerCase())
                  )
                )
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)}
              columns={tableColumns}
              striped
              center
              persistTableHead
            />
            <TablePagination
              rowsPerPageOptions={[10, 25, 50, 100, 250, 500, 1000]}
              component="div"
              count={data.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={(event, newPage) => setPage(newPage)}
              onRowsPerPageChange={(event) =>
                setRowsPerPage(parseInt(event.target.value, 10))
              }
              sx={{
                "& .MuiTablePagination-selectLabel": {
                  marginBottom: 0,
                },
                "& .MuiTablePagination-displayedRows": {
                  marginBottom: 0,
                },
              }}
            />
          </CardBody>
        </Card>
      </Col>

      {/* Add Modal */}
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Add Cashback Group</ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup>
              <Label for="groupName">Group Name</Label>
              <Input
                type="text"
                name="groupName"
                value={formValues.groupName}
                onChange={handleChange}
              />
            </FormGroup>
            <FormGroup>
              <Label for="platform">Platform</Label>
              <Select options={platformOptions} onChange={handleSelectChange} />
            </FormGroup>
            <FormGroup>
              <Label for="perlot">Per Lot</Label>
              <Input
                type="number"
                name="perlot"
                value={formValues.perlot}
                onChange={handleChange}
              />
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleSubmit}>
            Submit
          </Button>
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>

      {/* Edit Modal */}
      <Modal isOpen={editModal} toggle={toggleEditModal}>
        <ModalHeader toggle={toggleEditModal}>Edit Cashback Group</ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup>
              <Label>Group Name</Label>
              <Input
                type="text"
                name="groupName"
                value={editValues.groupName}
                onChange={handleEditChange}
              />
            </FormGroup>
            <FormGroup>
              <Label>Platform</Label>
              <Select
                options={platformOptions}
                value={platformOptions.find(
                  (opt) => opt.value === editValues.platform
                )}
                onChange={handleEditSelectChange}
              />
            </FormGroup>
            <FormGroup>
              <Label>Per Lot</Label>
              <Input
                type="number"
                name="perlot"
                value={editValues.perlot}
                onChange={handleEditChange}
              />
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleEditSubmit}>
            Update
          </Button>
          <Button color="secondary" onClick={toggleEditModal}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>

      {/* Delete Confirmation Modal */}
      <Modal isOpen={deleteModal} toggle={toggleDeleteModal}>
        <ModalHeader toggle={toggleDeleteModal}>Confirm Delete</ModalHeader>
        <ModalBody>
          Are you sure you want to delete this cashback group?
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={handleDeleteConfirm}>
            Delete
          </Button>
          <Button color="secondary" onClick={toggleDeleteModal}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

export default CashbackComponent;
