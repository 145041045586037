import React, { Fragment, useEffect, useState } from "react";
import {
  tradingAccountsService,
  accountTypeService,
  accountService,
  clientService,
} from "Services";
import AuthService from "../../../../Services/auth.service";
import {
  Col,
  Row,
  Table,
  Pagination,
  PaginationItem,
  PaginationLink,
} from "reactstrap";
import TableLoader from "CommonElements/Table/TableLoader";
import TableNodata from "CommonElements/Table/TableNodata";
import TablePagination from "CommonElements/Table/WithoutFilterPagination";
import AddAccount from "./AddAccount";
import OpenPositions from "./OpenPositions";
import ClosePositions from "./ClosePositions";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getMenuPerm } from "utils";
import LinkAccount from "./LinkAccount";
import { toast } from "react-toastify";
import { FSwitch } from "CommonElements/Form";

const TradingAccounts = (props) => {
  const RESTRICTED_ROLES = [19, 41, 46, 56, 53];
  const profile = useSelector((state) => state.login?.profile || {});
  const roleId = profile?.Role?.id;
  const isRestrictedRole = RESTRICTED_ROLES.includes(roleId);

  const history = useNavigate();
  const { customerId = "", activeTab, tabNumber } = props;
  const [rec, setRec] = useState({});
  const [openPositions, setOpenPositions] = useState({
    loading: false,
    positions: [],
  });
  const [closePositions, setClosePositions] = useState({
    loading: false,
    page: 1,
    size: 5,
    data: [],
  });
  const [selectedAccount, setSelectedAccount] = useState();
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 5,
  });

  const permissions = useSelector(
    (state) =>
      (state.login &&
        state.login.profile &&
        state.login.profile.Role &&
        state.login.profile.Role.permissions) ||
      {}
  );
  const [accountTypes, setAccountTypes] = useState([]);
  const [closeLimit, setCloseLimit] = useState(10);
  const [openLimit, setOpenLimit] = useState(10);
  const [accountId, setAccountId] = useState(0);
  const [platForm, setPlatForm] = useState("");

  useEffect(() => {
    if (activeTab === tabNumber) {
      accountTypeService
        .getAccountTypes({ limit: 100 })
        .then((res) => {
          if (res && res.data) {
            setAccountTypes(res.data);
            // console.log('account types are ', res.data);
          }
        })
        .catch((err) => {});
      loadData();
    }
  }, [activeTab, tabNumber]);

  const loadData = async (page = 1) => {
    await tradingAccountsService
      .getAccounts(customerId, page)
      .then((res) => {
        // console.log('hmm result is ', res);
        setRec({
          data: [],
          ...res,
          loading: false,
        });
      })
      .catch((err) => {});
  };
  // console.log('credit for mt4: ', rec);
  const loadPositions = async (item) => {
    setSelectedAccount(item.id);
    setAccountId(item.id);
    setPlatForm(item.platform);
  };
  const rebate = async (item, id) => {
    // console.log("item ", item, id);
    //AuthService.masterLogin(item.login.toString(), id.toString(), item.type);
    /*await AuthService.masterLogin(item.login, id).then((res) => {
      console.log('hmm result is sdf ',res);
        }).catch(err =>{
        }); 
    /*if(item.platform === 'MT4'){
      await AuthService.masterLogin(item.login, id).then((res) => {
        console.log('hmm result is sdf ',res);
          }).catch(err =>{
          });     
    }else{
    }*/
  };
  useEffect(() => {
    if (selectedAccount) {
      const page = 1;
      loadOpenPositions(page, closeLimit);
      loadClosePositions(page, closeLimit);
    }
  }, [selectedAccount]);
  const loadOpenPositions = async (page = 1, openLimit) => {
    // console.log('Loading open positions for page:', page, 'with limit:', openLimit);

    setOpenPositions({ loading: true });

    try {
      const positions = await tradingAccountsService.getOpenPositions(
        selectedAccount,
        page,
        openLimit
      );
      // console.log('Positions response:', positions);

      if (positions.platform === "cTrader" && positions.data) {
        setPlatForm(positions.platform);
        // Ensure data is trimmed and split into lines
        const lines = positions.data.trim().split("\n");
        if (lines.length > 1) {
          // Extract headers and map data rows
          const headers = lines[0].split(",");
          const resultArray = lines.slice(1).map((line) => {
            const values = line.split(",");
            return headers.reduce((acc, header, index) => {
              acc[header] = values[index];
              return acc;
            }, {});
          });

          // console.log('Converted response for CT:', resultArray);

          setOpenPositions({
            loading: false,
            openTradesCT: resultArray,
          });
        } else {
          console.warn("No valid data rows found for CT.");
          setOpenPositions({
            loading: false,
            openTradesCT: [],
          });
        }
      } else {
        setOpenPositions({
          loading: false,
          ...positions,
        });
      }
    } catch (err) {
      console.error("Error fetching open positions:", err);
      setOpenPositions({
        loading: false,
      });
    }
  };

  const loadClosePositions = async (page = 1, closeLimit) => {
    setClosePositions({ loading: true });
    try {
      const positions = await tradingAccountsService.getClosePositions(
        selectedAccount,
        page,
        closeLimit
      );
      setClosePositions({ loading: false, ...positions });
      // console.log('Positions are closed', positions);
    } catch (err) {
      setClosePositions({ loading: false });
    }
  };

  const PageLayout = async (balance, withdrawals, login) => {
    // console.log('PAYOUT clicked');
    await tradingAccountsService
      .setPayout(balance, withdrawals, login)
      .then((res) => {
        // console.log('hmm result is ', res);
        setRec({
          data: [],
          ...res,
          loading: false,
        });
      })
      .catch((err) => {});
  };

  const pageSize = 10; // Number of items per page
  const totalPages = Math.ceil(rec.data && rec.data.length / pageSize);

  const [currentPage, setCurrentPage] = useState(1);

  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  //const displayedData = (rec.data && rec.data.length > 0 && rec.data.slice(startIndex, endIndex));
  //const displayedData = (rec.data);
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const paginationStyle = {
    padding: "30px 0px", // Add your desired padding values
  };

  const changeAccess = (item, index) => {
    accountService
      .updateAccount(
        {
          isActive: !item.isActive,
        },
        item.id
      )
      .then((res) => {
        rec.data[index].isActive = !item.isActive;
        toast.success("Account updated, successfully");
      })
      .catch((err) => {
        toast.error("Failed to update Account");
      });
  };

  return (
    <Fragment>
      <Row>
        <Col md={12} className="d-flex flex-row-reverse">
          {getMenuPerm(permissions, "ibs:viewAll") === 1 && (
            <AddAccount
              customerId={customerId}
              accountTypes={accountTypes}
              loadData={loadData}
            />
          )}
          &nbsp; &nbsp;
          {getMenuPerm(permissions, "ibs:viewAll") === 1 && (
            <LinkAccount
              customerId={customerId}
              accountTypes={accountTypes}
              loadData={loadData}
            />
          )}
        </Col>
      </Row>
      <div className="table-responsive">
        <Table hover striped>
          <thead>
            <tr>
              <th scope="col">{"#"}</th>
              <th scope="col">{"Login"}</th>
              <th scope="col">{"Type"}</th>
              <th scope="col">{"Account Type"}</th>
              {!isRestrictedRole && <th scope="col">{"Group Info"}</th>}
              <th scope="col">{"Balance"}</th>
              <th scope="col">{"Payout"}</th>
              <th scope="col">{"Total"}</th>
              <th scope="col">{"Credit"}</th>
              <th scope="col">{"Margin Free"}</th>
              <th scope="col">{"Equity"}</th>
              {!isRestrictedRole && <th scope="col">{"Stop Out"}</th>}
              <th scope="col">{"Leverage"}</th>
              {/* <td scope="col">{'Access'}</td> */}
              {/*<th scope="col">{'Payout'}</th>*/}
            </tr>
          </thead>
          {rec.loading && <TableLoader />}
          {/*!rec.loading && displayedData && displayedData.length === 0 && <TableNodata title="Accounts" />*/}
          {!rec.data && <TableNodata title="Accounts" />}
          <tbody>
            {rec.data &&
              Array.isArray(rec.data) &&
              rec.data.map((item, id) => (
                <tr key={id}>
                  {/* {console.log(item)} */}
                  <th scope="row">{item.id}</th>
                  <td>
                    <strong
                      style={{ cursor: "pointer", textDecoration: "underline" }}
                      onClick={() => {
                        loadPositions(item, id);
                      }}
                    >
                      {item.login}
                    </strong>
                  </td>
                  <td>{item.type}</td>
                  <td>{item["AccountType.title"]}</td>
                  {!isRestrictedRole && (
                    <td className={item.info}>
                      {(item.info && item.info.Group) ||
                        (item.info && item.info.groupName) ||
                        (item.state && item.state.group)}
                    </td>
                  )}
                  <td>
                    {(item.info && parseInt(item.info.balance / 100)) ||
                      (item.state && parseInt(item.state.balance)) ||
                      (item.state && parseInt(item.state.Balance)) ||
                      "0"}
                  </td>
                  <td>{parseInt(item?.transactions?.totalWithdrawals) ?? 0}</td>
                  <td>
                    {(item.info &&
                      parseInt(item.info.balance / 100) +
                        parseInt(item?.transactions?.totalWithdrawals)) ||
                      (item.state &&
                        item.state.margin &&
                        parseInt(item.state.margin.balance) +
                          parseInt(item?.transactions?.totalWithdrawals)) ||
                      "0"}
                  </td>
                  <td>
                    {(item.info &&
                      parseInt(item.info.nonWithdrawableBonus / 100)) ||
                      (item.state && item.state.credit) ||
                      (item.state && item.state.Credit)}
                  </td>
                  <td>
                    {(item.info && parseInt(item.info.freeMargin / 100)) ||
                      (item.state && parseInt(item.state.MarginFree)) ||
                      (item.state &&
                        item.state.margin &&
                        parseInt(item.state.margin.margin_free)) ||
                      "0"}
                  </td>
                  <td>
                    {(item.info && parseInt(item.info.equity / 100)) ||
                      (item.state && parseInt(item.state.Equity)) ||
                      (item.state &&
                        item.state.margin &&
                        parseInt(item.state.margin.equity)) ||
                      "0"}
                  </td>
                  {!isRestrictedRole && (
                    <td>
                      {item.state && item["AccountType.groupInfo.stop_out"]}
                    </td>
                  )}
                  <td>
                    {(item.state && item.state.MarginLeverage) ||
                      (item.info && item.info.leverageInCents / 100) ||
                      (item.state &&
                        item.state.margin &&
                        item.state.margin.leverage)}
                  </td>
                  {/* {getMenuPerm(permissions, 'ibs:viewAll') &&
                    <td>
                      <FSwitch
                        onChange={() => {
                          changeAccess(item, id);
                        }}
                        value={item.isActive}
                      />
                    </td>
                  } */}
                  {/*<td onClick={() => PageLayout(item.state && item.state.margin && parseInt(item.state.margin.balance), item?.transactions?.totalWithdrawals,item.login)}>{'PAYOUT'}</td>*/}
                </tr>
              ))}
          </tbody>
        </Table>
        <TablePagination data={rec} loadData={loadData} />
      </div>
      <OpenPositions
        positions={openPositions}
        loadData={loadOpenPositions}
        paginationData={openPositions}
        accountId={accountId}
        platform={platForm}
        limit={openLimit}
      />
      <ClosePositions
        positions={closePositions}
        paginationData={closePositions}
        loadData={loadClosePositions}
        accountId={accountId}
        platform={platForm}
        limit={closeLimit}
      />
    </Fragment>
  );
};
export default TradingAccounts;
