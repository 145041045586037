import api from './api';

export const fetchfinancialTransaction = () => {
    return api.get(`/v1/crm/financialTransaction`);
};

export const createfinancialTransaction = (data) => {
    return api.post(`/v1/crm/financialTransaction`, data);
};

export const updatefinancialTransaction= (id, data) => {
    return api.put(`/v1/crm/financialTransaction/${id}`, data);
};

export const deletefinancialTransaction = (id) => {
    return api.delete(`/v1/crm/financialTransaction/${id}`);
};
    