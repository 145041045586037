import React, { useState, useEffect } from "react";
import {
  Col,
  Card,
  CardHeader,
  CardBody,
  Input,
  Row,
  Button,
} from "reactstrap";
import DataTable from "react-data-table-component";
import { toast } from "react-toastify";
import { TextField, TablePagination } from "@mui/material";
import { FinanceReport } from "Services"; // Importing the FinanceReport API

const RebateReport = () => {
  const [data, setData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [startDate, setStartDate] = useState("1999-01-01");
  const [endDate, setEndDate] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const today = new Date().toISOString().split("T")[0];
    setEndDate(today);
  }, []);

  useEffect(() => {
    if (!endDate) return;
    fetchData();
  }, [endDate]);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await FinanceReport.RebateReport(startDate, endDate);
      if (Array.isArray(response)) {
        setData(response);
      } else {
        console.warn("Unexpected response format");
        toast.error("Error: Unexpected data format");
      }
    } catch (error) {
      console.error("Error fetching Rebate Report data:", error);
      toast.error("Error fetching Rebate Report data");
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSubmitFilter = () => {
    fetchData();
  };

  const tableColumns = [
    { name: "ID", selector: (row) => row.crmID, sortable: true },
    { name: "Email", selector: (row) => row.email, sortable: true },
    { name: "Full Name", selector: (row) => row.fullName, sortable: true },
    { name: "Type", selector: (row) => row.ibType, sortable: true },  
    {
      name: "Reg Date",
      selector: (row) => new Date(row.createdAt).toISOString().split('T')[0], // Extracting only the date
      sortable: true,
    },
    {
      name: "Total Rebate",
      selector: (row) => row.TotalRebate ? row.TotalRebate.toFixed(2) : '0.00',
      sortable: true,
    },
    {
      name: "Payout",
      selector: (row) => row.Payout ? row.Payout.toFixed(2) : '0.00',
      sortable: true,
    },
    {
      name: "Balance",
      selector: (row) => row.Balance ? row.Balance.toFixed(2) : '0.00',
      sortable: true,
    },
  ];

  const filteredData = data.filter((row) =>
    Object.values(row).some(
      (value) => value && value.toString().toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  return (
    <div>
    <style>
      {`
        .form-group.w-75 {
          display: none;
        }

        .form-inline.search-fulls.col-7 {
          border: none;
        }

        .MuiBox-root.css-19midj6 {
          padding: 3px;
        }
      `}
    </style>

    <div style={{ height: "30px" }} className="container-fluid">
      <div className="page-title" style={{ display: "none" }}>
        <div className="row">
          <div className="col-6"></div>
          <div className="col-6">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="/dashboard">Dashboard</a>
              </li>
              <li className="breadcrumb-item active">Clients</li>
            </ol>
          </div>
        </div>
      </div>
    </div>
  
    <Col sm="12">
      <Card>
        <CardHeader className="d-flex justify-content-between align-items-center">
          <h5>Rebate Report</h5>
        </CardHeader>
        <CardBody>
          <Row className="mb-3">
              <Col md={2}>
                <TextField
                  variant="outlined"
                  placeholder="Search"
                  onChange={handleSearch}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      height: "37.6px",
                      display: "flex",
                      alignItems: "center",
                    },
                  }}
                />
              </Col>
            <Col className="d-flex justify-content-end">
              <Row>
                <Col md="auto">
                  <Input type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
                </Col>
                <Col md="auto">
                  <Input type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)} />
                </Col>
                <Col md="auto">
                  <Button color="primary" onClick={handleSubmitFilter} disabled={loading}>
                    {loading ? "Loading..." : "Submit Filter"}
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
          <DataTable
              data={filteredData.slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage
              )}
              columns={tableColumns}
              striped
              persistTableHead
            />
            <TablePagination
              rowsPerPageOptions={[10, 25, 50, 100, 250, 500, 1000]}
              component="div"
              count={filteredData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              sx={{
                "& .MuiTablePagination-selectLabel": {
                  marginBottom: 0,
                },
                "& .MuiTablePagination-displayedRows": {
                  marginBottom: 0,
                },
              }}
            />
          </CardBody>
      </Card>
    </Col>
    </div>
  );
};

export default RebateReport;
