import React, { useState } from 'react';
import {
    Table,
    Button,
    Input,
    Card,
    CardBody,
    CardHeader,
    Form,
    FormGroup,
    Label,
    Alert,
} from 'reactstrap';

const Calculator = () => {
    const [joiningDate, setJoiningDate] = useState('');
    const [lastWorkingDate, setLastWorkingDate] = useState('');
    const [salary, setSalary] = useState('');
    const [leavesPaid, setLeavesPaid] = useState('');
    const [leaveData, setLeaveData] = useState(null);
    const [gratuity, setGratuity] = useState(null);
    const [tenure, setTenure] = useState('');
    const [details, setDetails] = useState([]);

    const calculateLeave = () => {
        if (!joiningDate || !lastWorkingDate || !salary) {
            alert('Please fill in all fields.');
            return;
        }

        const startDate = new Date(joiningDate);
        const endDate = new Date(lastWorkingDate);

        if (endDate <= startDate) {
            alert('Last working day must be after the joining date.');
            return;
        }

        const totalDaysService = Math.floor((endDate - startDate) / (1000 * 60 * 60 * 24)) + 1;
        console.log('Total Days of Service:', totalDaysService);

        const yearsOfService = (totalDaysService / 365).toFixed(2);
        console.log('Years of Service:', yearsOfService);

        const totalLeaveBenefit = ((totalDaysService * 30) / 365).toFixed(2);
        console.log('Total Leave Benefit:', totalLeaveBenefit);

        const leavesPaidOffset = parseFloat(leavesPaid) || 0;
        console.log('Leaves Paid (Offset):', leavesPaidOffset);

        const netLeaveDaysDue = (totalLeaveBenefit - leavesPaidOffset).toFixed(2);
        console.log('Net Leave Days Due:', netLeaveDaysDue);

        const dailySalaryRate = ((salary * 12) / 365).toFixed(2);
        console.log('Daily Salary Rate:', dailySalaryRate);

        const totalLeaveSalaryDue = (netLeaveDaysDue * dailySalaryRate).toFixed(2);
        console.log('Total Leave Salary Due:', totalLeaveSalaryDue);

        setLeaveData({
            totalDaysService,
            yearsOfService,
            totalLeaveBenefit,
            leavesPaidOffset,
            netLeaveDaysDue,
            dailySalaryRate,
            totalLeaveSalaryDue,
        });
    };

    const calculateGratuity = () => {
        if (!joiningDate || !lastWorkingDate || !salary) {
            alert('Please fill in all fields.');
            return;
        }

        const startDate = new Date(joiningDate);
        const endDate = new Date(lastWorkingDate);

        if (endDate <= startDate) {
            alert('Last working day must be after the first working day.');
            return;
        }

        const basicSalaryPerDay = salary / 30;
        console.log('Basic Salary Per Day:', basicSalaryPerDay);

        const gratuityDetails = [];
        let totalGratuity = 0;
        let currentStart = new Date(startDate);
        let totalDays = 0;

        while (currentStart < endDate) {
            const nextYear = new Date(currentStart);
            nextYear.setFullYear(currentStart.getFullYear() + 1);

            const periodEnd = nextYear < endDate ? nextYear : endDate;
            const periodDays = (periodEnd - currentStart) / (1000 * 60 * 60 * 24);
            totalDays += periodDays;

            console.log(`Period from ${currentStart.toISOString().split('T')[0]} to ${periodEnd.toISOString().split('T')[0]}, Days:`, Math.round(periodDays));

            let gratuityDays = totalDays < 365 ? 0 : totalDays < 5 * 365 ? (periodDays / 365) * 21 : ((periodDays - Math.max(totalDays - 5 * 365, 0)) / 365) * 21 + (Math.max(totalDays - 5 * 365, 0) / 365) * 30;

            const cappedGratuity = Math.min(gratuityDays, totalDays > 5 * 365 ? 30 : 21) * basicSalaryPerDay;
            const periodGratuity = Math.round(cappedGratuity);
            console.log('Gratuity for this period:', periodGratuity);

            gratuityDetails.push({
                periodFrom: currentStart.toISOString().split('T')[0],
                periodTo: periodEnd.toISOString().split('T')[0],
                days: Math.round(periodDays),
                gratuity: Math.round(periodGratuity),
            });

            totalGratuity += periodGratuity;
            currentStart = periodEnd;
        }

        console.log('Total Gratuity:', totalGratuity);

        const years = Math.floor(totalDays / 365);
        const months = Math.floor((totalDays % 365) / 30);
        const days = Math.floor((totalDays % 365) % 30);

        setTenure(`${years} years, ${months} months, and ${days} days`);
        setGratuity(totalGratuity);
        setDetails(gratuityDetails);
    };
    return (
        <Card>
            <CardHeader>
                <h3>End Of Service Calculator</h3>
            </CardHeader>
            <CardBody>
                <Form>
                    <FormGroup>
                        <Label for="joiningDate">Joining Date</Label>
                        <Input type="date" id="joiningDate" value={joiningDate} onChange={(e) => setJoiningDate(e.target.value)} />
                    </FormGroup>
                    <FormGroup>
                        <Label for="lastWorkingDate">Last Working Date</Label>
                        <Input type="date" id="lastWorkingDate" value={lastWorkingDate} onChange={(e) => setLastWorkingDate(e.target.value)} />
                    </FormGroup>
                    <FormGroup>
                        <Label for="salary">Salary (AED)</Label>
                        <Input type="number" id="salary" value={salary} onChange={(e) => setSalary(e.target.value)} />
                    </FormGroup>
                    <FormGroup>
                        <Label for="leavesPaid">Leaves Paid (Offset)</Label>
                        <Input type="number" id="leavesPaid" value={leavesPaid} onChange={(e) => setLeavesPaid(e.target.value)} />
                    </FormGroup>
                    <Button color="primary" onClick={calculateLeave}>Calculate Leave</Button>
                    <Button color="secondary" className="ml-2" onClick={calculateGratuity}>Calculate Gratuity</Button>
                </Form>

                {leaveData && (
                    <Alert color="success" className="mt-4">
                        <strong>Total Leave Salary Due:</strong> AED {leaveData.totalLeaveSalaryDue}
                    </Alert>
                )}

                {gratuity !== null && (
                    <Alert color="info" className="mt-4">
                        <strong>Total Gratuity:</strong> AED {gratuity}<br />
                        <strong>Total Tenure:</strong> {tenure}
                    </Alert>
                )}
            </CardBody>
        </Card>
    );
};

export default Calculator;
