import api from './api'; // Assuming `api` is your axios instance

export const RebateReport = async (startDate, endDate) => {
    return api.post('/v1/crm/financeReport/rebate', { 
        startDate: startDate,
        endDate: endDate
    });
};

export const NTPReport = async (startDate, endDate) => {
    return api.post('/v1/crm/financeReport/ntp', { 
        startDate: startDate,
        endDate: endDate
    });
};