import React, { Fragment } from 'react';
import { Table, Row, Col } from 'reactstrap';
import TableNodata from 'CommonElements/Table/TableNodata';
import WithoutFilterPagination from "CommonElements/Table/WithoutFilterPagination";
import Select from 'react-select';

const ClosePositions = ({ positions = {}, loadData, platform, limit, accountId }) => {
  const {
    data = [],
  } = positions;
  if (!positions || !positions.data) return ('');
  const options = [
    { value: '25', label: '25' },
    { value: '50', label: '50' },
    { value: '100', label: '100' },
  ]
  // Function to handle limit change
  const handleLimitChange = (selectedOption) => {
    loadData(1, selectedOption.value); // Reload data with the selected limit
  };
  return (
    <Fragment>

      <Row>
        <Col md={8} className="mt-5 ">
          <h5>Close Positions</h5>
        </Col>
        <Col md={4} className="mt-5 mb-3">
          <Select
            defaultValue={options.find(option => option.value === limit.toString())}
            onChange={handleLimitChange}
            options={options}
          />
        </Col>
      </Row>
      <Row>
        {platform === 'cTrader' ?
          <div className="table-responsive">
            <Table hover>
              <thead>
                <tr>
                  <th scope="col">Login</th>
                  <th scope="col">Symbol</th>
                  <th scope="col">Direction</th>
                  <th scope="col">OpenTime</th>
                  <th scope="col">CloseTime</th>
                  <th scope="col">EntryPrice</th>
                  <th scope="col">ClosePrice</th>
                  <th scope='col'>PNL</th>
                  <th scope="col">Volume</th>
                </tr>
              </thead>
              <tbody>
                {
                  data.map((item, id) => (
                    <tr key={id}>
                      <td >{item.login}</td>
                      <td>{item.symbol}</td>
                      <td className={item.direction}>{item.direction}</td>
                      <td>{item.openTimestamp}</td>
                      <td>{item.closeTimestamp}</td>
                      <td>{item.entryPrice}</td>
                      <td>{item.closePrice}</td>
                      <td>{item.pnl}</td>
                      <td>{(item.volume && item.volume / 10000)}</td>
                    </tr>
                  )
                  )
                }
              </tbody>
              {
                positions.data && positions.data.length === 0 &&
                <TableNodata title="Close Positions" />

              }
            </Table>
            <WithoutFilterPagination data={data} loadData={loadData} limit={limit} accountId={accountId} />
          </div>
          :
          <div className="table-responsive">
            <Table hover>
              <thead>
                <tr>
                  <th scope="col">Login</th>
                  <th scope="col">Symbol</th>
                  <th scope="col">Deal Id</th>
                  <th scope="col">Deal Type</th>
                  <th scope="col">Time</th>
                  <th scope="col">Entry</th>
                  <th scope="col">Lots</th>
                  <th scope="col">Price</th>
                  <th scope="col">Profit</th>

                </tr>
              </thead>
              <tbody>
                {
                  data.map((item, id) =>
                    <tr key={id}>
                      <td >{item.login || item.LOGIN}</td>
                      <td>{item.symbol || item.SYMBOL}</td>
                      <td>{item.dealId || item.TICKET}</td>
                      <td className={item.action}>{item.action === 1 ? "SELL" : "BUY"}</td>
                      <td>{((item.createdAt && (new Date(item.createdAt).toLocaleDateString())) || (item.TIMESTAMP && new Date(item.TIMESTAMP * 1000).toLocaleDateString()))}</td>
                      <td>{item.entry === 1 ? "OUT" : "IN"}</td>
                      <td>{(item.volume && item.volume / 10000) || (item.VOLUME && item.VOLUME / 100)}</td>
                      {/*<td>{item.volume && item.volume || (item.VOLUME?(item.VOLUME / 100): '')}</td>*/}
                      <td>{(item.price && item.price) || (item.OPEN_PRICE && item.OPEN_PRICE)}</td>
                      <td>{(item.profit && item.profit) || (item.PROFIT || item.PROFIT)}</td>
                    </tr>
                  )
                }

              </tbody>
              {
                positions.data && positions.data.length === 0 &&
                <TableNodata title="Close Positions" />

              }
            </Table>
            <WithoutFilterPagination data={positions} loadData={loadData} limit={limit} accountId={accountId} />
          </div>
        }
      </Row>
    </Fragment>
  );
};
export default ClosePositions;