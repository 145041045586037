import api from './api';

export const CustomField = () => {
    return api.get('/v1/crm/CustomField');
};

export const CustomFieldMarketing = () => {
  return api.get('/v1/crm/CustomField/marketing');
};


export const createCustomField = (data) => {
  return api.post('/v1/crm/CustomField', data);
};

export const deleteCustomField = (id) => {
  return api.delete(`/v1/crm/CustomField/${id}`);
};
