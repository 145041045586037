import React, { useState, useEffect } from "react";
import { Card, CardBody, Button, Modal, ModalHeader, ModalBody, ModalFooter, Input } from "reactstrap";
import { CampaignsStatus } from "Services"; // API service import
import LocationIcon from "../../../assets/images/location.svg";
import RocketIcon from "../../../assets/images/rocket.svg";
import { MarketReport } from 'Services';
import { useSelector } from "react-redux";

function Campaigns() {
    const [modal, setModal] = useState(false);
    const [campaigns, setCampaigns] = useState([]); // ✅ Ensure state is an array
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [campaignNames, setCampaignNames] = useState([]); // Stores unique campaign names
    const [platforms, setPlatforms] = useState([]); // Stores unique platform/channel names
    const [activeCampaigns, setActiveCampaigns] = useState([]);
    const [archivedCampaigns, setArchivedCampaigns] = useState([]);
    const [activeTab, setActiveTab] = useState("active"); // 'active' or 'archive'
    const [campaignStats, setCampaignStats] = useState({});
    const [selectedStatus, setSelectedStatus] = useState(""); // Default: show all
    const [updateModal, setUpdateModal] = useState(false);
    const [selectedCampaign, setSelectedCampaign] = useState(null);
    const [newStatus, setNewStatus] = useState("");
    const profile = useSelector((state) => state.login?.profile);
    const userId = profile?.id || null;
    const openUpdateModal = (campaign) => {
        setSelectedCampaign(campaign);
        setNewStatus(campaign.status); // Set the current status in the modal
        setUpdateModal(true);
    };
    const handleStatusUpdate = async () => {
        if (!selectedCampaign) return;

        try {
            await CampaignsStatus.updateCampaignsStatus(selectedCampaign.id, { status: newStatus });

            setUpdateModal(false); // Close modal
            fetchCampaigns(); // Refresh the campaign list
        } catch (error) {
            console.error("Error updating campaign status:", error);
        }
    };




    // Filter campaigns after fetching data
    useEffect(() => {
        if (campaigns.length > 0) {
            const active = campaigns.filter(campaign =>
                campaign.status === "Running" || campaign.status === "Pause"
            );

            const archived = campaigns.filter(campaign =>
                campaign.status !== "Running" && campaign.status !== "Pause"
            );

            setActiveCampaigns(active);
            setArchivedCampaigns(archived);
        }
    }, [campaigns]); // Runs whenever `campaigns` is updated



    useEffect(() => {
        const fetchMarketReport = async () => {
            try {
                const response = await MarketReport.fetchMarketReport();

                if (Array.isArray(response)) {  // ✅ API returns a direct array, not wrapped in `result`
                    // Extract only unique campaign names
                    const uniqueCampaigns = [...new Set(response.map(item => item.Campaign).filter(Boolean))];

                    // Extract unique platform/channel names
                    const uniquePlatforms = [...new Set(response.map(item => item.Channel).filter(Boolean))];

                    setCampaignNames(uniqueCampaigns);
                    setPlatforms(uniquePlatforms);
                } else {
                    console.warn("Unexpected API response format:", response);
                }
            } catch (error) {
                console.error("Error fetching market report:", error);
            }
        };

        fetchMarketReport();
    }, []);

    useEffect(() => {
        const fetchMarketReport = async () => {
            try {
                const response = await MarketReport.fetchMarketReport();

                if (Array.isArray(response)) {
                    // Extract campaigns and calculate statistics
                    const stats = response.reduce((acc, item) => {
                        const campaignName = item.Campaign;

                        if (!campaignName) return acc;

                        // Initialize if not present
                        if (!acc[campaignName]) {
                            acc[campaignName] = { totalLeads: 0, convertedLeads: 0, totalDeposits: 0 };
                        }

                        // Increase lead count
                        acc[campaignName].totalLeads++;

                        // Count converted leads
                        if (item.Converted === "True") {
                            acc[campaignName].convertedLeads++;
                        }

                        // Sum deposit amounts
                        acc[campaignName].totalDeposits += Number(item.Deposit) || 0;

                        return acc;
                    }, {});

                    setCampaignStats(stats);
                } else {
                    console.warn("Unexpected API response:", response);
                }
            } catch (error) {
                console.error("Error fetching market report:", error);
            }
        };

        fetchMarketReport();
    }, []);


    const [formData, setFormData] = useState({
        name: "",
        platform: "",
        location: "",
        status: "",
    });

    // Fetch campaigns when component mounts
    useEffect(() => {
        fetchCampaigns();
    }, []);

    const fetchCampaigns = async () => {
        setLoading(true);
        setError(null);

        try {
            const response = await CampaignsStatus.getCampaignsStatus();
            // console.log("API Response:", response); // Debugging step

            if (Array.isArray(response)) {
                setCampaigns(response); // ✅ The response itself is the array
            } else if (Array.isArray(response.data)) {
                setCampaigns(response.data); // ✅ Works if it's wrapped in `data`
            } else {
                throw new Error("Unexpected API response format");
            }
        } catch (error) {
            console.error("Error fetching campaigns:", error);
            setError("Failed to load campaigns. Please try again.");
        } finally {
            setLoading(false);
        }
    };

    const toggleModal = () => setModal(!modal);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async () => {
        try {
            // Randomly assign a color from the given options
            const colors = ["#FC6B48", "#4A5AEE", "#BD59F9", "#FFAF12", "#7ED321"];
            const randomColor = colors[Math.floor(Math.random() * colors.length)];

            const newCampaign = {
                campaigns: formData.name,
                platform: formData.platform,
                location: formData.location,
                status: formData.status,
                created_by: userId, // Replace with actual user if available
                colour: randomColor
            };

            await CampaignsStatus.createCampaignsStatus(newCampaign);
            fetchCampaigns(); // Refresh list
            toggleModal();
        } catch (error) {
            console.error("Error adding campaign:", error);
        }
    };


    return (
        <div className="p-6 bg-gray-100 min-h-screen">
            <style>{`.form-group.w-75 { display: none; } .form-inline.search-fulls.col-7 { border: none; } .MuiBox-root.css-19midj6 { padding: 3px; } .border-left { border-left-width: 4px; }`}</style>
            {/* Adding the new div before the some space content */} <div style={{ height: "4px" }} className="container-fluid"><div className="page-title" style={{ display: "none" }}><div className="row"><div className="col-6"></div><div className="col-6"><ol className="breadcrumb"><li className="breadcrumb-item"><a href="/dashboard">Dashboard</a></li><li className="breadcrumb-item active">Clients</li></ol></div></div></div></div>
            {/* Page Header */}
            <div className="flex items-center justify-between">
                <div className="flex gap-6 text-gray-700 text-lg">
                    <span
                        className={`font-semibold border-b-2 pb-1 cursor-pointer ${activeTab === "active" ? "border-black text-black" : "text-gray-400"
                            }`}
                        onClick={() => setActiveTab("active")}
                    >
                        Active ({activeCampaigns.length})
                    </span>
                    <span style={{ marginLeft: "17px" }}
                        className={`font-semibold border-b-2 pb-1 cursor-pointer ${activeTab === "archive" ? "border-black text-black" : "text-gray-400"
                            }`}
                        onClick={() => setActiveTab("archive")}
                    >
                        Archive ({archivedCampaigns.length})
                    </span>
                </div>

                <div className="flex gap-4">

                    {/* Add Button */}
                    <Button color="success" onClick={toggleModal} style={{ fontWeight: "bold" }}>
                        + Add
                    </Button>
                    <select
                        style={{ border: "1px solid #ccc", padding: "6px 12px", borderRadius: "6px", background: "#FCFDFD", color: "rgba(43, 48, 52, 0.4)", outline: "none", cursor: "pointer" }}
                        value={selectedStatus}
                        onChange={(e) => setSelectedStatus(e.target.value)}
                    >
                        <option value="">Status</option>
                        <option value="Running">Running</option>
                        <option value="Pause">Pause</option>
                        <option value="Archive">Archive</option>
                    </select>
                </div>
            </div>

            {/* Modal Form */}

            <Modal isOpen={updateModal} toggle={() => setUpdateModal(false)}>
                <ModalHeader toggle={() => setUpdateModal(false)}>Update Status</ModalHeader>
                <ModalBody>
                    <label className="block text-gray-700 mb-2">Select New Status</label>
                    <Input type="select" value={newStatus} onChange={(e) => setNewStatus(e.target.value)}>
                        <option value="Running">Running</option>
                        <option value="Pause">Pause</option>
                        <option value="Archive">Archive</option>
                    </Input>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={() => setUpdateModal(false)}>Cancel</Button>
                    <Button color="primary" onClick={handleStatusUpdate}>Update</Button>
                </ModalFooter>
            </Modal>

            <Modal isOpen={modal} toggle={toggleModal}>
                <ModalHeader toggle={toggleModal}>Add Campaign</ModalHeader>
                <ModalBody>
                    <div className="mb-3">
                        <label className="block text-gray-700">Campaign Name</label>
                        <Input type="select" name="name" value={formData.name} onChange={handleChange}>
                            <option value="">Select Campaign</option>
                            {campaignNames.map((campaign, index) => (
                                <option key={index} value={campaign}>
                                    {campaign}
                                </option>
                            ))}
                        </Input>
                    </div>



                    <div className="mb-3">
                        <label className="block text-gray-700">Platform</label>
                        <Input type="select" name="platform" value={formData.platform} onChange={handleChange}>
                            <option value="">Select Platform</option>
                            {platforms.map((platform, index) => (
                                <option key={index} value={platform}>
                                    {platform}
                                </option>
                            ))}
                        </Input>
                    </div>

                    <div className="mb-3">
                        <label className="block text-gray-700">Location</label>
                        <Input type="text" name="country" value={formData.country} onChange={handleChange} placeholder="Enter location" />
                    </div>
                    <div className="mb-3">
                        <label className="block text-gray-700">Status</label>
                        <Input type="select" name="status" value={formData.status} onChange={handleChange}>
                            <option value="">Select Status</option>
                            <option value="Running">Running</option>
                            <option value="Pause">Pause</option>
                        </Input>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={toggleModal}>
                        Cancel
                    </Button>
                    <Button color="primary" onClick={handleSubmit}>
                        Save
                    </Button>
                </ModalFooter>
            </Modal>


            {/* Campaign Cards */}
            <div className="mt-4 space-y-4">
                {activeTab === "active" ? (
                    activeCampaigns.length > 0 ? (
                        activeCampaigns
                            .filter((campaign) =>
                                selectedStatus ? campaign.status === selectedStatus : true
                            )
                            .map((campaign) => (
                                <Card key={campaign.id} className={`shadow-md rounded-lg p-4 flex flex-col border ${campaign.color} w-[1021px] h-[156px] mx-auto`} style={{ height: "px" }}>
                                    {/* Vertical Line */}
                                    <div style={{ width: "2px", height: "73px", backgroundColor: campaign.colour, position: "absolute", left: 0, top: "50%", transform: "translateY(-50%)", margin: "0" }}></div>
                                    <CardBody style={{ padding: "0px", borderRadius: "08px" }}>

                                        <div className="flex justify-between items-center">
                                            <div className="flex items-center" style={{ width: "80%" }}>
                                                <img src={RocketIcon} alt="Rocket" className="w-6 h-6 mr-3" style={{ borderRadius: "07px", backgroundColor: campaign.colour }} />
                                                <h3 className="text-lg font-semibold" style={{ fontSize: "20px", color: "#2C323F" }}>
                                                    {campaign.campaigns}
                                                </h3>
                                            </div>
                                            <span
                                                className="text-sm px-3 py-1 rounded-full border cursor-pointer"
                                                style={{
                                                    color: campaign.status === "Running" ? "#28CA0C" :
                                                        campaign.status === "Pause" ? "#FFA500" :
                                                            campaign.status === "Archive" ? "#007BFF" : "#000",
                                                    borderColor: campaign.status === "Running" ? "#28CA0C" :
                                                        campaign.status === "Pause" ? "#FFA500" :
                                                            campaign.status === "Archive" ? "#007BFF" : "#000",
                                                    borderWidth: "1px",
                                                    borderRadius: "30px"
                                                }}
                                                onClick={() => openUpdateModal(campaign)}
                                            >
                                                ● {campaign.status}
                                            </span>


                                        </div>
                                        <p className="text-sm text-gray-500" style={{ color: "#9F9F9F", fontSize: "17px", marginLeft: "51px" }}>
                                            Platform: <span className="text-blue-600" style={{ color: "#2C323F", fontSize: "20px" }}>{campaign.platform}</span>
                                        </p>

                                        <div className="flex justify-between items-center">
                                            <p className="text-sm text-gray-500 flex items-center mt-1" style={{ width: "20%", color: "#575757", fontSize: "17px", marginLeft: "51px" }}>
                                                <img src={LocationIcon} alt="Location" className="w-4 h-4 mr-1" />
                                                {campaign.location}
                                            </p>
                                            <span >
                                                <div className="mt-2 text-sm text-gray-600 flex justify-between">
                                                    <span style={{ color: "#9F9F9F", fontSize: "17px", marginLeft: "45px" }}>
                                                        Total Leads: <strong style={{ color: "#2C323F" }}> {campaignStats[campaign.campaigns]?.totalLeads || 0}</strong>
                                                    </span>
                                                    <span style={{ color: "#9F9F9F", fontSize: "17px", marginLeft: "45px" }}>
                                                        Converted: <strong style={{ color: "#2C323F" }}> {campaignStats[campaign.campaigns]?.convertedLeads || 0}</strong>
                                                    </span>
                                                    <span style={{ color: "#9F9F9F", fontSize: "17px", marginLeft: "45px" }}>
                                                        Deposited: <strong style={{ color: "#2C323F" }}>  ${campaignStats[campaign.campaigns]?.totalDeposits.toFixed(2) || "0.00"}</strong>
                                                    </span>
                                                </div>
                                            </span>
                                        </div>
                                    </CardBody>
                                </Card>
                            ))
                    ) : (
                        !loading && <p className="text-center text-gray-500">No active campaigns found.</p>
                    )
                ) : (
                    archivedCampaigns.length > 0 ? (
                        archivedCampaigns
                            .filter((campaign) =>
                                selectedStatus ? campaign.status === selectedStatus : true
                            )
                            .map((campaign) => (
                                <Card key={campaign.id} className={`shadow-md rounded-lg p-4 flex flex-col border ${campaign.color} w-[1021px] h-[156px] mx-auto`} style={{ height: "156px" }}>
                                    {/* Vertical Line */}
                                    <div style={{ width: "2px", height: "73px", backgroundColor: campaign.colour, position: "absolute", left: 0, top: "50%", transform: "translateY(-50%)", margin: "0" }}></div>
                                    <CardBody style={{ padding: "0px", borderRadius: "08px" }}>

                                        <div className="flex justify-between items-center">
                                            <div className="flex items-center" style={{ width: "80%" }}>
                                                <img src={RocketIcon} alt="Rocket" className="w-6 h-6 mr-3" style={{ borderRadius: "07px", backgroundColor: campaign.colour }} />
                                                <h3 className="text-lg font-semibold" style={{ fontSize: "20px", color: "#2C323F" }}>
                                                    {campaign.campaigns}
                                                </h3>
                                            </div>
                                            <span
                                                className="text-sm px-3 py-1 rounded-full border"
                                                style={{ color: "#28CA0C", borderColor: "#28CA0C", borderWidth: "1px !important", borderRadius: "30px" }}>
                                                ● {campaign.status}
                                            </span>
                                        </div>
                                        <p className="text-sm text-gray-500" style={{ color: "#9F9F9F", fontSize: "17px", marginLeft: "51px" }}>
                                            Platform: <span className="text-blue-600" style={{ color: "#2C323F", fontSize: "20px" }}>{campaign.platform}</span>
                                        </p>

                                        <div className="flex justify-between items-center">
                                            <p className="text-sm text-gray-500 flex items-center mt-1" style={{ width: "20%", color: "#575757", fontSize: "17px", marginLeft: "51px" }}>
                                                <img src={LocationIcon} alt="Location" className="w-4 h-4 mr-1" />
                                                {campaign.location}
                                            </p>
                                            <span >
                                                <div className="mt-2 text-sm text-gray-600 flex justify-between">
                                                    <span style={{ color: "#9F9F9F", fontSize: "17px", marginLeft: "45px" }}>
                                                        Total Leads: <strong style={{ color: "#2C323F" }}> {campaignStats[campaign.campaigns]?.totalLeads || 0}</strong>
                                                    </span>
                                                    <span style={{ color: "#9F9F9F", fontSize: "17px", marginLeft: "45px" }}>
                                                        Converted: <strong style={{ color: "#2C323F" }}> {campaignStats[campaign.campaigns]?.convertedLeads || 0}</strong>
                                                    </span>
                                                    <span style={{ color: "#9F9F9F", fontSize: "17px", marginLeft: "45px" }}>
                                                        Deposited: <strong style={{ color: "#2C323F" }}>  ${campaignStats[campaign.campaigns]?.totalDeposits.toFixed(2) || "0.00"}</strong>
                                                    </span>
                                                </div>
                                            </span>
                                        </div>
                                    </CardBody>
                                </Card>
                            ))
                    ) : (
                        !loading && <p className="text-center text-gray-500">No archived campaigns found.</p>
                    )
                )}
            </div>

        </div>
    );
}

export default Campaigns;
