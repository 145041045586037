import React, { useState, useEffect } from "react";
import {
  Col,
  Card,
  CardHeader,
  CardBody,
  Input,
  Row,
  Button,
} from "reactstrap";
import DataTable from "react-data-table-component";
import { toast } from "react-toastify";
import { TextField, TablePagination } from "@mui/material";
import { bonusReport } from "Services"; // Importing the bonusReport namespace

const BonusReportComponent = () => {
  const [data, setData] = useState([]); // State for bonus report data
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [platformFilter, setPlatformFilter] = useState(""); // State for platform filter
  const [bonusUpdateFilter, setBonusUpdateFilter] = useState(""); // State for bonus update filter
  const [startDate, setStartDate] = useState(""); // Start date for the range filter
  const [endDate, setEndDate] = useState(""); // End date for the range filter

  // Fetch the data from the API
  useEffect(() => {
    let isMounted = true; // Flag to track if the component is mounted

    bonusReport
      .BonusReport()
      .then((res) => {
        if (isMounted) {
          if (Array.isArray(res)) {
            setData(res); // Set the data directly
          } else {
            console.warn("Unexpected response format: Expected an array");
            toast.error("Error: Unexpected data format - Expected an array");
          }
        }
      })
      .catch((error) => {
        if (isMounted) {
          console.error("Error fetching bonus report data:", error);
          toast.error("Error fetching bonus report data");
        }
      });

    // Cleanup function to set isMounted to false when component unmounts
    return () => {
      isMounted = false;
    };
  }, []);

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setPage(0); // Reset to the first page when a search is performed
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handlePlatformFilterChange = (event) => {
    setPlatformFilter(event.target.value); // Set the selected platform
  };

  const handleBonusUpdateFilterChange = (event) => {
    setBonusUpdateFilter(event.target.value); // Set the selected bonus update filter
  };

  const resetFilters = () => {
    setPlatformFilter(""); // Reset the platform filter to show all platforms
    setBonusUpdateFilter(""); // Reset the bonus update filter
    setStartDate(""); // Reset the start date
    setEndDate(""); // Reset the end date
  };

  // Function to format the date
  const formatDateTime = (dateString) => {
    const options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false,
    };
    return new Intl.DateTimeFormat("en-GB", options)
      .format(new Date(dateString))
      .replace(",", "");
  };

  // Function to check if a date is within a range
  const isWithinRange = (dateString, start, end) => {
    const date = new Date(dateString);
    const startDateCheck = start ? new Date(start) : null;
    const endDateCheck = end ? new Date(end) : null;

    if (startDateCheck && date < startDateCheck) {
      return false;
    }
    if (endDateCheck && date > endDateCheck) {
      return false;
    }
    return true;
  };

  // Function to display "Bonus Update" with color based on value
  const renderBonusUpdate = (row) => {
    if (row.bonusupdate === 1) {
      return <span style={{ color: "green" }}>Done</span>;
    } else if (row.bonusupdate === 0) {
      return <span style={{ color: "orange" }}>In Process</span>;
    } else {
      return <span style={{ color: "red" }}>Error</span>;
    }
  };

  const tableColumns = [
    { name: "Sl. No", selector: (row) => row.slno, sortable: true },
    { name: "Ticket", selector: (row) => row.ticket, sortable: true },
    { name: "Login", selector: (row) => row.logini, sortable: true },
    { name: "Profit", selector: (row) => row.profit, sortable: true },
    {
      name: "Original Time",
      selector: (row) => row.ogtime,
      cell: (row) => formatDateTime(row.ogtime), // Format the date
      sortable: true,
    },
    { name: "Bonus", selector: (row) => row.bonus, sortable: true },
    {
      name: "Bonus Update",
      selector: (row) => row.bonusupdate,
      cell: (row) => renderBonusUpdate(row),
      sortable: true,
    },
    { name: "Percentage", selector: (row) => row.Pecentage, sortable: true },
    { name: "Platform", selector: (row) => row.platform, sortable: true },
    {
      name: "Updated By",
      selector: (row) => row.updatedby,
      cell: (row) => formatDateTime(row.updatedby), // Format the updated by date
      sortable: true,
    },
    { name: "Command", selector: (row) => row.command, sortable: true },
  ];

  // Filter data based on search term, platform filter, bonus update filter, and date range
  const filteredData = data.filter((row) => {
    const searchMatches = Object.values(row).some(
      (value) =>
        value &&
        value.toString().toLowerCase().includes(searchTerm.toLowerCase())
    );
    const platformMatches = platformFilter
      ? row.platform === platformFilter
      : true;
    const bonusUpdateMatches = bonusUpdateFilter
      ? row.bonusupdate === parseInt(bonusUpdateFilter, 10)
      : true;
    const dateMatches = isWithinRange(row.updatedby, startDate, endDate);

    return (
      searchMatches && platformMatches && bonusUpdateMatches && dateMatches
    );
  });

  return (
    <div>
      <style>
        {`
          .form-group.w-75 {
            display: none;
        
          }
            .form-inline.search-fulls.col-7 {
              border: none;
            }

          .MuiBox-root.css-19midj6 {
                  padding: 3px;
            }
        `}
      </style>
      {/* Added the div block for breadcrumb */}
      <div style={{ height: "30px" }} className="container-fluid">
        <div className="page-title" style={{ display: "none" }}>
          <div className="row">
            <div className="col-6"></div>
            <div className="col-6">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="/dashboard">Dashboard</a>
                </li>
                <li className="breadcrumb-item active">Clients</li>
              </ol>
            </div>
          </div>
        </div>
      </div>

      <Col sm="12">
        <Card>
          <CardHeader className="d-flex justify-content-between align-items-center">
            <h5>Bonus Report</h5>
          </CardHeader>
          <CardBody>
            {/* Filter Section */}
            <Row className="mb-3">
              <Col md={2}>
                <TextField
                  variant="outlined"
                  placeholder="Search"
                  onChange={handleSearch}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      height: "37.6px",
                      display: "flex",
                      alignItems: "center",
                    },
                  }}
                />
              </Col>
              <Col md={2}>
                <Input
                  type="select"
                  value={platformFilter}
                  onChange={handlePlatformFilterChange}
                >
                  <option value="">All Platforms</option>
                  <option value="MT4">MT4</option>
                  <option value="MT5">MT5</option>
                </Input>
              </Col>
              <Col md={2}>
                <Input
                  type="select"
                  value={bonusUpdateFilter}
                  onChange={handleBonusUpdateFilterChange}
                >
                  <option value="">All Statuses</option>
                  <option value="1">Done</option>
                  <option value="0">In Process</option>
                </Input>
              </Col>
              <Col className="d-flex justify-content-end">
                <Row>
                  <Col md="auto">
                    <Input
                      type="date"
                      value={startDate}
                      onChange={(e) => setStartDate(e.target.value)}
                      placeholder="From"
                    />
                  </Col>
                  <Col md="auto">
                    <Input
                      type="date"
                      value={endDate}
                      onChange={(e) => setEndDate(e.target.value)}
                      placeholder="To"
                    />
                  </Col>
                  <Col md="auto">
                    <Button color="secondary" onClick={resetFilters}>
                      Reset Filters
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>

            <DataTable
              data={filteredData.slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage
              )}
              columns={tableColumns}
              striped
              selectableRows
              persistTableHead
            />
            <TablePagination
              rowsPerPageOptions={[10, 25, 50, 100, 250, 500, 1000]}
              component="div"
              count={filteredData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              sx={{
                "& .MuiTablePagination-selectLabel": {
                  marginBottom: 0,
                },
                "& .MuiTablePagination-displayedRows": {
                  marginBottom: 0,
                },
              }}
            />
          </CardBody>
        </Card>
      </Col>
    </div>
  );
};

export default BonusReportComponent;
