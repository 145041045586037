// AddUserModal.js
import React, { useState, useEffect } from 'react';
import { Col, Row, Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Input } from 'reactstrap';
import { createUsersPersonalDetail, UsersPersonalDetail } from 'Services/usersPersonalDetail.service';
import { getUserOptions } from 'Services/user.service';
import { toast } from 'react-toastify';

const AddUserModal = ({ isOpen, toggle }) => {
  const [userOptions, setUserOptions] = useState([]); // Store user options
  const [formValues, setFormValues] = useState({
    userid: '',
    status: '',
    work_location: '',
    department: '',
    section: '',
    name: '',
    employment_number: '',
    job_title: '',
    business_analyst_details: '',
    work_type: '',
    direct_manager: '',
    direct_managerId: '',
    branch: '',
    hiring_date: '',
    end_of_probation: '',
    gender: '',
    birth_date: '',
    marital_status: '',
    nationality: '',
    national_id: '',
    email: '',
    mobile_number: '',
    phone_no_ext: '',
    img: null,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    let updatedValues = { ...formValues, [name]: value };

    if (name === 'hiring_date' && value) {
      // Calculate end_of_probation date as 6 months after hiring_date
      const hiringDate = new Date(value);
      hiringDate.setMonth(hiringDate.getMonth() + 6);

      // Format the date to YYYY-MM-DD
      const endOfProbation = hiringDate.toISOString().split('T')[0];

      updatedValues = { ...updatedValues, end_of_probation: endOfProbation };
    }

    setFormValues(updatedValues);
  };

  const handleManagerChange = (e) => {
    const selectedManager = userOptions.find(option => option.value === e.target.value);
    setFormValues({
      ...formValues,
      direct_manager: selectedManager ? selectedManager.label : '',
      direct_managerId: selectedManager ? selectedManager.value : '',
    });
  };


  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    setFormValues({
      ...formValues,
      img: file,
    });
  };

  const handleSubmit = () => {

    createUsersPersonalDetail(formValues)
      .then(response => {
        toast.success('User added successfully!');
        toggle();
      })
      .catch(error => {
        if (error.response) {
          console.error('Server responded with an error:', error.response.data);
          toast.error(`Failed to add user: ${error.response.data.message}`);
        } else if (error.request) {
          console.error('No response received:', error.request);
          toast.error('Failed to add user: No response received from server');
        } else {
          console.error('Error setting up request:', error.message);
          toast.error(`Failed to add user: ${error.message}`);
        }
      });
  };
  const loadUserOptions = async () => {
    try {
      const response = await getUserOptions();
      console.log('API Response:', response);

      if (!response || !response.data || !Array.isArray(response.data.data)) {
        console.error('No valid data in response:', response);
        return;
      }

      const userDataArray = response.data.data;
      console.log('User Data Array:', userDataArray);

      const formattedOptions = userDataArray.map(user => {
        if (user.id && user.firstName && user.lastName) {
          return {
            value: user.id.toString(), // Ensure value is a string for dropdown
            label: `${user.firstName} ${user.lastName}`
          };
        } else {
          console.error('User object is missing properties:', user);
          return null;
        }
      }).filter(option => option !== null);

      console.log('Formatted Options:', formattedOptions);
      setUserOptions(formattedOptions);
    } catch (error) {
      console.error('Failed to fetch employees:', error);
    }
  };

  useEffect(() => {
    loadUserOptions(); // Fetch users on component load
  }, []);

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Add New User</ModalHeader>
      <ModalBody>
        <Form>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label for="userid">User ID</Label>
                <Input
                  type="text"
                  name="userid"
                  id="userid"
                  value={formValues.userid}
                  onChange={handleInputChange}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label for="name">Name</Label>
                <Input
                  type="text"
                  name="name"
                  id="name"
                  value={formValues.name}
                  onChange={handleInputChange}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label for="email">Email</Label>
                <Input
                  type="email"
                  name="email"
                  id="email"
                  value={formValues.email}
                  onChange={handleInputChange}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label for="mobile_number">Mobile Number</Label>
                <Input
                  type="text"
                  name="mobile_number"
                  id="mobile_number"
                  value={formValues.mobile_number}
                  onChange={handleInputChange}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>

            <Col md={6}>
              <FormGroup>
                <Label for="work_type">Work Type</Label>
                <Input
                  type="select"
                  name="work_type"
                  id="work_type"
                  value={formValues.work_type}
                  onChange={handleInputChange}
                >
                  <option value="">Select Work Type</option>
                  <option value="FULL_TIME">Full-Time</option>
                  <option value="PART_TIME">Part-Time</option>
                </Input>
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label for="department">Department</Label>
                <Input
                  type="select"
                  name="department"
                  id="department"
                  value={formValues.department}
                  onChange={handleInputChange}
                >
                  <option value="">Select Department</option>
                  <option value="SALES">SALES</option>
                  <option value="MARKETING">MARKETING</option>
                  <option value="IT">IT</option>
                  <option value="DEALING">DEALING</option>
                  <option value="COMPLIANCE">COMPLIANCE</option>
                  <option value="FINANCE">FINANCE</option>
                  <option value="MANAGEMENT">MANAGEMENT</option>
                  <option value="HR">HR</option>
                  <option value="SUPPORT">SUPPORT</option>

                </Input>
              </FormGroup>
            </Col>

          </Row>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label for="work_location">Work Location</Label>
                <Input
                  type="text"
                  name="work_location"
                  id="work_location"
                  value={formValues.work_location}
                  onChange={handleInputChange}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label for="section">Biometric ID</Label>
                <Input
                  type="text"
                  name="section"
                  id="section"
                  value={formValues.section}
                  onChange={handleInputChange}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label for="employment_number">Employment Number</Label>
                <Input
                  type="text"
                  name="employment_number"
                  id="employment_number"
                  value={formValues.employment_number}
                  onChange={handleInputChange}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label for="job_title">Job Title</Label>
                <Input
                  type="select"
                  name="job_title"
                  id="job_title"
                  value={formValues.job_title}
                  onChange={(e) => {
                    const value = e.target.value;
                    setFormValues((prev) => ({
                      ...prev,
                      job_title: value,
                      custom_job_title: value === "Custom" ? prev.custom_job_title || "" : "",
                    }));
                  }}
                >
                  <option value="">Select Job Title or Type Your Own</option>
                  <option value="Software Engineer">Software Engineer</option>
                  <option value="Business Analyst">Business Analyst</option>
                  <option value="Relationship Manager">Relationship Manager</option>
                  <option value="Senior Sales Executive">Senior Sales Executive</option>
                  <option value="Country Manager">Country Manager</option>
                  <option value="Marketing Specialist">Marketing Specialist</option>
                  <option value="Forex Dealer">Forex Dealer</option>
                  <option value="Sales Executive">Sales Executive</option>
                  <option value="Custom">Other (Type below)</option>
                </Input>

                {/* Allow manual input if "Other" is selected */}
                {formValues.job_title === "Custom" && (
                  <Input
                    type="text"
                    name="custom_job_title"
                    id="custom_job_title"
                    placeholder="Enter your job title"
                    value={formValues.custom_job_title}
                    onChange={(e) =>
                      setFormValues((prev) => ({
                        ...prev,
                        custom_job_title: e.target.value,
                      }))
                    }
                  />
                )}
              </FormGroup>
            </Col>

          </Row>
          {/* <Row> */}
          {/* <Col md={6}>
              <FormGroup>
                <Label for="business_analyst_details">Business Analyst Details</Label>
                <Input
                  type="text"
                  name="business_analyst_details"
                  id="business_analyst_details"
                  value={formValues.business_analyst_details}
                  onChange={handleInputChange}
                />
              </FormGroup>
            </Col> */}
          {/* <Col md={6}>
              <FormGroup>
                <Label for="position">Position</Label>
                <Input
                  type="text"
                  name="position"
                  id="position"
                  value={formValues.position}
                  onChange={handleInputChange}
                />
              </FormGroup>
            </Col> */}

          {/* </Row> */}
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label for="direct_manager">Direct Manager</Label>
                <Input
                  type="select"
                  name="direct_manager"
                  id="direct_manager"
                  value={formValues.direct_managerId} // Bind to the ID value
                  onChange={handleManagerChange}
                >
                  <option value="">Select a Manager</option>
                  {userOptions.map(manager => (
                    <option key={manager.value} value={manager.value}>
                      {manager.label}
                    </option>
                  ))}
                </Input>
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label for="branch">Branch</Label>
                <Input
                  type="select"
                  name="branch"
                  id="branch"
                  value={formValues.branch}
                  onChange={handleInputChange}
                >
                  <option value="">Select Branch</option>
                  <option value="GoDo LTD. Dubai Branch">GODO LTD. Dubai Branch</option>
                  <option value="GODO Introduction Financial Services LLC">GODO Introduction Financial Services LLC</option>
                </Input>
              </FormGroup>
            </Col>

          </Row>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label for="hiring_date">Hiring Date</Label>
                <Input
                  type="date"
                  name="hiring_date"
                  id="hiring_date"
                  value={formValues.hiring_date}
                  onChange={handleInputChange}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label for="end_of_probation">End of Probation</Label>
                <Input
                  type="date"
                  name="end_of_probation"
                  id="end_of_probation"
                  value={formValues.end_of_probation}
                  onChange={handleInputChange}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label for="gender">Gender</Label>
                <Input
                  type="select"
                  name="gender"
                  id="gender"
                  value={formValues.gender}
                  onChange={handleInputChange}
                >

                  <option value="">Select Gender</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                </Input>
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label for="birth_date">Birth Date</Label>
                <Input
                  type="date"
                  name="birth_date"
                  id="birth_date"
                  value={formValues.birth_date}
                  onChange={handleInputChange}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label for="marital_status">Marital Status</Label>
                <Input
                  type="select"
                  name="marital_status"
                  id="marital_status"
                  value={formValues.marital_status}
                  onChange={handleInputChange}
                >
                  <option value="">Select Marital Status</option>
                  <option value="Married">Married</option>
                  <option value="Single">Single</option>
                </Input>
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label for="nationality">Nationality</Label>
                <Input
                  type="text"
                  name="nationality"
                  id="nationality"
                  value={formValues.nationality}
                  onChange={handleInputChange}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label for="national_id">National ID</Label>
                <Input
                  type="text"
                  name="national_id"
                  id="national_id"
                  value={formValues.national_id}
                  onChange={handleInputChange}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label for="phone_no_ext">Phone Extension</Label>
                <Input
                  type="text"
                  name="phone_no_ext"
                  id="phone_no_ext"
                  value={formValues.phone_no_ext}
                  onChange={handleInputChange}
                />
              </FormGroup>
            </Col>
          </Row>
          {/* <Row>
            <Col md={6}>
              <FormGroup>
                <Label for="img">Profile Image</Label>
                <Input
                  type="file"
                  name="img"
                  id="img"
                  onChange={handleFileUpload}
                />
              </FormGroup>
            </Col>
          </Row>  */}
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={handleSubmit}>Add User</Button>
        <Button color="secondary" onClick={toggle}>Cancel</Button>
      </ModalFooter>
    </Modal>
  );
};

export default AddUserModal;
