import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Card, CardBody, Row, Col, Input, Button, Container, Table, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { CustomFieldupdate } from "Services"; // Updated import

const CustomField = () => {
    const fieldOptions = ["Lead Source", "UTM Source", "UTM Category"];
    const [selectedField, setSelectedField] = useState("");
    const [selectedOption, setSelectedOption] = useState("");
    const [entries, setEntries] = useState([]);
    const [activeTab, setActiveTab] = useState(fieldOptions[0]);
    const [modal, setModal] = useState(false);
    const [deleteId, setDeleteId] = useState(null);
    
    const userProfile = useSelector(
        (state) => (state.login && state.login.profile) || {}
    );


    // Fetch data from API
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await CustomFieldupdate.CustomFieldMarketing();
   

                if (response && Array.isArray(response)) {
                    setEntries(response);
                } else if (response && response.data && Array.isArray(response.data)) {
                    setEntries(response.data);
                } else {
                    console.error("Unexpected API response format:", response);
                }
            } catch (error) {
                console.error("Error fetching market report data:", error);
            }
        };
        fetchData();
    }, []);

    // Add new entry
    const addEntry = async () => {
        if (selectedField && selectedOption) {
            const newEntry = {
                slno: entries.length + 1,
                options: selectedOption,
                field: selectedField,
                teamby: "Marketing",
                created_by: userProfile.id,
                created_at: new Date().toISOString(),
            };

            try {
                await CustomFieldupdate.createCustomField(newEntry);
                setEntries([...entries, newEntry]);
                setSelectedOption("");
                toast.success("Entry added successfully");
            } catch (error) {
                console.error("Error creating entry:", error);
                toast.error("Failed to add entry");
            }
        }
    };

    // Toggle modal
    const toggleModal = (id) => {
        setDeleteId(id);
        setModal(!modal);
    };

    // Confirm delete entry
    const confirmDeleteEntry = async () => {
        if (deleteId !== null) {
            try {
                await CustomFieldupdate.deleteCustomField(deleteId);
                setEntries(entries.filter((entry) => entry.slno !== deleteId));
                toast.success("Entry deleted successfully");
            } catch (error) {
                console.error("Error deleting entry:", error);
                toast.error("Failed to delete entry");
            }
            setModal(false);
            setDeleteId(null);
        }
    };

    // Filter entries
    const filteredEntries = entries.filter(entry => 
        entry.field?.trim().toLowerCase() === activeTab.trim().toLowerCase()
    );

    return (
        <Container className="py-4">
            {/* Input Form */}
            <Card className="mb-4" style={{ width: "50%" }}>
                <CardBody className="p-4">
                    <Input type="select" onChange={(e) => setSelectedField(e.target.value)}>
                        <option value="">Select Field</option>
                        {fieldOptions.map((field) => (
                            <option key={field} value={field}>{field}</option>
                        ))}
                    </Input>

                    <Input
                        type="text"
                        placeholder="Enter Option"
                        value={selectedOption}
                        onChange={(e) => setSelectedOption(e.target.value)}
                        className="mt-3"
                    />

                    <Button onClick={addEntry} color="primary" className="mt-3">Add</Button>
                </CardBody>
            </Card>

            {/* Tabbed Data View */}
            <Card className="mb-4">
                <CardBody className="p-4">
                    <Row className="mb-3">
                        {fieldOptions.map((field) => (
                            <Col key={field} md={4}>
                                <Button
                                    color={activeTab === field ? "primary" : "secondary"}
                                    block
                                    onClick={() => setActiveTab(field)}
                                >
                                    {field}
                                </Button>
                            </Col>
                        ))}
                    </Row>

                    <div className="mt-3">
                        <h5>{activeTab}</h5>
                        <Table striped>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Options</th>
                                    <th>Field</th>
                                    <th>Created By</th>
                                    <th>Created At</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredEntries.length > 0 ? (
                                    filteredEntries.map((entry) => (
                                        <tr key={entry.slno}>
                                            <td>{entry.slno}</td>
                                            <td>{entry.options}</td>
                                            <td>{entry.field}</td>
                                            <td>{entry.created_by}</td>
                                            <td>{new Date(entry.created_at).toLocaleString()}</td>
                                            <td>
                                                <Button color="danger" onClick={() => toggleModal(entry.slno)}>Delete</Button>
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="6" className="text-center">No data available</td>
                                    </tr>
                                )}
                            </tbody>
                        </Table>
                    </div>
                </CardBody>
            </Card>

            {/* Delete Confirmation Modal */}
            <Modal isOpen={modal} toggle={() => setModal(!modal)}>
                <ModalHeader toggle={() => setModal(!modal)}>Confirm Deletion</ModalHeader>
                <ModalBody>
                    Are you sure you want to delete this entry?
                </ModalBody>
                <ModalFooter>
                    <Button color="danger" onClick={confirmDeleteEntry}>Delete</Button>{" "}
                    <Button color="secondary" onClick={() => setModal(false)}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </Container>
    );
};

export default CustomField;
