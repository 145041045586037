import api from './api';

export const Cashback = () => {
    return api.get('/v1/crm/Cashback/');
};

export const createCashback = (data) => {
  return api.post('/v1/crm/Cashback/', data);
};

export const deleteCashback = (id) => {
  return api.delete(`/v1/crm/Cashback/${id}`);
};

export const updateCashback = (id, data) => {
  return api.put(`/v1/crm/Cashback/${id}`, data);
};

