import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import { SalesReportService } from "Services";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { Bar } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from "chart.js";
import { Grid } from '@mui/material';
ChartJS.register(ArcElement, Tooltip, Legend, BarElement, CategoryScale, LinearScale);

const NTPIB = ({ searchTerm, startDate, endDate, showCharts }) => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [groupMembers, setGroupMembers] = useState([]);

  const profile = useSelector((state) => state.login?.profile);
  const roleId = profile?.roleId || null;
  const userId = profile?.id || null;

  useEffect(() => {
    if (userId) {
      const fetchGroupMembers = async () => {
        try {
          const response = await SalesReportService.getGroupMembersById(userId);
          setGroupMembers(response);
        } catch (err) {
          console.error("Failed to fetch group members:", err);
          toast.error("Failed to fetch group members.");
          setGroupMembers([]);
        }
      };
      fetchGroupMembers();
    }
  }, [userId]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError("");
      try {
        const response = await SalesReportService.getNTPIB();
        let filteredResponse = response;
        if (roleId !== 33 && roleId !== 18 && roleId !== 41&&roleId !== 35&&roleId !== 35&&roleId !== 39) {
          if (groupMembers.length > 0) {
            filteredResponse = response.filter((item) => groupMembers.includes(item.agentId));
          } else {
            filteredResponse = response.filter((item) => item.agentId === userId);
          }
        }
        setData(filteredResponse);
        setFilteredData(filteredResponse);
      } catch (err) {
        setError("Failed to fetch data.");
        toast.error("Failed to fetch data.");
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [roleId, userId, groupMembers]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-GB");
  };

  const columns = [
    { name: "Created Date", selector: (row) => formatDate(row.CreatedDate), sortable: true },
    { name: "Partner ID", selector: (row) => row.IBID, sortable: true },
    { name: "Partner Name", selector: (row) => row.IBName, sortable: true },
    { name: "Sales", selector: (row) => row.Sales, sortable: true },
    { name: "Partner Type", selector: (row) => row.IBType, sortable: true },
  ];

  useEffect(() => {
    let filtered = data;
    if (searchTerm) {
      const lowercasedTerm = searchTerm.toLowerCase();
      filtered = filtered.filter((item) =>
        Object.values(item).some(
          (value) => value && value.toString().toLowerCase().includes(lowercasedTerm)
        )
      );
    }
    if (startDate) {
      filtered = filtered.filter((item) => new Date(item.CreatedDate) >= new Date(startDate));
    }
    if (endDate) {
      filtered = filtered.filter((item) => new Date(item.CreatedDate) <= new Date(endDate));
    }
    setFilteredData(filtered);
  }, [searchTerm, startDate, endDate, data]);

  const getBarChartData = () => {
    const ibData = filteredData.reduce((acc, item) => {
      acc[item.IBName] = (acc[item.IBName] || 0) + 1;
      return acc;
    }, {});
    const labels = Object.keys(ibData);
    return {
      labels,
      datasets: [{
        label: "IB Count",
        data: labels.map((ib) => ibData[ib]),
        backgroundColor: "#086CA2"
      }],
    };
  };

  return (
    <div style={{ position: "relative" }}>
      <style>
        {`
          .chartbutton {
           display: none;
          }
        `}
      </style>
      {error && <p className="text-danger">{error}</p>}
      {loading ? (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100px", color: "#007bff" }}>
          <div className="spinner-border text-primary" role="status"><span className="sr-only">Loading...</span></div>
        </div>
      ) : filteredData.length > 0 ? (
        <Grid container spacing={1}>
          {showCharts && (
            <Grid item xs={12} md={9}>
              <Bar data={getBarChartData()} options={{ responsive: true, scales: { x: { stacked: true }, y: { stacked: true } } }} />
            </Grid>
          )}
          <Grid item xs={12} md={12}>
            <DataTable columns={columns} data={filteredData} pagination striped persistTableHead />
          </Grid>
        </Grid>
      ) : (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100px", color: "#007bff" }}>
          No data available.
        </div>
      )}
    </div>
  );
};

export default NTPIB;
