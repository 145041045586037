import React, { useEffect, useRef, useState } from "react";
import { Card, CardBody, Row, Col, Media, Input, Button } from "reactstrap";
import Accounts from "../../../assets/images/avtar/dashboardIcon.svg";
import { Doughnut, Line } from "react-chartjs-2";
import { Chart, ArcElement, Tooltip, Legend } from "chart.js";
import {
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
} from "chart.js";
import BluewalletIcon from "../../../assets/images/blueWalletMarketing.svg";
import PinkwalletIcon from "../../../assets/images/pinkWalletMarketing.svg";
import Image1 from "../../../assets/images/godounknowpic.svg";
import Image2 from "../../../assets/images/test-profile/Image1.png";
import Image3 from "../../../assets/images/test-profile/Image2.png";
import Image4 from "../../../assets/images/test-profile/Image3.png";
import { MarketReport } from "Services";
import { getDocumentSas } from "Services/usersPersonalDetail.service"; // API to fetch images
import { useNavigate } from "react-router-dom";

// Register Chart.js components
Chart.register(
  LineElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  ArcElement
);

const Dashboard = () => {
  const [groupedSalesData, setGroupedSalesData] = useState({});
  const [agentImages, setAgentImages] = useState({});
  const [reportData, setReportData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [lineChartData, setLineChartData] = useState({ datasets: [] });
  const navigate = useNavigate();
  const [dateRange, setDateRange] = useState({ start: "", end: "" });
  const [selectedSource, setSelectedSource] = useState(""); // Fix: Add this
  const [selectedChannel, setSelectedChannel] = useState(""); // Fix: Add this
  const [selectedCampaign, setSelectedCampaign] = useState("");
  const convertedLeads = filteredData.filter(
    (item) => item.Converted === "True"
  );
  const organicInConvertedCount = convertedLeads.filter(
    (item) => item.utmCategory === "Organic"
  ).length;
  const paidInConvertedCount = convertedLeads.filter(
    (item) =>
      item.utmCategory === "Paid" ||
      (item.utmCategory === "Organic / Paid" && item.Converted === "True")
  ).length;
  const RegisteredCount = filteredData.filter(
    (item) => item.Converted === "True"
  ).length;

  const totalDepositConverted = convertedLeads.reduce(
    (sum, item) => sum + (item.Deposit || 0),
    0
  );
  // Define colors for statuses
  const colors = ["#DE0D92", "#f28b82", "#fbbc04", "#34a853", "#4285f4", "#a142f4", "#ff6d00"];

  // Get unique Channels but exclude empty or undefined values
  const uniqueChannels = [
    ...new Set(
      reportData
        .map((item) => item.Channel)
        .filter((channel) => channel && channel.trim() !== "")
    ),
  ];
  const uniqueSource = [
    ...new Set(
      reportData
        .map((item) => item.Source)
        .filter((Source) => Source && Source.trim() !== "")
    ),
  ];
  const [selectedTables, setSelectedTables] = useState([]);
  const uniqueCampaigns = [...new Set(reportData.map((item) => item.Campaign))];
  const [cardWidth, setCardWidth] = useState("429px");
  const [cardMaxHeight, setCardMaxHeight] = useState("auto");
  const [cardMarginLeft, setCardMarginLeft] = useState("47px");
  const chartRef = useRef(null);
  const [chartData, setChartData] = useState({ datasets: [] }); // Ensure data is initialized
  const [gradient, setGradient] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [sortConfig, setSortConfig] = useState({
    key: "Name",
    direction: "asc",
  });

  const handleChartClick = (event, elements) => {
    if (!elements.length) return;

    const index = elements[0].index;
    const label = chartData.labels[index]; // "Converted" or "Other Leads"

    let filteredLeads = [];

    const handleStatusClick = (status) => {
      // Close the chart table when opening a status table
      setSelectedTables([]);

      setSelectedStatus(status);
    };

    if (label === "Converted") {
      filteredLeads = filteredData.filter((item) => item.Converted === "True");
    } else {
      filteredLeads = filteredData.filter((item) => item.Converted === "False");
    }

    // Close any open status table and open the chart table
    setSelectedStatus(null);
    setSelectedTables([
      {
        id: Date.now(),
        title: label,
        data: filteredLeads,
        sortConfig: { key: "Name", direction: "asc" },
        currentPage: 1,
        itemsPerPage: 10,
      },
    ]);
  };

  const sortData = (data, sortConfig) => {
    if (!sortConfig.key) return data;
    return [...data].sort((a, b) => {
      const aValue = a[sortConfig.key] || "";
      const bValue = b[sortConfig.key] || "";
      if (aValue < bValue) return sortConfig.direction === "asc" ? -1 : 1;
      if (aValue > bValue) return sortConfig.direction === "asc" ? 1 : -1;
      return 0;
    });
  };

  const getPaginatedData = (data, currentPage, itemsPerPage) => {
    if (itemsPerPage === "All") return data;
    const startIndex = (currentPage - 1) * itemsPerPage;
    return data.slice(startIndex, startIndex + itemsPerPage);
  };

  // Sorting function
  const sortedData = (data) => {
    if (!sortConfig.key) return data;
    return [...data].sort((a, b) => {
      const aValue = a[sortConfig.key] || "";
      const bValue = b[sortConfig.key] || "";
      if (aValue < bValue) return sortConfig.direction === "asc" ? -1 : 1;
      if (aValue > bValue) return sortConfig.direction === "asc" ? 1 : -1;
      return 0;
    });
  };

  // Filter data based on selected status
  const filteredAndSortedData = sortedData(
    reportData.filter((item) => item.Status === selectedStatus)
  );

  // Pagination logic
  const totalPages =
    itemsPerPage === "All"
      ? 1
      : Math.ceil(filteredAndSortedData.length / itemsPerPage);
  const paginatedData =
    itemsPerPage === "All"
      ? filteredAndSortedData
      : filteredAndSortedData.slice(
          (currentPage - 1) * itemsPerPage,
          currentPage * itemsPerPage
        );

  // Calculate deposits separately for Paid and Organic leads inside Converted
  const paidDepositConverted = convertedLeads
    .filter((item) => item.utmCategory === "Paid")
    .reduce((sum, item) => sum + (item.Deposit || 0), 0);

  const organicDepositConverted = convertedLeads
    .filter(
      (item) =>
        item.utmCategory === "Organic" || item.utmCategory === "Organic / Paid"
    )
    .reduce((sum, item) => sum + (item.Deposit || 0), 0);

  // Get unique statuses with their counts
  const statusCounts = filteredData.reduce((acc, item) => {
    let status = item.Status;

    // Check for empty, null, or undefined values and replace them with "Undefined"
    if (
      !status ||
      status.trim() === "" ||
      status === null ||
      status === undefined
    ) {
      status = "Undefined";
    }

    acc[status] = (acc[status] || 0) + 1;
    return acc;
  }, {});

  // Convert object into an array of { status, count }
  const statusArray = Object.entries(statusCounts).map(([status, count]) => ({
    status,
    count,
  }));


    // Fetch and Group Data by SalesId
    useEffect(() => {
      const fetchAndGroupSalesData = async () => {
        try {
          const response = await MarketReport.fetchMarketReport();
  
          let salesData;
          if (Array.isArray(response)) {
            salesData = response; // API returned an array directly
          } else if (response.status && Array.isArray(response.result)) {
            salesData = response.result; // API returned expected {status, result}
          } else {
            console.warn("Unexpected API response format:", response);
            return;
          }
  
          const salesGroup = {};
  
          for (const lead of salesData) {
            const { SalesId, SalesManager, SalesJobTitle, pictureId } = lead;
  
            if (!salesGroup[SalesId]) {
              salesGroup[SalesId] = {
                SalesManager,
                SalesJobTitle,
                pictureId,
                totalLeads: 0,
              };
            }
  
            salesGroup[SalesId].totalLeads += 1;
          }
  
          setGroupedSalesData(salesGroup);
        } catch (error) {
          console.error("Error fetching market report:", error);
        } finally {
          setLoading(false);
        }
      };
  
      fetchAndGroupSalesData();
    }, []);
  
    // Fetch Profile Images for Each pictureId
    useEffect(() => {
      const fetchAgentImages = async () => {
        const imageUrls = {};
  
        await Promise.all(
          Object.values(groupedSalesData).map(async (agent) => {
            const url = await getUrl(agent.pictureId); // Use getUrl() to fetch image
            imageUrls[agent.pictureId] = url;
          })
        );
  
        setAgentImages(imageUrls);
      };
  
      if (Object.keys(groupedSalesData).length > 0) {
        fetchAgentImages();
      }
    }, [groupedSalesData]);

  // Fetch data
  useEffect(() => {
    const fetchMarketReport = async () => {
      try {
        const response = await MarketReport.fetchMarketReport();
        if (Array.isArray(response)) {
          setReportData(response);
          setFilteredData(response); // Initial load
        } else {
          console.warn("Unexpected API response:", response);
        }
      } catch (error) {
        console.error("Error fetching market report:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchMarketReport();
  }, []);

  useEffect(() => {
    let data = [...reportData];

    // Convert date range inputs to full day format
    if (dateRange.start && dateRange.end) {
      const startDate = new Date(dateRange.start);
      startDate.setHours(0, 0, 0, 0); // Set to 00:00:00

      const endDate = new Date(dateRange.end);
      endDate.setHours(23, 59, 59, 999); // Set to 23:59:59

      data = data.filter((item) => {
        const createdDate = new Date(item.Created);
        return createdDate >= startDate && createdDate <= endDate;
      });
    }

    // Filter by Channel (Platform)
    if (selectedChannel) {
      data = data.filter((item) => item.Channel === selectedChannel);
    }
    if (selectedSource) {
      data = data.filter((item) => item.Source === selectedSource);
    }

    // Filter by Campaign
    if (selectedCampaign) {
      data = data.filter((item) => item.Campaign === selectedCampaign);
    }
    

    setFilteredData(data);
  }, [
    dateRange,
    selectedChannel,
    selectedSource,
    selectedCampaign,
    reportData,
  ]);

  useEffect(() => {
    const updateSize = () => {
      if (window.innerWidth <= 1280) {
        setCardWidth("364px");
        setCardMaxHeight("auto");
        setCardMarginLeft("0px"); // Remove margin-left on smaller screens
      } else {
        setCardWidth("429px");
        setCardMaxHeight("377px");
        setCardMarginLeft("47px"); //
      }
    };

    window.addEventListener("resize", updateSize);
    updateSize(); // Initial check

    return () => window.removeEventListener("resize", updateSize);
  }, []);

  useEffect(() => {
    if (chartRef.current && chartRef.current.canvas) {
      const ctx = chartRef.current.canvas.getContext("2d");

      // Create a gradient for Converted
      const gradientFill = ctx.createLinearGradient(0, 0, 200, 200);
      gradientFill.addColorStop(0, "#06E766"); // Light Green
      gradientFill.addColorStop(1, "#27E7B1"); // Dark Green

      setGradient(gradientFill);
    }
  }, []);

  useEffect(() => {
    if (filteredData.length > 0) {
      // Count Converted and Other Leads
      const convertedCount = filteredData.filter(
        (item) => item.Converted === "True"
      ).length;
      const otherLeadsCount = filteredData.filter(
        (item) => item.Converted === "False"
      ).length;

      setChartData({
        labels: ["Converted", "Other Leads"],
        datasets: [
          {
            data: [convertedCount, otherLeadsCount],
            backgroundColor: [gradient || "#06E766", "#D3D3D3"], // Use gradient or fallback color
            hoverBackgroundColor: [gradient || "#27E7B1", "#a9a9a9"],
          },
        ],
      });
    }
  }, [filteredData, gradient]); // Runs whenever filteredData or gradient changes

  // Chart Options
  const chartOptions = {
    cutout: "70%", // Creates a donut effect
    plugins: {
      legend: { display: false },
      tooltip: { enabled: true },
    },
  };

  useEffect(() => {
    if (filteredData.length > 0) {
      const monthlyData = {};

      // Filter only converted clients
      const convertedClients = filteredData.filter(
        (item) => item.Converted === "True"
      );

      convertedClients.forEach((item) => {
        if (!item.Created) return;

        const date = new Date(item.Created);
        const monthKey = `${date.toLocaleString("default", {
          month: "short",
        })}-${date.getFullYear().toString().slice(-2)}`; // Format: Jan-24

        if (!monthlyData[monthKey]) {
          monthlyData[monthKey] = { clients: 0, deposit: 0 };
        }

        monthlyData[monthKey].clients += 1; // Count only converted clients
        monthlyData[monthKey].deposit += item.Deposit || 0; // Sum deposit
      });

      // Sort the data by date order
      const sortedKeys = Object.keys(monthlyData).sort((a, b) => {
        const [monthA, yearA] = a.split("-");
        const [monthB, yearB] = b.split("-");
        return (
          new Date(`${monthA} 1, 20${yearA}`) -
          new Date(`${monthB} 1, 20${yearB}`)
        );
      });

      const labels = sortedKeys;
      const clientData = sortedKeys.map((key) => monthlyData[key].clients);
      const depositData = sortedKeys.map((key) => monthlyData[key].deposit);

      setLineChartData({
        labels,
        datasets: [
          {
            label: "Clients",
            data: clientData,
            borderColor: "#16A34A",
            backgroundColor: "rgba(22, 163, 74, 0.2)",
            tension: 0.4,
          },
          {
            label: "Deposits",
            data: depositData,
            borderColor: "#EF4444",
            backgroundColor: "rgba(239, 68, 68, 0.2)",
            tension: 0.4,
          },
        ],
      });
    }
  }, [filteredData]);

  // Line Chart Options
  const lineChartOptions = {
    plugins: { legend: { display: false } },
    scales: {
      x: { display: false },
      y: { display: false },
    },
  };

  const agents = [
    { name: "Lily French", role: "Director Direct Sales", image: Image1 },
    { name: "Howard Adkins", role: "Director Direct Sales", image: Image2 },
    { name: "Earl Bowman", role: "Director Direct Sales", image: Image3 },
    { name: "Patrick Padilla", role: "Director Direct Sales", image: Image4 },
    { name: "Lily French", role: "Director Direct Sales", image: Image1 },
  ];

  // Function to Fetch Image using getUrl
  const getUrl = async (pictureId) => {
    try {
      const res = await getDocumentSas(pictureId);
      // console.log("Response from getDocumentSas:", res);

      if (res && res.url) {
        return res.url; // Return the URL to be used for the image
      } else {
        return Image1; // Use local fallback image
      }
    } catch (err) {
      console.error("Error fetching image:", err);
      return Image1; // Return local fallback image on failure
    }
  };



  return (
    <div className="p-6 bg-gray-100 min-h-screen">
      <style>{`.form-group.w-75 { display: none; } .form-inline.search-fulls.col-7 { border: none; } .MuiBox-root.css-19midj6 { padding: 3px; } .border-left { border-left-width: 4px; }`}</style>
      {/* Adding the new div before the SalesReports content */}
      <div style={{ height: "4px" }} className="container-fluid">
        <div className="page-title" style={{ display: "none" }}>
          <div className="row">
            <div className="col-6"></div>
            <div className="col-6">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="/dashboard">Dashboard</a>
                </li>
                <li className="breadcrumb-item active">Clients</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-between items-center mb-6">
        <h4 style={{ color: "black" }} className="text-2xl font-bold">
          Overview
        </h4>

        <div className="flex items-center gap-2" style={{ width: "65%" }}>
          {/* Platform (Source) Dropdown */}
          <Input
            type="select"
            className="border rounded px-3 py-2 w-48"
            value={selectedSource}
            onChange={(e) => setSelectedSource(e.target.value)}
          >
            <option value="">All Source</option>
            {uniqueSource.map((Source) => (
              <option key={Source} value={Source}>
                {Source}
              </option>
            ))}
          </Input>
          {/* Platform (Channel) Dropdown */}
          <Input
            type="select"
            className="border rounded px-3 py-2 w-48"
            value={selectedChannel}
            onChange={(e) => setSelectedChannel(e.target.value)}
          >
            <option value="">All Platforms</option>
            {uniqueChannels.map((channel) => (
              <option key={channel} value={channel}>
                {channel}
              </option>
            ))}
          </Input>

          {/* Campaign Dropdown */}
          <Input
            type="select"
            className="border rounded px-3 py-2 w-48"
            value={selectedCampaign}
            onChange={(e) => setSelectedCampaign(e.target.value)}
          >
            <option value="">All Campaigns</option>
            {uniqueCampaigns.map((campaign) => (
              <option key={campaign} value={campaign}>
                {campaign}
              </option>
            ))}
          </Input>

          {/* Date Range Filters */}
          <Input
            type="date"
            className="border rounded px-3 py-2 w-48"
            style={{ height: "41.6px" }}
            onChange={(e) =>
              setDateRange({ ...dateRange, start: e.target.value })
            }
          />
          <Input
            type="date"
            className="border rounded px-3 py-2 w-48"
            style={{ height: "41.6px" }}
            onChange={(e) =>
              setDateRange({ ...dateRange, end: e.target.value })
            }
          />
        </div>
      </div>

      {/* Main Card - Left Side Split, Right Side Full */}
      <Card
        className="p-6 mb-6 flex flex-row justify-between items-center  card1"
        style={{
          marginTop: "20px",
          height: "232px",
          borderRadius: "24px",
          marginBottom: "20px",
        }}
      >
        {/* Left Side - Split into Two Sections */}
        <div className="w-1/2 flex flex-col justify-between p-4">
          {/* Top Left - Header */}
          <div
            className="flex items-center gap-4"
            style={{ width: "271px", height: "79px", position: "absolute" }}
          >
            <Media left>
              <img src={Accounts} alt="Accounts" className="w-10" />
            </Media>
            <Media body>
              <h2
                className="text-lg font-semibold cursor-pointer text-blue-500 hover:underline"
                style={{ marginBottom: "-5px", color: "black" }}
                onClick={() => navigate("/marketing-report")} // Redirect to Market Report
              >
                {loading ? "Loading..." : filteredData.length}
              </h2>
              <p className="text-gray-500">Total Leads</p>
            </Media>
          </div>

          <div
            className="flex flex-wrap gap-2  overflow-auto max-h-40 md:max-h-52 lg:max-h-full"
            style={{ height: "auto", marginTop: "60px" }}
          >
            {statusArray.map((item, index) => {
              const color = colors[index % colors.length]; // Cycle through colors

              return (
                <button
                  key={item.status}
                  className="px-4 py-2 text-sm font-semibold rounded-lg statusbutton"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "auto",
                    minWidth: "100px",
                    maxWidth: "fit-content",
                    height: "34px",
                    padding: "0 12px",
                    border: `1px solid ${color}`,
                    color,
                    backgroundColor: `${color}1A`,
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                  }}
                  onClick={() => setSelectedStatus(item.status)} // Set clicked status
                >
                  {`${item.status} (${item.count})`}
                </button>
              );
            })}
          </div>
        </div>
        {/* Right Side - Full Chart */}
        <div className="w-1/2 flex justify-center items-center">
          <div
            style={{
              width: "192px",
              height: "192px",
              marginTop: "26px",
              marginRight: "16px",
              cursor: "pointer",
            }}
          >
            <Doughnut
              data={chartData}
              options={{ ...chartOptions, onClick: handleChartClick }}
            />
          </div>
        </div>
      </Card>

      {/* Table Section  for data detail for card 1 left */}
      {selectedStatus && (
        <Card
          className="p-6 mb-6 flex flex-col"
          style={{
            marginTop: "20px",
            borderRadius: "24px",
            marginBottom: "30px",
            padding: "16px",
          }}
        >
          <div className="flex justify-between items-center">
            <h4 className="text-lg font-semibold text-black">
              {selectedStatus} Reports
            </h4>

            {/* Dropdown and Close Button */}
            <div className="flex items-center gap-2">
              {/* Dropdown for items per page */}
              <select
                value={itemsPerPage}
                onChange={(e) => {
                  const value =
                    e.target.value === "All"
                      ? "All"
                      : parseInt(e.target.value, 10);
                  setItemsPerPage(value);
                  setCurrentPage(1); // Reset to first page
                }}
                className="border rounded px-3 py-2"
              >
                <option value="10">10 per page</option>
                <option value="20">20 per page</option>
                <option value="30">30 per page</option>
                <option value="50">50 per page</option>
                <option value="All">Show All</option>
              </select>

              {/* Close Button */}
              <Button color="danger" onClick={() => setSelectedStatus(null)}>
                Close
              </Button>
            </div>
          </div>

          {/* Table */}
          <table className="table-auto w-full border-collapse border border-gray-300 mt-4">
            <thead style={{ backgroundColor: "#e9e9ed" }}>
              <tr className="bg-gray-200">
                {[
                  { label: "Name", key: "Name" },
                  { label: "Email", key: "Email" },
                  { label: "Channel", key: "Channel" },
                  { label: "Source", key: "Source" },
                  { label: "Campaign", key: "Campaign" },
                  { label: "Deposit", key: "Deposit" },
                ].map(({ label, key }) => (
                  <th
                    key={key}
                    className="border border-gray-300 px-4 py-2 cursor-pointer"
                    onClick={() =>
                      setSortConfig({
                        key,
                        direction:
                          sortConfig.key === key &&
                          sortConfig.direction === "asc"
                            ? "desc"
                            : "asc",
                      })
                    }
                  >
                    {label}{" "}
                    {sortConfig.key === key
                      ? sortConfig.direction === "asc"
                        ? "▲"
                        : "▼"
                      : ""}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {paginatedData.map((item, index) => (
                <tr key={index} className="hover:bg-gray-100">
                  <td className="border border-gray-300 px-4 py-2">
                    {item.Name}
                  </td>
                  <td className="border border-gray-300 px-4 py-2">
                    {item.Email}
                  </td>
                  <td className="border border-gray-300 px-4 py-2">
                    {item.Channel}
                  </td>
                  <td className="border border-gray-300 px-4 py-2">
                    {item.Source}
                  </td>
                  <td className="border border-gray-300 px-4 py-2">
                    {item.Campaign}
                  </td>
                  <td className="border border-gray-300 px-4 py-2">
                    ${item.Deposit ? item.Deposit.toFixed(2) : "0.00"}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          {/* Pagination Controls */}
          <div className="flex justify-between items-center mt-3">
            <Button
              color="secondary"
              disabled={currentPage === 1 || itemsPerPage === "All"}
              onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
            >
              Previous
            </Button>

            <span className="text-gray-700">
              Page {currentPage} of {totalPages}
            </span>

            <Button
              color="secondary"
              disabled={currentPage === totalPages || itemsPerPage === "All"}
              onClick={() =>
                setCurrentPage((prev) => Math.min(prev + 1, totalPages))
              }
            >
              Next
            </Button>
          </div>
        </Card>
      )}

      {/* Table Section  for data detail for card 1  right*/}
      {selectedTables.map((table) => {
        // Apply sorting
        const sortedData = sortData(table.data, table.sortConfig);

        // Apply pagination
        const paginatedData = getPaginatedData(
          sortedData,
          table.currentPage,
          table.itemsPerPage
        );

        const totalPages =
          table.itemsPerPage === "All"
            ? 1
            : Math.ceil(sortedData.length / table.itemsPerPage);

        return (
          <Card
            key={table.id}
            className="p-6 mb-6 flex flex-col"
            style={{
              marginTop: "20px",
              borderRadius: "24px",
              marginBottom: "30px",
              padding: "16px",
            }}
          >
            <div className="flex justify-between items-center">
              <h4 className="text-lg font-semibold text-black">
                {table.title} Reports
              </h4>

              {/* Dropdown and Close Button */}
              <div className="flex items-center gap-2">
                {/* Dropdown for items per page */}
                <select
                  value={table.itemsPerPage}
                  onChange={(e) => {
                    const value =
                      e.target.value === "All"
                        ? "All"
                        : parseInt(e.target.value, 10);
                    setSelectedTables((prevTables) =>
                      prevTables.map((t) =>
                        t.id === table.id
                          ? { ...t, itemsPerPage: value, currentPage: 1 }
                          : t
                      )
                    );
                  }}
                  className="border rounded px-3 py-2"
                >
                  <option value="10">10 per page</option>
                  <option value="20">20 per page</option>
                  <option value="30">30 per page</option>
                  <option value="50">50 per page</option>
                  <option value="All">Show All</option>
                </select>

                {/* Close Button */}
                <Button
                  color="danger"
                  onClick={() =>
                    setSelectedTables((prevTables) =>
                      prevTables.filter((t) => t.id !== table.id)
                    )
                  }
                >
                  Close
                </Button>
              </div>
            </div>

            {/* Table */}
            <table className="table-auto w-full border-collapse border border-gray-300 mt-4">
              <thead style={{ backgroundColor: "#e9e9ed" }}>
                <tr className="bg-gray-200">
                  {[
                    { label: "Name", key: "Name" },
                    { label: "Email", key: "Email" },
                    { label: "Channel", key: "Channel" },
                    { label: "Source", key: "Source" },
                    { label: "Campaign", key: "Campaign" },
                    { label: "Deposit", key: "Deposit" },
                  ].map(({ label, key }) => (
                    <th
                      key={key}
                      className="border border-gray-300 px-4 py-2 cursor-pointer"
                      onClick={() =>
                        setSelectedTables((prevTables) =>
                          prevTables.map((t) =>
                            t.id === table.id
                              ? {
                                  ...t,
                                  sortConfig: {
                                    key,
                                    direction:
                                      t.sortConfig.key === key &&
                                      t.sortConfig.direction === "asc"
                                        ? "desc"
                                        : "asc",
                                  },
                                }
                              : t
                          )
                        )
                      }
                    >
                      {label}{" "}
                      {table.sortConfig.key === key
                        ? table.sortConfig.direction === "asc"
                          ? "▲"
                          : "▼"
                        : ""}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {paginatedData.map((item, index) => (
                  <tr key={index} className="hover:bg-gray-100">
                    <td className="border border-gray-300 px-4 py-2">
                      {item.Name}
                    </td>
                    <td className="border border-gray-300 px-4 py-2">
                      {item.Email}
                    </td>
                    <td className="border border-gray-300 px-4 py-2">
                      {item.Channel}
                    </td>
                    <td className="border border-gray-300 px-4 py-2">
                      {item.Source}
                    </td>
                    <td className="border border-gray-300 px-4 py-2">
                      {item.Campaign}
                    </td>
                    <td className="border border-gray-300 px-4 py-2">
                      ${item.Deposit ? item.Deposit.toFixed(2) : "0.00"}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            {/* Pagination Controls */}
            <div className="flex justify-between items-center mt-3">
              <Button
                color="secondary"
                disabled={
                  table.currentPage === 1 || table.itemsPerPage === "All"
                }
                onClick={() =>
                  setSelectedTables((prevTables) =>
                    prevTables.map((t) =>
                      t.id === table.id
                        ? { ...t, currentPage: Math.max(t.currentPage - 1, 1) }
                        : t
                    )
                  )
                }
              >
                Previous
              </Button>

              <span className="text-gray-700">
                Page {table.currentPage} of {totalPages}
              </span>

              <Button
                color="secondary"
                disabled={
                  table.currentPage === totalPages ||
                  table.itemsPerPage === "All"
                }
                onClick={() =>
                  setSelectedTables((prevTables) =>
                    prevTables.map((t) =>
                      t.id === table.id
                        ? {
                            ...t,
                            currentPage: Math.min(
                              t.currentPage + 1,
                              totalPages
                            ),
                          }
                        : t
                    )
                  )
                }
              >
                Next
              </Button>
            </div>
          </Card>
        );
      })}

      {/* Left Section: Registered Clients & Deposit Amount */}
      <div className="bg-white shadow-md rounded-2xl p-6 w-full flex ">
        {/* Flex Container to Keep Both Cards Side by Side */}
        <Row
          className="flex w-full gap-6 justify-center items-center"
          style={{ width: "100%", alignItems: "stretch" }}
        >
          {/* Left Section: Two Cards Side by Side */}
          <Col md={8} className="d-flex justify-content-center">
            <Card
              className="p-4 text-center flex-grow-1 h-100 d-flex align-items-center justify-content-center"
              style={{
                maxHeight: "251px",
                overflow: "hidden",
                borderRadius: "24px",
              }}
            >
              <CardBody className="d-flex w-100 h-100 justify-content-center align-items-center">
                {/* Flex Container for the Two Cards */}
                <div className="d-flex w-100 h-100 gap-4 justify-content-center align-items-center">
                  {/* First Card */}
                  <div
                    className="card p-4 mb-0"
                    style={{
                      width: cardWidth,
                      height: "203px",
                      borderRadius: "10px",
                      backgroundColor: "#EBF5FF",
                    }}
                  >
                    {/* Registered Clients Title */}
                    {/* Vertical Line */}
                    <div
                      style={{
                        width: "2px",
                        height: "73px",
                        backgroundColor: "#007bff",
                        position: "absolute",
                        left: 0,
                        top: "50%",
                        transform: "translateY(-50%)",
                        margin: "0",
                      }}
                    ></div>

                    <div className="flex justify-between mt-2 text-sm text-gray-600">
                      <div>
                        <span style={{ color: "#9F9F9F" }}>
                          Registered Clients
                        </span>
                        <br />
                        <strong
                          className="text-gray-900"
                          style={{ float: "left", fontSize: "24px" }}
                        >
                          {RegisteredCount}
                        </strong>
                      </div>
                      <div className="relative">
                        <div className="absolute top-0 right-0 bg-blue-100 p-2 rounded-full">
                          <img
                            src={BluewalletIcon}
                            alt="Blue Wallet Icon"
                            className="w-6 h-6"
                          />
                        </div>
                      </div>
                    </div>

                    {/* Paid and Organic Counts */}

                    <div className="flex justify-between mt-4 text-sm text-gray-600">
                      <div>
                        <span style={{ color: "#9F9F9F" }}>Paid</span>
                        <br />
                        <strong
                          className="text-gray-900"
                          style={{ float: "left" }}
                        >
                          {paidInConvertedCount}
                        </strong>
                      </div>
                      <div>
                        <span style={{ color: "#9F9F9F" }}>Organic</span>
                        <br />
                        <strong
                          className="text-gray-900"
                          style={{ float: "left" }}
                        >
                          {organicInConvertedCount}
                        </strong>
                      </div>
                    </div>
                  </div>

                  {/* Second Card */}

                  <div
                    className="card p-4 mb-0"
                    style={{
                      width: cardWidth,
                      height: "203px",
                      borderRadius: "10px",
                      backgroundColor: "#F4EDFD",
                      marginLeft: cardMarginLeft,
                    }}
                  >
                    {/* Vertical Line */}
                    <div
                      style={{
                        width: "2px",
                        height: "73px",
                        backgroundColor: "#BD59F9",
                        position: "absolute",
                        left: 0,
                        top: "50%",
                        transform: "translateY(-50%)",
                        margin: "0",
                      }}
                    ></div>
                    {/* Registered Clients Title */}
                    <div className="flex justify-between mt-2 text-sm text-gray-600">
                      <div>
                        <span style={{ color: "#9F9F9F" }}>Deposit Amount</span>
                        <br />
                        <strong
                          className="text-gray-900"
                          style={{ float: "left", fontSize: "24px" }}
                        >
                          ${totalDepositConverted.toFixed(2)}
                        </strong>
                      </div>
                      <div className="relative">
                        <div className="absolute top-0 right-0 bg-blue-100 p-2 rounded-full">
                          <img
                            src={PinkwalletIcon}
                            alt="Pink Wallet Icon"
                            className="w-6 h-6"
                          />
                        </div>
                      </div>
                    </div>

                    {/* Paid and Organic Counts */}
                    <div className="flex justify-between mt-4 text-sm text-gray-600">
                      <div>
                        <span style={{ color: "#9F9F9F" }}>Paid</span>
                        <br />
                        <strong
                          className="text-gray-900"
                          style={{ float: "left" }}
                        >
                          ${paidDepositConverted.toFixed(2)}
                        </strong>
                      </div>
                      <div>
                        <span style={{ color: "#9F9F9F" }}>Organic</span>
                        <br />
                        <strong
                          className="text-gray-900"
                          style={{ float: "left" }}
                        >
                          ${organicDepositConverted.toFixed(2)}
                        </strong>
                      </div>
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>

          {/* Chart Section */}
          <Col md={4} className="d-flex position-relative">
            <div
              className="card flex-grow-1 d-flex h-100 position-relative"
              style={{
                maxHeight: "251px",
                borderRadius: "24px",
                marginTop: "20px",
              }}
            >
              {/* Labels with Absolute Position */}
              <div
                className="d-flex"
                style={{
                  position: "absolute",
                  top: window.innerWidth >= 768 ? "30px" : "0px",
                  left: "20px", // Adjust left alignment if needed
                  zIndex: 10, // Ensures labels are above the chart
                }}
              >
                <span className="badge bg-success me-2">CLIENTS</span>
                <span className="badge bg-danger">DEPOSITS</span>
              </div>

              {/* Chart Container */}
              <div
                className="card-body d-flex align-items-center justify-content-center h-100"
                style={{ maxHeight: "251px", overflow: "hidden" }}
              >
                <Line data={lineChartData} options={lineChartOptions} />
              </div>
            </div>
          </Col>
        </Row>
      </div>

      <Card
        className="p-6 mb-6"
        style={{
          marginTop: "20px",
          borderRadius: "24px",
          marginBottom: "30px",
          maxHeight: "419px",
        }}
      >
        <div style={{ padding: "26px" }}>
          {/* Header Section */}
          <div className="flex justify-between items-center">
            <h4 className="text-lg font-semibold text-black">Sales</h4>
            <a href="/MarketSales" className="text-blue-500 text-sm">
              View All
            </a>
          </div>

          {loading ? (
            <p>Loading sales data...</p>
          ) : Object.keys(groupedSalesData).length === 0 ? (
            <p>No sales data available.</p>
          ) : (
            <div className="flex flex-wrap gap-4">
              {Object.values(groupedSalesData).map((data) => (
                <Card
                  className="p-4 text-center shadow-md bg-gray-100 rounded-14 flex-shrink-0 d-flex flex-column align-items-center justify-content-between"
                  style={{
                    background: "#F7F8F9",
                    width: "calc(100% / 4 - 16px)", // Ensures even distribution
                    maxWidth: "226px",
                    minWidth: "180px",
                    height: "320px", // Fixed height to keep all cards aligned
                    borderRadius: "14px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    paddingBottom: "15px",
                  }}
                >
                  <CardBody className="d-flex flex-column justify-content-between w-100">
                    {/* Agent Info */}
                    <div>
                      <img
                        src={agentImages[data.pictureId] || Image1}
                        alt={data.SalesManager}
                        className="w-16 h-16 rounded-full mx-auto mb-2 object-cover"
                        style={{
                          width: "87.1px",
                          height: "87.1px",
                          borderRadius: "50%",
                          border: "3px solid #ddd",
                          objectFit: "cover",
                        }}
                      />
                      <p
                        style={{
                          fontSize: "15px",
                          color: "#2C323F",
                          marginBottom: "5px",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          maxWidth: "180px",
                        }}
                      >
                        {data.SalesManager}
                      </p>
                      <p
                        style={{
                          fontSize: "10.56px",
                          color: "#979797",
                          minHeight: "30px", // Ensures job title does not affect alignment
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          textAlign: "center",
                        }}
                      >
                        {data.SalesJobTitle}
                      </p>
                    </div>

                    {/* View Profile Button - Always at the Bottom */}
                    <button
                      onClick={() =>
                        navigate("/MarketSales", {
                          state: { selectedAgent: data },
                        })
                      }
                      style={{
                        background: "#E6E6E6",
                        color: "#898989",
                        border: "none",
                        height: "30px",
                        width: "120px",
                        borderRadius: "4px",
                        cursor: "pointer",
                        marginTop: "auto", // Ensures the button stays at the bottom
                      }}
                    >
                      View Profile
                    </button>
                  </CardBody>
                </Card>
              ))}
            </div>
          )}
        </div>
      </Card>
    </div>
  );
};

export default Dashboard;
