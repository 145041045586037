import React, { useState, useEffect } from "react";
import { Col, Card, CardHeader, CardBody, Row } from "reactstrap";
import DataTable from "react-data-table-component";
import { toast } from "react-toastify";
import { TextField, TablePagination } from "@mui/material";
import { CashbackReport } from "Services";

const CashbackReportComponent = () => {
  const [data, setData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    let isMounted = true;

    CashbackReport.CashbackReport()
      .then((res) => {
        console.log("API Response:", res); // Debugging: Check API response

        if (isMounted) {
          if (Array.isArray(res)) {
            // Sort the data in descending order by slno
            const sortedData = res.sort((a, b) => b.slno - a.slno);

            const formattedData = sortedData.map((item) => ({
              sllno: item.slno,
              PositionID: item.PositionID || "N/A",
              Login: item.Login,
              Volume: item.Volume || "N/A",
              Symbol: item.Symbol || "N/A",
              Platform: item.Platform || "N/A",
              groupname: item.groupname || "N/A",
              perlot: item.perlot || "0.00",
              cashbackamount:
                parseFloat(item.cashbackamount).toFixed(2) || "0.00",
              createAT: item.createAT
                ? new Date(item.createAT).toLocaleDateString()
                : "N/A",
              cashbackupdateAT: item.cashbackupdateAT
                ? new Date(item.cashbackupdateAT).toLocaleDateString()
                : "N/A",
              cashbackupdate: item.cashbackupdate || 0, // Store the raw value for conditional formatting
            }));

            setData(formattedData);
          } else {
            console.warn("Unexpected response format: Expected an array");
            toast.error("Error: Unexpected data format - Expected an array");
          }
        }
      })
      .catch((error) => {
        if (isMounted) {
          console.error("Error fetching cashback report data:", error);
          toast.error("Error fetching cashback report data");
        }
      });

    return () => {
      isMounted = false;
    };
  }, []);

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Function to render cashback status with colors
  const renderCashbackStatus = (status) => {
    let statusText = "Pending";
    let bgColor = "#ff4d4d"; // Red for Pending

    if (status === 1) {
      statusText = "Done";
      bgColor = "#28a745"; // Green
    } else if (status === 2) {
      statusText = "Other Symbols";
      bgColor = "#ffa500"; // Orange
    }

    return (
      <span
        style={{
          backgroundColor: bgColor,
          color: "white",
          padding: "5px 10px",
          borderRadius: "5px",
          fontWeight: "bold",
          display: "inline-block",
        }}
      >
        {statusText}
      </span>
    );
  };

  const tableColumns = [
    { name: "Sl No", selector: (row) => row.sllno, sortable: true },
    { name: "Position ID", selector: (row) => row.PositionID, sortable: true },
    { name: "Login", selector: (row) => row.Login, sortable: true },
    { name: "Lot", selector: (row) => row.Volume, sortable: true },
    { name: "Symbols", selector: (row) => row.Symbol, sortable: true },
    { name: "Platform", selector: (row) => row.Platform, sortable: true },
    { name: "Group Name", selector: (row) => row.groupname, sortable: true },
    { name: "Per Lot", selector: (row) => row.perlot, sortable: true },
    {
      name: "Amount ($)",
      selector: (row) => row.cashbackamount,
      sortable: true,
    },
    { name: "Created At", selector: (row) => row.createAT, sortable: true },
    {
      name: "Updated At",
      selector: (row) => row.cashbackupdateAT,
      sortable: true,
    },
    {
      name: "Cashback Status",
      selector: (row) => row.cashbackupdate,
      sortable: true,
      cell: (row) => renderCashbackStatus(row.cashbackupdate), // Render with colors
    },
  ];

  const filteredData = data.filter((row) => {
    return Object.values(row).some(
      (value) =>
        value &&
        value.toString().toLowerCase().includes(searchTerm.toLowerCase())
    );
  });

  return (
    <div>
      <Col sm="12">
        <style>
          {`
          .form-group.w-75 {
            display: none;
        
          }
            .form-inline.search-fulls.col-7 {
              border: none;
            }

          .MuiBox-root.css-19midj6 {
                  padding: 3px;
            }
        `}
        </style>
        {/* Adding the new div before the SalesReports content */}
        <div style={{ height: "20px" }} className="container-fluid">
          <div className="page-title" style={{ display: "none" }}>
            <div className="row">
              <div className="col-6"></div>
              <div className="col-6">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/dashboard">Dashboard</a>
                  </li>
                  <li className="breadcrumb-item active">Clients</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <Card>
          <CardHeader className="d-flex justify-content-between align-items-center">
            <h5>Cashback Report</h5>
          </CardHeader>
          <CardBody>
            <Row className="mb-3">
              <Col md={3}>
                <TextField
                  label="Search"
                  variant="outlined"
                  onChange={handleSearch}
                  fullWidth
                />
              </Col>
            </Row>
            <DataTable
              data={filteredData.slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage
              )}
              columns={tableColumns}
              striped
              persistTableHead
              noDataComponent="No records found"
            />

            <TablePagination
              rowsPerPageOptions={[10, 25, 50, 100, 250, 500, 1000]}
              component="div"
              count={filteredData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              sx={{
                "& .MuiTablePagination-selectLabel": {
                  marginBottom: 0,
                },
                "& .MuiTablePagination-displayedRows": {
                  marginBottom: 0,
                },
              }}
            />
          </CardBody>
        </Card>
      </Col>
    </div>
  );
};

export default CashbackReportComponent;
