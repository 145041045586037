import React, { Fragment, useEffect, useState } from "react";
import {
  Col,
  Card,
  CardBody,
  Form,
  Row,
  ListGroupItem,
  ListGroup,
} from "reactstrap";
import { Btn } from "../../../../AbstractElements";
import { useForm } from "react-hook-form";
import { FInput, FUserSelect, FSelect } from "CommonElements/Form";
import { clientService, activitiesService } from "Services";
import SweetAlert from "sweetalert2";
import { useSelector } from "react-redux";
import { getMenuPerm } from "utils";
import AddBankAccount from "../Notes/Notes";
import countriesStates from "../../../Common/json/countriesStates.json";
import { BorderRight } from "Constant";
import { CustomFieldupdate } from "Services"; 

const BasicInformation = (props) => {
  const { customerId = "", data = {} } = props;

  const [submitState, setSubmitState] = useState({
    loading: false,
  });

  const [coldCall, setcoldCall] = useState(false);
  const setFlagToTrue = () => {
    setcoldCall(true);
  };

  const [fullName, setFullName] = useState(
    `${data.firstName} ${data.lastName ? data.lastName : ""}`
  );
  const handleFullName = (e) => {
    setFullName(e.target.value);
    setValue("firstName", e.target.value);
  };

  const [list, setList] = useState([]);
  const [leadSourceOptions, setLeadSourceOptions] = useState([]);
  const [utmSourceOptions, setUtmSourceOptions] = useState([]);
  const [utmCategoryOptions, setUtmCategoryOptions] = useState([]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    control,
  } = useForm({
    defaultValues: data, // assume `data` contains initial values
  });

  useEffect(() => {
    const formFields = getValues();
    Object.keys(formFields).forEach((key) => {
      if (data[key]) {
        let datas = data[key];
        key === "country"
          ? setValue(key, datas.toUpperCase())
          : key === "nationality"
          ? setValue(key, datas.toUpperCase())
          : setValue(key, data[key]);
      }
    });
  }, []);
  const permissions = useSelector(
    (state) =>
      (state.login &&
        state.login.profile &&
        state.login.profile.Role &&
        state.login.profile.Role.permissions) ||
      {}
  );

  const userPermissions = useSelector(
    (state) => (state.login && state.login.profile) || {}
  );

  const onSubmit = (formData) => {
    console.log("data is ", formData);
    delete formData.Agent;
    delete formData.Transactions;
    delete formData.Parent;
    delete formData.Retention;
    delete formData.Group;
    const timestamp = new Date().toISOString();

    if (
      formData.callStatus.replace(/\s+/g, "") === "Hot-Interested" ||
      formData.callStatus.replace(/\s+/g, "") === "Warm-callafter10days"
    ) {
      // Store the change in local storage or send it to the server
      console.log("time save");
      localStorage.setItem(
        `lead-${formData.firstName}-status-change`,
        JSON.stringify({
          status: formData.callStatus.replace(/\s+/g, ""),
          date: timestamp,
        })
      );
    }

    if (
      formData !== "" &&
      (formData.callStatus === "New Lead" ||
        (formData.callStatus !== "New Lead" && coldCall === true))
    ) {
      setSubmitState({ loading: true });

      // Step 1: Compare form data with the original `data` object
      const changedData = {};
      Object.keys(formData).forEach((key) => {
        if (formData[key] !== data[key]) {
          changedData[key] = formData[key]; // Only capture the fields that were changed
        }
      });

      console.log("Changed data: ", changedData);

      const logData = {
        type: `Lead Info Updated`,
        userId: userPermissions.id,
        createdAt: Date.now(),
        customerId: customerId,
        note: changedData,
      };

      activitiesService.postActivity(logData);

      clientService
        .updateLead(customerId, changedData)
        .then((res) => {
          setSubmitState({
            loading: false,
            status: "Profile updated sucessfully",
          });
          SweetAlert.fire({
            title: "Success",
            text: "Profile updated sucessfully!",
            icon: "success",
          });
        })
        .catch((err) => {
          setSubmitState({
            loading: false,
            status: "Error in updating Profile",
          });
          SweetAlert.fire({
            title: "Failed",
            text: err.response.data.message,
            icon: "error",
          });
        });
      // alert('You submitted the form and stuff!');
    } else if (
      formData !== "" &&
      formData.callStatus !== "New Lead" &&
      coldCall === false
    ) {
      setSubmitState({
        loading: false,
        status: "Error in updating Profile",
      });
      SweetAlert.fire({
        title: "Failed",
        text: "Please put the comment for Call Response!",
        icon: "error",
      });
    } else {
      errors.showMessages();
    }
  };


   useEffect(() => {
    const fetchDropdownData = async () => {
      try {
        const response = await CustomFieldupdate.CustomFieldMarketing();

        if (response && Array.isArray(response)) {
          // Extract Lead Source options
          const leadOptions = response
            .filter((item) => item.field === "Lead Source")
            .map((item) => ({ name: item.options, value: item.options }));

          // Ensure previous Lead Source is included
          if (data.source && !leadOptions.some((opt) => opt.value === data.source)) {
            leadOptions.unshift({ name: data.source, value: data.source });
          }
          setLeadSourceOptions(leadOptions);

          // Extract UTM Source options
          const utmSources = response
            .filter((item) => item.field === "UTM Source")
            .map((item) => ({ name: item.options, value: item.options }));

          if (data.utmSource && !utmSources.some((opt) => opt.value === data.utmSource)) {
            utmSources.unshift({ name: data.utmSource, value: data.utmSource });
          }
          setUtmSourceOptions(utmSources);

          // Extract UTM Category options
          const utmCategories = response
            .filter((item) => item.field === "UTM Category")
            .map((item) => ({ name: item.options, value: item.options }));

          if (data.utmCategory && !utmCategories.some((opt) => opt.value === data.utmCategory)) {
            utmCategories.unshift({ name: data.utmCategory, value: data.utmCategory });
          }
          setUtmCategoryOptions(utmCategories);
        }
      } catch (error) {
        console.error("Error fetching dropdown data:", error);
      }
    };

    fetchDropdownData();
  }, [data.source, data.utmSource, data.utmCategory]); // Re-fetch if previous values change

  
  return (
    <Fragment>
      <Row>
        <Col md="8 mb-3">
          <Card>
            <CardBody>
              <Form
                className="needs-validation"
                noValidate=""
                onSubmit={handleSubmit(onSubmit)}
              >
                <Row>
                  <Col md="12 mb-3 fs_20 fw_600">Client Info</Col>
                </Row>
                <hr></hr>
                <Row>
                  <Col md="4 mb-3">
                    <FInput
                      errors={errors}
                      label="Full Name"
                      name="firstName"
                      value={fullName}
                      handleOnChange={handleFullName}
                      register={register}
                      //placeholder= 'Enter First Name'
                      validation={{ required: true }}
                    />
                  </Col>
                  {/* <Col md='4 mb-3' >
                                    <FInput
                                        errors={errors}
                                        label = 'Last Name'
                                        name = 'lastName'
                                        register = {register}
                                        //placeholder= 'Enter Last Name'
                                    />
                                </Col> */}
                  <Col md="4 mb-3">
                    <FInput
                      errors={errors}
                      label="IB ID"
                      name="ibid"
                      register={register}
                      //placeholder= 'Enter IB ID'
                      validation={{ required: false }}
                      disabled={
                        getMenuPerm(permissions, "clients:disable") === 1 &&
                        true
                      }
                    />
                  </Col>
                  <Col md="4 mb-3">
                    <FInput
                      errors={errors}
                      label="Email"
                      name="email"
                      register={register}
                      //placeholder= 'Enter Email Address'
                      validation={{ required: true }}
                      disabled={
                        getMenuPerm(permissions, "clients:disable") === 1 &&
                        true
                      }
                    />
                  </Col>
                  <Col md="4 mb-3">
                    <FInput
                      errors={errors}
                      label="Phone"
                      name="phone"
                      register={register}
                      //placeholder= 'Enter Phone Number'
                      validation={{ required: false }}
                      disabled={
                        getMenuPerm(permissions, "clients:disable") === 1 &&
                        true
                      }
                    />
                  </Col>
                  <Col md="4 mb-3">
                    <FSelect
                      control={control}
                      errors={errors}
                      label="Lead Status"
                      name="callStatus"
                      register={register}
                      //placeholder= 'Enter Lead Status'

                      options={[
                        { name: "New Lead", value: "New Lead" },
                        { name: "ReOpen", value: "ReOpen" },
                        { name: "Not Interested", value: "Not Interested" },
                        { name: "Not Reachable", value: "Not Reachable" },
                        {
                          name: "Cold -  call later",
                          value: "Cold -  call later",
                        },
                        {
                          name: "Warm -  call after 10 days",
                          value: "Warm -  call after 10 days",
                        },
                        {
                          name: "Hot - Interested",
                          value: "Hot - Interested ",
                        },
                        { name: "Wrong Number", value: "Wrong Number" },
                        { name: "Converted", value: "Converted" },
                      ]}
                      validation={{ required: true }}
                      value={data.callStatus}
                      setValue={setValue}
                    />
                  </Col>
                  <Col md="4 mb-3">
                    <FSelect
                      control={control}
                      errors={errors}
                      label="Nationality"
                      name="nationality"
                      register={register}
                      //placeholder= 'Enter Nationality'
                      validation={{ required: false }}
                      value={data.nationality || ""}
                      options={countriesStates.map((obj) => {
                        return {
                          name: obj.name,
                          value: obj.name,
                        };
                      })}
                      setValue={setValue}
                    />
                  </Col>
                  <Col md="4 mb-3">
                    <FInput
                      errors={errors}
                      label="City"
                      name="city"
                      register={register}
                      //placeholder= 'Enter City'
                      validation={{ required: false }}
                      disabled={
                        getMenuPerm(permissions, "clients:disable") === 1 &&
                        true
                      }
                    />
                  </Col>
                  <Col md="4 mb-3">
                    <FInput
                      errors={errors}
                      label="State"
                      name="state"
                      register={register}
                      //placeholder= 'Enter State'
                      disabled={
                        getMenuPerm(permissions, "clients:disable") === 1 &&
                        true
                      }
                    />
                  </Col>
                  <Col md="4 mb-3">
                    <FSelect
                      control={control}
                      errors={errors}
                      label="Country of Residence"
                      name="country"
                      register={register}
                      //placeholder= 'Enter Country of Residence'
                      validation={{ required: false }}
                      value={data.country || ""}
                      options={countriesStates.map((obj) => {
                        return {
                          name: obj.name,
                          value: obj.name,
                        };
                      })}
                      setValue={setValue}
                    />
                  </Col>
                  <Col md="4 mb-3">
                    <FInput
                      errors={errors}
                      label="PO Box"
                      name="address2"
                      register={register}
                      //placeholder= 'Enter PO Box'
                      disabled={
                        getMenuPerm(permissions, "clients:disable") === 1 &&
                        true
                      }
                    />
                  </Col>
                  <Col md="4 mb-3">
                    <FInput
                      errors={errors}
                      label="Langauge"
                      name="language"
                      register={register}
                      //placeholder= 'Langauge'
                      disabled={
                        getMenuPerm(permissions, "clients:disable") === 1 &&
                        true
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md="12 mb-3 fs_20 fw_600">Lead Info</Col>
                </Row>
                <hr></hr>
                <Row>
                  <Col md="4 mb-3">
                    <FUserSelect
                      control={control}
                      errors={errors}
                      label="Lead Owner"
                      name="agentId"
                      validation={{ required: false }}
                      setValue={setValue}
                      value={data.agentId}
                      defaultOptions={data.Agent ? [data.Agent] : []}

                      // isClient={true}
                    />
                  </Col>
                  <Col md="4 mb-3">
                  <FSelect
          control={control}
          errors={errors}
          label="Lead Source"
          name="source"
          register={register}
          validation={{ required: true }}
          value={getValues("source") || data.source || ""}
          options={leadSourceOptions}
          setValue={setValue}
        />
                  </Col>

                  <Col md="4 mb-3">
                    <FInput
                      errors={errors}
                      label="Modified Time"
                      name="updatedAt"
                      // register = {register}
                      disabled
                      //placeholder= 'Lead Update Time'
                    />
                  </Col>
                </Row>
                {/*<Row>
                                <Col md='12 mb-3 fs_20'>
                                    Call Logs
                                </Col>
                            </Row>
                            <hr></hr>
                            <Row>
                                    </Row>*/}
                <Row>
                  <Col md="12 mb-3 fs_20 fw_600">Marketing Info</Col>
                </Row>
                <hr></hr>
                <Row>
                  <Col md="4 mb-3">
                    <FInput
                      errors={errors}
                      label="UTM Campaign"
                      //placeholder= 'UTM Campaign'
                      name="utmCampaign"
                      register={register}
                      disabled={
                        getMenuPerm(permissions, "clients:disable") === 1 &&
                        true
                      }
                    />
                  </Col>
                  <Col md="4 mb-3">
                  <FSelect
          control={control}
          errors={errors}
          label="UTM Source"
          name="utmSource"
          register={register}
          validation={{ required: false }}
          value={getValues("utmSource") || data.utmSource || ""}
          options={utmSourceOptions}
          setValue={setValue}
        />
                  </Col>

                  <Col md="4 mb-3">
                    <FInput
                      errors={errors}
                      label="UTM Medium"
                      //placeholder= 'UTM Medium'
                      name="utmMedium"
                      register={register}
                      disabled={
                        getMenuPerm(permissions, "clients:disable") === 1 &&
                        true
                      }
                    />
                  </Col>
                  <Col md="4 mb-3">
                    <FInput
                      errors={errors}
                      label="UTM Term"
                      //placeholder= 'UTM Term'
                      name="utmTerm"
                      register={register}
                      disabled={
                        getMenuPerm(permissions, "clients:disable") === 1 &&
                        true
                      }
                    />
                  </Col>
                  <Col md="4 mb-3">
                    <FInput
                      errors={errors}
                      label="UTM Content"
                      //placeholder= 'UTM Content'
                      name="utmContent"
                      register={register}
                      disabled={
                        getMenuPerm(permissions, "clients:disable") === 1 &&
                        true
                      }
                    />
                  </Col>
                  <Col md="4 mb-3">
                  <FSelect
          control={control}
          errors={errors}
          label="UTM Category"
          name="utmCategory"
          register={register}
          validation={{ required: false }}
          value={getValues("utmCategory") || data.utmCategory || ""}
          options={utmCategoryOptions}
          setValue={setValue}
        />
                  </Col>
                  <Col md="4 mb-3">
                    <FInput
                      errors={errors}
                      label="Do you have experience as an IB or in financial trading ?"
                      name="expAsIB"
                      register={register}
                      disabled={
                        getMenuPerm(permissions, "clients:disable") === 1 &&
                        true
                      }
                    />
                  </Col>
                  <Col md="4 mb-3">
                    <FInput
                      errors={errors}
                      label="How do you plan on promoting GoDoCM to your network ?"
                      name="promotionplans"
                      register={register}
                      disabled={
                        getMenuPerm(permissions, "clients:disable") === 1 &&
                        true
                      }
                    />
                  </Col>
                  <Col md="4 mb-3">
                    <FInput
                      errors={errors}
                      label="How many clients do you have in your network or database ?"
                      name="noofClients"
                      register={register}
                      disabled={
                        getMenuPerm(permissions, "clients:disable") === 1 &&
                        true
                      }
                    />
                  </Col>
                </Row>
                {getMenuPerm(permissions, "leads:update") === 1 && (
                  <Btn
                    disabled={submitState.loading}
                    attrBtn={{ color: "primary" }}
                  >
                    {"Update"}
                  </Btn>
                )}
              </Form>
            </CardBody>
          </Card>
        </Col>
        <Col md="4 mb-3">
          <Card style={{ overflow: "visible" }}>
            <CardBody style={{ padding: "30px" }}>
              <Row>
                <Col>
                  <AddBankAccount
                    customerId={customerId}
                    setFlagToTrue={setFlagToTrue}
                  />
                </Col>
              </Row>

              <Row>
                <Col>
                  <ListGroup>
                    {/* map over and print items */}
                    {list &&
                      list !== undefined &&
                      list.map((item) => (
                        <div key={item.id}>
                          <ListGroupItem
                            variant="dark"
                            action
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              border: "none",
                              borderBottom: "1px solid rgba(0,0,0,.125)",
                            }}
                          >
                            {item.value}
                          </ListGroupItem>
                        </div>
                      ))}
                  </ListGroup>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};
export default BasicInformation;
