import React, { Fragment, useState, useEffect } from 'react';
import {
  Col,
  Row,
  Card,
  CardHeader,
  CardBody,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
} from 'reactstrap';
import DataTable from 'react-data-table-component';
import { toast } from 'react-toastify';
import { TradingGroup as TradingGroupService } from 'Services'; // Adjust the path if necessary
import { TextField, TablePagination } from '@mui/material';
import { Edit } from 'react-feather';




const TradingGroup = () => {
  // Filter states
  const [platformFilter, setPlatformFilter] = useState('');
  const [typeFilter, setTypeFilter] = useState('');
  const [ibGroupFilter, setIbGroupFilter] = useState('');
  const [modal, setModal] = useState(false);
  const [data, setData] = useState([]); // Initialize with an empty array
  const [formValues, setFormValues] = useState({
    groupPath: '',
    platform: '',
    type: '',
    ib_group: '',
    margin_call: '',
    stop_out: '',
  });
  const [searchTerm, setSearchTerm] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedRowId, setSelectedRowId] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);

  const toggle = () => setModal(!modal);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const fetchTradingGroups = () => {
    TradingGroupService.TradingGroup()
      .then((res) => {
        // console.log('API Response:', res); // Log the response to see the structure
        if (res && Array.isArray(res)) {
          setData(res); // Set the response directly if it's an array
        } else {
          setData([]); // Fallback to empty array if no data
        }
      })
      .catch((error) => {
        console.error('Error fetching trading groups:', error);
        toast.error('Error fetching trading groups');
      });
  };

  const handleDeleteGroup = () => {
    if (selectedRows.length > 0) {
      Promise.all(selectedRows.map(id => TradingGroupService.deleteTradingGroup(id)))
        .then(() => {
          toast.success('Selected Trading Groups deleted successfully!');
          fetchTradingGroups();
          setSelectedRows([]); // Reset selection after deletion
        })
        .catch((error) => {
          console.error('Error deleting trading groups:', error);
          toast.error('Error deleting trading groups');
        });
    } else {
      toast.error('Please select groups to delete');
    }
  };

  const resetFilters = () => {
    setPlatformFilter('');
    setTypeFilter('');
    setIbGroupFilter('');
  };



  const CustomTablePagination = (props) => (
    <TablePagination
      {...props}
      sx={{
        '& .MuiTablePagination-selectLabel': {
          marginBottom: 0,
        },
        '& .MuiTablePagination-displayedRows': {
          marginBottom: 0,
        },
      }}
    />
  );

  useEffect(() => {
    fetchTradingGroups();
  }, []);


  const handleSubmit = () => {
    const payload = { ...formValues };

    if (selectedRowId) {
      TradingGroupService.updateTradingGroup(selectedRowId, payload)
        .then(() => {
          toast.success('Trading Group updated successfully!');
          fetchTradingGroups();
          resetForm();
        })
        .catch((error) => {
          console.error('Error updating trading group:', error);
          toast.error('Error updating trading group');
        });
    } else {
      TradingGroupService.createTradingGroup(payload)
        .then(() => {
          toast.success('Trading Group added successfully!');
          fetchTradingGroups();
          resetForm();
        })
        .catch((error) => {
          console.error('Error adding trading group:', error);
          toast.error('Error adding trading group');
        });
    }
  };


  const handleEdit = (row) => {
    setSelectedRowId(row.id);
    setFormValues({
      groupPath: row.groupPath,
      platform: row.platform,
      type: row.type,
      ib_group: row.ib_group,
      margin_call: row.margin_call,
      stop_out: row.stop_out
    });
    toggle();
  };

  const resetForm = () => {
    setFormValues({
      groupPath: '',
      platform: '',
      type: '',
      ib_group: '',
      margin_call: '',
      stop_out: ''
    });
    setSelectedRowId(null);
    toggle();
  };

  const tableColumns = [
    { name: 'ID', selector: row => row.id, sortable: true },
    { name: 'Group Name', selector: row => row.groupPath, sortable: true },
    { name: 'Platform', selector: row => row.platform, sortable: true },
    { name: 'Type', selector: row => row.type, sortable: true },
    { name: 'IB Group', selector: row => row.ib_group, sortable: true },
    { name: 'Margin Call', selector: row => row.margin_call, sortable: true },
    { name: 'Stop Out', selector: row => row.stop_out, sortable: true },
    {
      name: 'Actions',
      cell: (row) => (
        <Fragment>
          <Button color="warning" onClick={() => handleEdit(row)} style={{ marginRight: '10px' }}>
            <Edit size={16} /> {/* Edit Icon */}
          </Button>
        </Fragment>
      ),
    },
  ];


  const filteredData = data.filter(row => {
    const matchesPlatform = platformFilter ? row.platform === platformFilter : true;
    const matchesType = typeFilter ? row.type === typeFilter : true;
    const matchesIbGroup = ibGroupFilter ? row.ib_group === ibGroupFilter : true;
    const matchesSearch = Object.values(row).some(value =>
      value && value.toString().toLowerCase().includes(searchTerm.toLowerCase())
    );

    return matchesPlatform && matchesType && matchesIbGroup && matchesSearch;
  });



  return (
    <Fragment>
      <style>
        {`
          .form-group.w-75 {
            display: none;
        
          }
            .form-inline.search-fulls.col-7 {
              border: none;
            }

          .MuiBox-root.css-19midj6 {
                  padding: 3px;
            }
        `}
      </style>

      {/* Add the dashboard header */}
      <div style={{ height: '20px' }} className="container-fluid">
        <div className="page-title" style={{ display: 'none' }}>
          <div className="row">
            <div className="col-6">

            </div>
            <div className="col-6">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">

                </li>
                <li className="breadcrumb-item active"></li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <Col sm="12">
        <Card>
          <CardHeader className="d-flex justify-content-between align-items-center">
            <div className="ms-auto">
              <Button color="primary" onClick={toggle}>Add Trading Group</Button>{' '}
              <Button color="danger" onClick={handleDeleteGroup}>Delete Group</Button>
            </div>
          </CardHeader>
          <CardBody>
            {/* Row to display the three filters and the reset button in one line */}
            <Row form className="mb-4"> {/* Adds margin-bottom for spacing */}
              <Col md={3}>
                <FormGroup>
                  <Label for="platformFilter">Platform</Label>
                  <Input
                    type="select"
                    name="platformFilter"
                    id="platformFilter"
                    value={platformFilter}
                    onChange={e => setPlatformFilter(e.target.value)}
                  >
                    <option value="">All Platforms</option>
                    <option value="MT4">MT4</option>
                    <option value="MT5">MT5</option>
                  </Input>
                </FormGroup>
              </Col>
              <Col md={3}>
                <FormGroup>
                  <Label for="typeFilter">Type</Label>
                  <Input
                    type="select"
                    name="typeFilter"
                    id="typeFilter"
                    value={typeFilter}
                    onChange={e => setTypeFilter(e.target.value)}
                  >
                    <option value="">All Types</option>
                    <option value="WH">WH</option>
                    <option value="STP">STP</option>
                  </Input>
                </FormGroup>
              </Col>
              <Col md={3}>
                <FormGroup>
                  <Label for="ibGroupFilter">IB Group</Label>
                  <Input
                    type="select"
                    name="ibGroupFilter"
                    id="ibGroupFilter"
                    value={ibGroupFilter}
                    onChange={e => setIbGroupFilter(e.target.value)}
                  >
                    <option value="">All IB Groups</option>
                    <option value="YES">YES</option>
                    <option value="NO">NO</option>
                  </Input>
                </FormGroup>
              </Col>
              <Col md={3} className="d-flex align-items-end">
                {/* Align button at the bottom and apply inline margin-bottom */}
                <Button
                  color="secondary"
                  onClick={() => resetFilters()}
                  style={{ marginBottom: '14px' }}  // Inline margin-bottom 14px
                >
                  Reset Filters
                </Button>
              </Col>

            </Row>

            {/* Search bar */}
            <TextField
              label="Search"
              variant="outlined"
              fullWidth
              onChange={(e) => setSearchTerm(e.target.value)}
              sx={{ marginBottom: 2 }}
            />

            {/* Data Table */}
            <DataTable
              data={filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)}
              columns={tableColumns}
              striped
              selectableRows
              onSelectedRowsChange={({ selectedRows }) => setSelectedRows(selectedRows.map(row => row.id))}
              persistTableHead
            />

            {/* Pagination */}
            <CustomTablePagination
              rowsPerPageOptions={[10, 25, 50, 100, 250, 500, 1000]}
              component="div"
              count={filteredData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={(event, newPage) => setPage(newPage)}
              onRowsPerPageChange={(event) => {
                setRowsPerPage(parseInt(event.target.value, 10));
                setPage(0);
              }}
            />
          </CardBody>

        </Card>
      </Col>

      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>
          {selectedRowId ? 'Edit Trading Group' : 'Add Trading Group'}
        </ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup>
              <Label for="groupName">Group Name</Label>
              <Input
                type="text"
                name="groupPath"
                id="groupName"
                value={formValues.groupPath}
                onChange={handleChange}
              />
            </FormGroup>
            <FormGroup>
              <Label for="platform">Platform</Label>
              <Input
                type="select"
                name="platform"
                id="platform"
                value={formValues.platform}
                onChange={handleChange}
              >
                <option value="">Select Platform</option>
                <option value="MT4">MT4</option>
                <option value="MT5">MT5</option>
              </Input>
            </FormGroup>
            <FormGroup>
              <Label for="type">Type</Label>
              <Input
                type="select"
                name="type"
                id="type"
                value={formValues.type}
                onChange={handleChange}
              >
                <option value="">Select Type</option>
                <option value="WH">WH</option>
                <option value="STP">STP</option>
              </Input>
            </FormGroup>
            <FormGroup>
              <Label for="ib_group">IB Group</Label>
              <Input
                type="select"
                name="ib_group"
                id="ib_group"
                value={formValues.ib_group}
                onChange={handleChange}
              >
                <option value="">Select IB Group</option>
                <option value="YES">YES</option>
                <option value="NO">NO</option>
              </Input>
            </FormGroup>
            <FormGroup>
              <Label for="margin_call">Margin Call</Label>
              <Input
                type="text"
                name="margin_call"
                id="margin_call"
                value={formValues.margin_call}
                onChange={handleChange}
              />
            </FormGroup>
            <FormGroup>
              <Label for="stop_out">Stop Out</Label>
              <Input
                type="text"
                name="stop_out"
                id="stop_out"
                value={formValues.stop_out}
                onChange={handleChange}
              />
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleSubmit}>
            {selectedRowId ? 'Update' : 'Submit'}
          </Button>
          <Button color="secondary" onClick={toggle}>Cancel</Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

export default TradingGroup;