import React, { Fragment, useEffect, useState, useCallback } from "react";
import {
  Col,
  Card,
  CardHeader,
  Table,
  Row,
  Container,
  CardBody,
} from "reactstrap";
import { IBS } from "../../../Constant";
import { H5, Btn } from "../../../AbstractElements";
import { ibService, activitiesService } from "Services";
import AuthService from "../../../Services/auth.service";
import AssignModal from "./Modal/assignTo";
import TableLoader from "CommonElements/Table/TableLoader";
import IbModalButton from "./IbModalButton";
import { Link, useLocation } from "react-router-dom";
import TablePagination from "CommonElements/Table/TablePagination";
import { useSelector } from "react-redux";
import { getMenuPerm } from "utils";
import { writeFile, utils } from "xlsx";
import DataTable from "react-data-table-component";
import TableRowStatus from "CommonElements/Table/TableRowStatus";
import Select from "react-select";
import Filter from "../../Common/Component/filter";
import { toast } from "react-toastify";
import { SalesReportService } from "Services";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};
const HoverableRowsClass = () => {
  const [rec, setRec] = useState({});
  const [modal, setModal] = useState(false);
  const [ibBulkModal, setIbBulkModal] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [toggleCleared, setToggleCleared] = useState(false);
  const [modalMessage, setmodalMessage] = useState("");
  const [limit, setLimit] = useState(25);
  const [initialPage, setInitialPage] = useState(1);
  let [searchValue, setSearchValue] = useState({});
  const [clientLength, setClientLength] = useState({});
  const [groupMembers, setGroupMembers] = useState([]);
  const profile = useSelector((state) => state.login?.profile);
  const userId = profile?.id || null;

  const options = [
    { value: "25", label: "25" },
    { value: "50", label: "50" },
    { value: "100", label: "100" },
  ];

  const query = useQuery();
  const stateQuery = query.get("state");
  const pageQuery = query.get("page");

  // Function to handle limit change
  const handleLimitChange = (selectedOption) => {
    const newLimit = selectedOption.value; // Use the selected option's value directly
    setLimit(newLimit); // Update the limit state
    //loadData(1,newLimit);
    appendParameterToUrl("limit", newLimit);
  };
  const appendParameterToUrl = (key, value) => {
    // Get the current URL
    const currentUrl = window.location.href;

    // Create a URL object
    const url = new URL(currentUrl);

    if (key == "subLeads") {
      url.search = "";
    }
    if (
      key == "kycStatus" ||
      key == "limit" ||
      key == "dateFrom" ||
      key == "dateFrom" ||
      key == "agentId" ||
      key == "firstName"
    ) {
      url.searchParams.set("page", 1);
    }

    // Check if the parameter exists
    if (url.searchParams.has(key)) {
      // Update the existing parameter
      url.searchParams.set(key, value);
    } else {
      // Append the new parameter
      url.searchParams.append(key, value);
    }

    if (key == "kycStatus" && value == "") {
      console.log("value is ", value);
      url.searchParams.delete(key);
    }

    // Update the browser's address bar
    window.history.pushState(null, "", url.toString());
    loadData();
    console.log("Updated URL:", url.toString());
  };

  // Function to separate page and limit, and construct body params
  const getParamsAndBody = (url) => {
    const queryParams = extractUrlParams(url);

    const page = queryParams.page || 1;
    const limit = queryParams.limit || 25;
    setLimit(limit);
    // Remove page and limit from queryParams to construct the body parameters
    delete queryParams.page;
    delete queryParams.limit;

    const bodyParams = queryParams;

    return {
      page,
      limit,
      bodyParams,
    };
  };
  // Function to extract query parameters from the URL
  const extractUrlParams = (url) => {
    const urlObj = new URL(url);
    const params = new URLSearchParams(urlObj.search);
    const queryParams = {};
    for (const [key, value] of params) {
      queryParams[key] = value;
    }

    return queryParams;
  };

  const handleSubLeads = () => {
    appendParameterToUrl("subLeads", "true");
  };

  useEffect(() => {
    if (userId) {
      const fetchGroupMembers = async () => {
        try {
          let response = await SalesReportService.getGroupMembersById(userId);
          // console.log("Fetched Group Members Response:", response);

          let members;

          // If response is a string (comma-separated numbers), convert it into an array
          if (typeof response === "string") {
            members = response.split(",").map(Number); // Convert to an array of numbers
          } else if (
            response?.data?.result &&
            Array.isArray(response.data.result)
          ) {
            members = response.data.result;
          } else {
            console.error("Unexpected API response format:", response);
            setGroupMembers([]); // Reset to empty array if format is invalid
            return;
          }

          // Set state and trigger handleSubLeads if there are valid members
          setGroupMembers(members);
          if (members.length > 0) {
            handleSubLeads();
          }
        } catch (err) {
          console.error("Failed to fetch group members:", err);
          toast.error("Failed to fetch group members.");
          setGroupMembers([]); // Reset on API failure
        }
      };

      fetchGroupMembers();
    }
  }, [userId]);

  useEffect(() => {
    loadData();
  }, [searchValue]);
  const loadData = () => {
    const currentUrl = window.location.href;
    let { page, limit, bodyParams } = getParamsAndBody(currentUrl);
    setInitialPage(page);

    bodyParams.isIb = true;
    (!bodyParams.page || !bodyParams.limit) &&
      setRec({
        loading: true,
      });
    ibService
      .searchCustomers({
        page,
        limit,
        ...bodyParams,
      })
      .then(async (res) => {
        setRec({
          ...res,
          loading: false,
        });
      })
      .catch((err) => {});
  };

  const permissions = useSelector(
    (state) =>
      (state.login &&
        state.login.profile &&
        state.login.profile.Role &&
        state.login.profile.Role.permissions) ||
      {}
  );

  const user = useSelector(
    (state) => (state.login && state.login.profile) || {}
  );

  const emailPhoneViewClick = (e, index, customerId, type) => {
    e.preventDefault();
    var updatedRecord = rec;
    var isActive = false;
    var activityType = "";
    if (type === "emailView") {
      activityType = "email";
      isActive = updatedRecord.data[index].isEmailView === true ? false : true;
      updatedRecord.data[index].isEmailView = updatedRecord.data[
        index
      ].isEmailView = isActive;
    } else if (type === "phoneView") {
      activityType = "phone";
      isActive = updatedRecord.data[index].isPhoneView === true ? false : true;
      updatedRecord.data[index].isPhoneView = updatedRecord.data[
        index
      ].isPhoneView = isActive;
    }
    if (isActive) {
      var postData = {
        type: activityType,
        customerId: customerId,
        userId: user.id,
        note: { note: activityType + " View " },
      };
      postActivities(postData);
    }
    setRec({
      ...updatedRecord,
      loading: false,
    });
  };
  const postActivities = (payload) => {
    activitiesService
      .postActivity(payload)
      .then((res) => {})
      .catch((err) => {});
  };
  const clearSelectedRow = () => {
    setToggleCleared(!toggleCleared);
    setSelectedRows([]);
  };

  const tableColumns = [
    {
      name: "ID",
      selector: (row) => row.id,
      sortable: true,
      center: true,
    },
    {
      name: "KYC",
      selector: (row) => <TableRowStatus status={row.kycStatus} name="KYC" />,
      sortable: false,
      center: true,
    },
    {
      name: "Name",
      sortable: true,
      center: true,
      cell: (item) => (
        /*<Link className='' to={{ pathname: `/ibs/${item.id}`, search: `?page=${initialPage}`}}>
          {`${item.firstName} ${item.lastName}`}
        </Link>*/
        <Link
          className=""
          to={{
            pathname: `/ibs/${item.id}`,
          }}
        >
          {`${item.firstName} ${item.lastName ? item.lastName : ""}`}
        </Link>
      ),
    },
    {
      name: "Email",
      sortable: false,
      center: true,
      cell: (item, index) => (
        <div>
          {!item.isEmailView ? (
            <span>
              ******
              <i
                className="fa fa-eye-slash"
                style={{
                  position: "absolute",
                  cursor: "pointer",
                  marginLeft: "10px",
                }}
                onClick={(e) =>
                  emailPhoneViewClick(e, index, item.id, "emailView")
                }
              ></i>
            </span>
          ) : (
            <span>
              {item.email}
              <i
                className="fa fa-eye"
                style={{
                  position: "absolute",
                  cursor: "pointer",
                  marginLeft: "10px",
                }}
                onClick={(e) =>
                  emailPhoneViewClick(e, index, item.id, "emailView")
                }
              ></i>
            </span>
          )}
        </div>
      ),
    },
    {
      name: "Phone",
      sortable: false,
      center: true,
      cell: (item, index) => (
        <div>
          {!item.isPhoneView ? (
            <span>
              ******
              <i
                className="fa fa-eye-slash"
                style={{
                  position: "absolute",
                  cursor: "pointer",
                  marginLeft: "10px",
                }}
                onClick={(e) =>
                  emailPhoneViewClick(e, index, item.id, "phoneView")
                }
              ></i>
            </span>
          ) : (
            <span>
              {item.phone}
              <i
                className="fa fa-eye"
                style={{
                  position: "absolute",
                  cursor: "pointer",
                  marginLeft: "10px",
                }}
                onClick={(e) =>
                  emailPhoneViewClick(e, index, item.id, "phoneView")
                }
              ></i>
            </span>
          )}
        </div>
      ),
    },
    {
      name: "Date",
      selector: (row) => new Date(row.createdAt).toLocaleDateString(),
      sortable: true,
      center: true,
    },
    {
      name: "Country",
      selector: (row) => row.country,
      sortable: true,
      center: true,
    },
    {
      name: "Sales",
      // selector: (row) => row.country,
      sortable: true,
      center: true,
      cell: (item, index) => (
        <div>
          {item["Agent.firstName"]} {item["Agent.lastName"]}
        </div>
      ),
    },
    {
      name: "Master",
      selector: (row) =>
        `${row["Parent.firstName"] || ""} ${row["Parent.lastName"] || ""}`,
      sortable: false,
      center: true,
    },
    {
      name: "Status",
      selector: (row) => (
        <TableRowStatus status={row.ibStatus || "pending"} name="IBStatus" />
      ),
      sortable: true,
      center: true,
    },
    {
      name: "Clients", // Name of the column
      center: true, // Center the button
      sortable: false,
      cell: (item, index) => (
        <div>
          {clientLength[item.id] !== undefined ? (
            clientLength[item.id] // Show the client count if available
          ) : (
            <button
              onClick={() => handleButtonClick(item.id)} // Fetch clients when clicked
              className="btn btn-primary"
            >
              Clients
            </button>
          )}
        </div>
      ),
    },
    /*{
      name: 'Login',
      // selector: (row) => row.country,
      sortable: true,
      center: true,
      cell: (item, index) => (
        <div>
          <span onClick={()=>{handleClick(item.id,'GoDo#@!123')}} style={{cursor: 'pointer', textDecoration: 'underline', color: '#5a8dee'}}>{'Login'}</span>
        </div>
      ),
    },*/
  ];

  const handleButtonClick = (id) => {
    ibService
      .getClients({ parentId: id, page: 1, limit: 10000 })
      .then((res) => {
        setClientLength((prevCounts) => ({
          ...prevCounts,
          [id]: res.data.length, // Store client count for the specific id
        }));
        console.log("Ib Clients are ", res);
      })
      .catch((err) => {
        console.log("Ib Clients Fetch Error ", err);
      });
  };

  const handleClick = (email, password) => {
    AuthService.masterLoginIB(email, password);
  };

  const handleRowSelected = useCallback((state) => {
    setSelectedRows(state.selectedRows);
  }, []);
  const toggle = (e) => {
    if (e.target.textContent == "Sales Assign") {
      setmodalMessage("Assign to");
    } else if (e.target.textContent == "Delete IBs") {
      setmodalMessage("Delete IBs");
    }
    setModal(!modal);
  };
  const clientUploadToggle = (e) => {
    setIbBulkModal(!ibBulkModal);
  };
  function exportToExcel(data) {
    const worksheet = utils.json_to_sheet(data);
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, "ib_sheet");
    return workbook;
  }
  const handleExport = () => {
    const currentUrl = window.location.href;
    let { page, bodyParams } = getParamsAndBody(currentUrl);

    bodyParams.isIb = true;
    ibService
      .searchCustomers({
        page,
        limit: 100000,
        ...bodyParams,
      })
      .then(async (res) => {
        if (res.data != null) {
          const workbook = exportToExcel(res.data);
          writeFile(workbook, "ib_list.xlsx");

          var postData = {
            type: "IB Data Export",
            customerId: user.id,
            userId: user.id,
            note: { note: "ib_list.xlsx" },
          };
          postActivities(postData);
        }
      })
      .catch((err) => {});
  };

  return (
    <Fragment>
      {rec.loading && <TableLoader />}
      {!rec.loading && (
        <Col sm="12">
          <Card>
            <CardHeader>
              <Row className="align-items-center">
                <Col md="4">
                  <H5>{IBS}</H5>
                </Col>
                <Col
                  md="8"
                  className="d-flex justify-content-end align-items-center gap-2"
                >
                  {selectedRows.length > 0 && (
                    <>
                      <Btn attrBtn={{ color: "primary", onClick: toggle }}>
                        Sales Assign
                      </Btn>
                      <AssignModal
                        modal={modal}
                        toggle={toggle}
                        setModal={setModal}
                        selectedSales={selectedRows}
                        checkBoxClear={clearSelectedRow}
                        loadIbData={loadData}
                        titleMessage={modalMessage}
                      />
                    </>
                  )}

                  {selectedRows.length > 0 &&
                    getMenuPerm(permissions, "ibs:delete") === 1 && (
                      <Btn attrBtn={{ color: "secondary", onClick: toggle }}>
                        Delete IBs
                      </Btn>
                    )}

                  {getMenuPerm(permissions, "ibs:export") === 1 && rec.data && (
                    <Btn attrBtn={{ color: "success", onClick: handleExport }}>
                      Export
                    </Btn>
                  )}

                  {getMenuPerm(permissions, "ibs:create") === 1 && (
                    <IbModalButton btnText="Add" defaultVal="Godo" />
                  )}

                  <Select
                    defaultValue={options.find(
                      (option) => option.value === limit.toString()
                    )}
                    onChange={handleLimitChange}
                    options={options}
                  />
                </Col>
              </Row>
            </CardHeader>

            <div className="table-responsive">
              <Container fluid={true} className="datatables">
                <Row>
                  <Col sm="12">
                    <Card>
                      {rec.data ? (
                        <CardBody>
                          <Filter filter={appendParameterToUrl} show={"isIb"} />
                          <DataTable
                            data={rec.data}
                            columns={tableColumns}
                            striped={true}
                            center={true}
                            selectableRows
                            persistTableHead
                            onSelectedRowsChange={handleRowSelected}
                            clearSelectedRows={toggleCleared}
                          />
                        </CardBody>
                      ) : null}
                    </Card>
                  </Col>
                </Row>
              </Container>
              <TablePagination
                data={rec}
                loadData={loadData}
                limit={limit}
                param={appendParameterToUrl}
              />
            </div>
          </Card>
        </Col>
      )}
    </Fragment>
  );
};

export default HoverableRowsClass;
