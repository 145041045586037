import React, { Fragment, useEffect, useState } from 'react';
import { ibService, activitiesService } from 'Services';
import { Table, Row, Col } from 'reactstrap';
import TableLoader from 'CommonElements/Table/TableLoader';
import TableNodata from 'CommonElements/Table/TableNodata';
import LinkClient from './LinkClient';
import { useSelector } from "react-redux";
import { getMenuPerm } from 'utils';
import WithoutFilterPagination from "CommonElements/Table/WithoutFilterPagination";
import { toast } from 'react-toastify';
import Select from 'react-select';
import { Link } from 'react-router-dom';

const IbClients = (props) => {
    const {
        customerId = '',
        data = '',
        activeTab, tabNumber
    } = props;
    const [rec, setRec] = useState({});
    const [limit, setLimit] = useState(10);
    const [searchParams, setSearchParams] = useState({
        parentId: customerId,
        limit: 5
    });

    const user = useSelector(
        (state) =>
            (state.login &&
                state.login.profile) ||
            {}
    );

    const permissions = user?.Role?.permissions;

    useEffect(() => {
        if (activeTab === tabNumber) {
            loadData();
        }
    }, [activeTab, tabNumber]);

    const emailPhoneViewClick = (e, index, customerId, type) => {
        e.preventDefault();
        var updatedRecord = rec;
        var isActive = false;
        var activityType = '';
        if (type === 'emailView') {
            activityType = 'email';
            isActive = updatedRecord.data[index].isEmailView === true ? false : true;
            updatedRecord.data[index].isEmailView = updatedRecord.data[
                index
            ].isEmailView = isActive;
        } else if (type === 'phoneView') {
            activityType = 'phone';
            isActive = updatedRecord.data[index].isPhoneView === true ? false : true;
            updatedRecord.data[index].isPhoneView = updatedRecord.data[
                index
            ].isPhoneView = isActive;
        }
        if (isActive) {
            // console.log("IS active true");
            var postData = {
                type: activityType,
                customerId: searchParams.parentId,
                userId: user.id,
                note: { 'note': activityType + ' View of this client: ' + customerId },
            };
            postActivities(postData);
        }
        setRec({
            ...updatedRecord,
            loading: false,
        });
    };

    const postActivities = (payload) => {
        // console.log("payload is ", payload);
        activitiesService
            .postActivity(payload)
            .then((res) => { })
            .catch((err) => {
            });
    };

    const loadData = (page = 1, limit) => {
        setSearchParams({
            ...searchParams,
            page,
            limit: limit || 10,
        })
        ibService.getClients({ ...searchParams, page }).then((res) => {
            if (res.data.length > 0) {
                res.data = res.data.map((item) => ({
                    ...item,
                    isEmailView: false,
                    isPhoneView: false,
                }));
                console.log("Hm ", res.data.length);
            }
            console.log("Hm 222", res.data.length);
            setRec({
                ...res,
                loading: false,
            })
            console.log('Ib Clients are ', res);
        }).catch(err => {
            console.log('Ib Clients Fetch Error ', err);
        });
    }
    const unlinkClient = (client, id) => {
        ibService.unlinkClient(client.id).then(res => {
            toast.success('Client unlinnked, successfully');
            rec.data.splice(id, 1);
            loadData();
        }).catch(err => {
            toast.error('Failed to unlink Client');
        });
    }
    const getPageName = (item) => {
        if (item.isIb) return 'ibs';
        if (item.isClient || item.isCorporate) return 'clients';
        return 'leads';
    }

    const options = [
        { value: '10', label: '10' },
        { value: '50', label: '50' },
        { value: '100', label: '100' },
        { value: '200', label: '200' },
        { value: '500', label: '500' },
        { value: '1000', label: '1000' }
    ]
    // Function to handle limit change
    const handleLimitChange = (selectedOption) => {
        const newLimit = selectedOption.value; // Use the selected option's value directly
        const page = 1;
        setLimit(newLimit); // Update the limit state
        setSearchParams({
            ...searchParams,
            page,
        })
        ibService.getClients({ ...searchParams, page, limit: newLimit }).then((res) => {
            setRec({
                ...res,
                limit: newLimit,
                loading: false,
            })
            console.log('Ib Clients are ', res);
        }).catch(err => {
            console.log('Ib Clients Fetch Error ', err);
        });
    };
    return (
        <Fragment>
            <Row>
                <Col md={9} className="d-flex flex-row-reverse">
                    {getMenuPerm(permissions, 'transactions:create') === 1 &&
                        <LinkClient customerId={customerId} ibType={data.ibType} loadData={loadData} />
                    }
                </Col>
                <Col md="3">
                    <Select
                        defaultValue={options.find(option => option.value === limit.toString())}
                        onChange={handleLimitChange}
                        options={options}
                    />
                </Col>
            </Row>
            <div className="table-responsive">
                <Table hover>
                    <thead>
                        <tr>
                            <th scope="col">{'#'}</th>
                            <th scope="col">{'Name'}</th>
                            {/* <th scope="col">{'Last Name'}</th> */}
                            <th scope="col">{'Email'}</th>
                            <th scope="col">{'Client Type'}</th>
                            <th scope="col">{'Phone'}</th>
                            <th scope="col">{'Country'}</th>
                            <th scope="col">{'Registered Date'}</th>
                            <th scope="col">{'Actions'}</th>

                        </tr>
                    </thead>
                    {rec.loading && <TableLoader />}
                    {!rec.loading && rec.data && rec.data.length === 0 && <TableNodata title="IB Clients" />}

                    <tbody>
                        {
                            rec.data && rec.data.map((item, id) =>
                                <tr key={id}>
                                    <th scope="row">{item.id}</th>
                                    <td >{item.firstName}</td>
                                    {/* <td >{item.lastName ? item.lastName : ''}</td> */}
                                    <td >
                                        {!item.isEmailView ? (
                                            <span>
                                                ****
                                                <i
                                                    className='fa fa-eye-slash'
                                                    style={{
                                                        position: 'absolute',
                                                        cursor: 'pointer',
                                                        marginLeft: '10px',
                                                    }}
                                                    onClick={(e) =>
                                                        emailPhoneViewClick(e, id, item.id, 'emailView')
                                                    }
                                                ></i>
                                            </span>
                                        ) : (
                                            <span>
                                                {/* {item.email} */}
                                                <Link to={`${process.env.PUBLIC_URL}/${getPageName(item)}/${item.id}`}>{item.email}</Link>
                                                <i
                                                    className='fa fa-eye'
                                                    style={{
                                                        position: 'absolute',
                                                        cursor: 'pointer',
                                                        marginLeft: '10px',
                                                    }}
                                                    onClick={(e) =>
                                                        emailPhoneViewClick(e, id, item.id, 'emailView')
                                                    }
                                                ></i>
                                            </span>
                                        )}
                                    </td>
                                    <td >
                                        {item.isLead === 1 && 'Lead'}
                                        {item.isClient === 1 && ' Client'}
                                        {item.isIb === 1 && ' Partner'}
                                        {item.isCorporate === 1 && ' Corporate'}
                                    </td>
                                    {/* <td >{item.phone}</td> */}
                                    <td>
                                        {!item.isPhoneView ? (
                                            <span>
                                                ***
                                                <i
                                                    className='fa fa-eye-slash'
                                                    style={{
                                                        position: 'absolute',
                                                        cursor: 'pointer',
                                                        marginLeft: '10px',
                                                    }}
                                                    onClick={(e) =>
                                                        emailPhoneViewClick(e, id, item.id, 'phoneView')
                                                    }
                                                ></i>
                                            </span>
                                        ) : (
                                            <span>
                                                {item.phone}
                                                <i
                                                    className='fa fa-eye'
                                                    style={{
                                                        position: 'absolute',
                                                        cursor: 'pointer',
                                                        marginLeft: '10px',
                                                    }}
                                                    onClick={(e) =>
                                                        emailPhoneViewClick(e, id, item.id, 'phoneView')
                                                    }
                                                ></i>
                                            </span>
                                        )}
                                    </td>
                                    <td >{item.country}</td>
                                    <td >{new Date(item.createdAt).toDateString()}</td>
                                    <td>
                                        {getMenuPerm(permissions, 'ibs:viewAll') === 1 &&
                                            <span onClick={() => { unlinkClient(item, id) }} className="m-2 font-danger" style={{ cursor: 'pointer' }}>
                                                Unlink
                                            </span>
                                        }
                                    </td>
                                </tr>
                            )
                        }
                    </tbody>
                </Table>
                <WithoutFilterPagination data={rec} loadData={loadData} limit={limit} />

            </div>
        </Fragment>
    );
};
export default IbClients;